import xhr from 'xhr.js';
import store from 'store';
import qs from 'qs';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import user_services from 'services/users';
import services from 'services/services';
import analytics from 'utils/analytics';
import helpers from 'utils/helpers';

const CONSTANT_ACCOUNT_ID_SELECTED = 'auth_account_id';
const CONSTANT_AUTH_STATE_OBJECT = 'auth_state';

const starterAuth = {
    isFetchingAuth: true,
    isAuthenticated: false,
    account: {},
    user: {},
    account_role: {}
};

const auth = {
    async checkAuth() {
        const authReturn = starterAuth;
        // check if we were passed an account id to switch to
        let switch_to_account_id = null;
        const query_strings = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (query_strings.account_id) {
            switch_to_account_id = query_strings.account_id;
        }

        await xhr
            .get(`/me`, { withCredentials: true })
            .then(res => {
                const user = res.data;
                authReturn.user = user;
                authReturn.isAuthenticated = true;

                datadogRum.setUser({ id: user.id });
                datadogLogs.setUser({ id: user.id });

                // use mixpanel to track user
                analytics.identify(user.id);
                // set user properties each time they are authenticated
                analytics.set({ $email: user.email, $name: user.name });

                if (!user.timezone) {
                    try {
                        const browser_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        if (browser_timezone) {
                            // update user timezone
                            user_services.updateUserTimezone(browser_timezone);
                        }
                    } catch (e) {
                        helpers.trackError(e);
                    }
                }

                if (user.accounts.length) {
                    let foundAccount = null;
                    const accountIdLocalStorage = store.get(CONSTANT_ACCOUNT_ID_SELECTED);
                    // console.log(`FROM STORAGE: ${accountIdLocalStorage}`);

                    if (switch_to_account_id) {
                        foundAccount = user.accounts.find(a => a.id == switch_to_account_id);
                    } else if (accountIdLocalStorage) {
                        foundAccount = user.accounts.find(a => a.id == accountIdLocalStorage);
                        // console.log('FOUND', foundAccount);
                    }

                    if (foundAccount) {
                        this.setAccountId(foundAccount.id);
                        authReturn.account = foundAccount;
                        authReturn.account_role = this.findAccountRole(user.account_roles, foundAccount.id);
                    } else {
                        user.accounts.forEach(a => {
                            // console.log(a);
                            if (!authReturn.account.id) {
                                this.setAccountId(a.id);
                                authReturn.account = a;
                                authReturn.account_role = this.findAccountRole(user.account_roles, a.id);
                            }
                        });
                    }

                    helpers.googleTagManagerEventLogin(authReturn.account);
                }

                if (!helpers.isMinimalUi()) {
                    try {
                        //if (['michael@panelfox.io', '123ana.cabrera@gmail.com'].includes(user.email)) {
                        window.Beacon('init', '8f45c831-8982-4915-a172-24d6e3e11615');
                        window.Beacon('identify', {
                            name: user.name,
                            email: user.email
                        });

                        //}
                    } catch (e) {
                        helpers.trackError(e);
                    }
                }
            })
            .catch(error => {
                //console.log(error);
                authReturn.isAuthenticated = false;
            })
            .then(() => {
                authReturn.isFetchingAuth = false;
                //console.log('SAVING authReturn', authReturn);
                helpers.storeSave(CONSTANT_AUTH_STATE_OBJECT, authReturn);
            });

        return authReturn;
    },
    findAccountRole(account_roles, account_id) {
        //console.log('looking user.account_roles', account_roles, account_id);
        const foundAR = account_roles.find(ar => ar.account_id == account_id);
        //console.log('found foundAccountRole', foundAR);
        return foundAR;
    },
    setAccountId(accountId) {
        helpers.storeSave(CONSTANT_ACCOUNT_ID_SELECTED, accountId);
        //console.log(`Set account to: ${accountId}`);
    },
    getAuthState() {
        const auth_local = store.get(CONSTANT_AUTH_STATE_OBJECT);
        return auth_local;
    },
    changeAccountId(accountId) {
        // @todo verify accountid is valid
        helpers.storeSave(CONSTANT_ACCOUNT_ID_SELECTED, accountId);
        window.location = '/studies';
    },
    getAccountId() {
        return store.get(CONSTANT_ACCOUNT_ID_SELECTED);
    },
    getAccount() {
        let returnAccount = {};
        const accountId = this.getAccountId();
        const authState = this.getAuthState();
        if (authState && authState.user && authState.user.accounts) {
            authState.user.accounts.forEach(account => {
                if (account.id == accountId) {
                    returnAccount = account;
                }
            });
        }
        return returnAccount;
    },
    getAccountToken() {
        const account = this.getAccount();
        return account.token;
    },
    clearStorage() {
        store.remove(CONSTANT_ACCOUNT_ID_SELECTED);
        store.remove(CONSTANT_AUTH_STATE_OBJECT);
    },
    starterObject: starterAuth,
    createAccountEmail: async (em, pw, name, timezone) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/login-provider/panelfox-register`,
            { email: em, password: pw, name, timezone },
            { withCredentials: true }
        );

        return res.data;
    },
    loginAccountEmail: async (em, pw, two_fa_code) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/login-provider/panelfox-login`,
            { email: em, password: pw, two_fa_code: two_fa_code },
            { withCredentials: true }
        );

        return res.data;
    },
    verifyUserPhone: async (phone_number, two_fa_code) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/me/verify-phone`,
            { phone_number: phone_number, two_fa_code: two_fa_code },
            { withCredentials: true }
        );

        return res.data;
    }
};

export default auth;
