import React, { useState } from 'react';
import { Box, Button, Flex } from 'rebass';
import { FiCheck } from 'react-icons/fi';

import NiceModal from 'components/NiceModal';
import Portal from 'components/Portal';
import useQuery from 'hooks/useQuery';
import services from 'services/services';
import helpers from 'utils/helpers';

import styles from './styles.module.css';

/**
 * Dscout invite to mission buttons
 *
 * @param {number} total Total number of selected panelists
 * @param {Function} onClose - A callback function to be called when the "Back to dscout" button is clicked
 * @param {Function} onSubmit - A callback function to be called when "Add to mission" button is clicked
 * @param {Function} onRefetch - A callback function to refresh the participant list after an update
 * @param {Function} onStudyIdsUpdate - A callback function to be called when study id is assigned to the mission
 */
export function DscoutInviteButtons({ total, onClose, onSubmit, onRefetch, onStudyIdsUpdate }) {
    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const dscoutMissionId = helpers.getDscoutInviteMissionId();

    const { data, refetch: refetchMission } = useQuery({
        queryFn: async () => services.getDscoutMission(dscoutMissionId),
        onSuccess: data => {
            onStudyIdsUpdate(data.study_ids);
            onRefetch();
        }
    });

    const isDisabled = total === 0 || !data || isSubmitting;

    const closeModal = () => {
        setIsSubmitting(false);
        setShowModal(false);
        refetchMission();
    };

    const addPanelists = async () => {
        setIsSubmitting(true);
        try {
            await onSubmit();
            setShowModal(true);
        } catch (err) {
            setIsSubmitting(false);
        }
    };

    if (!data) return null;

    return (
        <>
            <Flex className={styles.container}>
                <Flex>
                    <Flex className={styles.logo}>
                        <img src="/dscout.png" alt="Dscout logo" />
                    </Flex>
                    <Flex className={styles.title}>
                        {data.mission.project.name || 'Unknown project'} / {data.mission.name}
                    </Flex>
                    {data.total > 0 && (
                        <Flex className={styles.total}>
                            {data.total} panelist{data.total > 1 && 's'} in the mission
                        </Flex>
                    )}
                </Flex>
                <Flex className={styles.buttons}>
                    <Button variant="secondary-gray" onClick={onClose}>
                        Back to dscout
                    </Button>
                    <Button className={styles.buttonAdd} variant="primary" onClick={addPanelists} disabled={isDisabled}>
                        Add to mission
                    </Button>
                </Flex>
            </Flex>
            <Portal>
                <NiceModal isOpen={showModal} shouldCloseOnOverlayClick onRequestClose={closeModal}>
                    <Flex className={styles.modal}>
                        <Flex className={styles.icon}>
                            <FiCheck />
                        </Flex>
                        <Box className={styles.title}>
                            {total} panelist{total > 1 && 's'} added to {data.mission.name}!
                        </Box>
                        <Flex className={styles.buttons}>
                            <Button variant="secondary-gray" onClick={closeModal}>
                                Close
                            </Button>
                            <Button variant="primary" className={styles.buttonReturn} onClick={onClose}>
                                Return to mission
                            </Button>
                        </Flex>
                    </Flex>
                </NiceModal>
            </Portal>
        </>
    );
}

export default DscoutInviteButtons;
