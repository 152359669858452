import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { FiInfo } from 'react-icons/fi';
import { Label, Select } from '@rebass/forms';
import { Box, Flex } from 'rebass';
import { OPTION_ORDER_OFF, OPTION_ORDER_RADOMIZE, OPTION_ORDER_FLIP } from 'utils/constants';
import helpers from 'utils/helpers';
import moment from 'moment-timezone';

const SortOrder = ({ elementId, currentOrder, onChangeOrder }) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (currentOrder !== undefined && currentOrder.value !== 0) setChecked(true);
        else setChecked(false);
    }, [currentOrder]);

    const handleCheck = () => {
        if (checked) {
            onChangeOrder(OPTION_ORDER_OFF.value);
        } else {
            onChangeOrder(OPTION_ORDER_RADOMIZE.value);
        }
        setChecked(!checked);
    };

    return (
        <>
            <Box>
                <Label className="for-checkbox">
                    <input
                        id="is-saved-to-panel"
                        className="custom-control-input"
                        type="checkbox"
                        checked={checked}
                        value={false}
                        onChange={handleCheck}
                        style={{ margin: '4px 8px 0 0' }}
                    />
                    {helpers.newFeatureTag(moment('05-17-2022', 'MM-DD-YYYY'), { margin: '2px 8px 0 0px' })}
                    Randomize or Flip
                    <FiInfo
                        data-tip
                        data-for={`tooltip-randomize-${elementId}`}
                        style={{
                            color: '#606E85',
                            margin: '3px 0 0 4px',
                            verticalAlign: 'top'
                        }}
                    />
                </Label>
                <ReactTooltip id={`tooltip-randomize-${elementId}`} effect="solid" place="right">
                    <Box>The answer options will be randomized or flipped for every respondent.</Box>
                </ReactTooltip>
            </Box>
            {checked && (
                <>
                    <div className="form-group" style={{ marginLeft: '21px' }}>
                        <Flex>
                            <Box>
                                <Box sx={{ position: 'relative' }} mt={1} mb={2}>
                                    <Select
                                        style={{ maxWidth: '160px' }}
                                        onChange={e => onChangeOrder(Number(e.target.value))}
                                        className="theme-input"
                                        value={currentOrder !== undefined && currentOrder.value}
                                        mr={3}
                                    >
                                        <option value={OPTION_ORDER_RADOMIZE.value}>
                                            {OPTION_ORDER_RADOMIZE.title}
                                        </option>
                                        <option value={OPTION_ORDER_FLIP.value}>{OPTION_ORDER_FLIP.title}</option>
                                    </Select>
                                </Box>
                            </Box>
                        </Flex>
                    </div>
                </>
            )}
        </>
    );
};

export default SortOrder;
