exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".card__60R15 {\n  padding: 0 !important;\n}\n\n.searchContainer__3T7dn {\n  padding: 8px 12px;\n  border-bottom: 1px solid #e9ecef;\n}\n\n.list__FiY6o {\n  max-height: 300px;\n  overflow: auto\n}\n\n.option__1HzFP {\n  color: inherit;\n  padding: 0 16px;\n  line-height: 36px;\n  cursor: pointer;\n  background-color: transparent;\n}\n\n.activeOption__3fmAH {\n  color: #0a73eb;\n  background-color: #fafafa;\n}\n\n.empty___kuQ6 {\n  padding: 0 16px;\n  line-height: 36px;\n  text-align: center;\n}\n", ""]);

// Exports
exports.locals = {
	"card": "card__60R15",
	"searchContainer": "searchContainer__3T7dn",
	"list": "list__FiY6o",
	"option": "option__1HzFP",
	"activeOption": "activeOption__3fmAH",
	"empty": "empty___kuQ6"
};