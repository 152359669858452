import React, { useState } from 'react';
import { Box, Button } from 'rebass';

import NiceModal from 'components/NiceModal';
import { DSCOUT_MISSION_DELIMITER, EMAIL_TEMPLATE_TYPE, INTEGRATION_DSCOUT } from 'utils/constants';
import service_studies from 'services/studies';
import { useToastHelper } from 'hooks/useToastHelper';
import useMutation from 'hooks/useMutation';

import DscoutMissionSelect from './DscoutMissionSelect';

/**
 * Modal component for inviting participants to a Dscout mission.
 *
 * @param {Object} study Study that needs to be integrated with Dscout.
 * @param {boolean} isOpen Controls whether the modal is open or closed.
 * @param {Function} onClose Callback function triggered when the modal is closed.
 * @param {Function} onSendEmail Callback function for sending email.
 */
export function DscoutInviteModal({ study, isOpen, onClose, onSendEmail }) {
    const { addSuccessToast, addErrorToast } = useToastHelper();

    const integratedDscoutMission = study.integrations.length
        ? study.integrations.find(si => si.integration_id == INTEGRATION_DSCOUT)
        : null;

    const selectedMissionId = integratedDscoutMission
        ? `${integratedDscoutMission.metadata.mission_type}${DSCOUT_MISSION_DELIMITER}${integratedDscoutMission.external_id}`
        : null;

    const [selectedMission, setSelectedMission] = useState();

    const { mutate: addIntegration, isLoading } = useMutation({
        mutationFn: ({ studyId, integration }) => service_studies.addIntegration(studyId, integration),
        onSuccess: studyIntegration => {
            onSendEmail('dscout', {
                study_integration_id: studyIntegration.id,
                subject: 'Invite to dscout mission',
                templateType: EMAIL_TEMPLATE_TYPE.BLOCKS,
                content: {
                    time: 1690835481429,
                    blocks: [
                        {
                            id: 'O4u99TNfJ0',
                            type: 'paragraph',
                            data: { text: 'Hello {First Name},' }
                        },
                        {
                            id: 'XeyyY4ynrD',
                            type: 'paragraph',
                            data: { text: 'We have a new research study:<br>' }
                        },
                        {
                            id: 'OkTVrLtn6A',
                            type: 'dscoutMissionButton',
                            data: { buttonText: 'Go to activity' }
                        },
                        { id: '49khf1fXlx', type: 'paragraph', data: { text: '' } },
                        {
                            id: '9Uvk98aARS',
                            type: 'paragraph',
                            data: { text: 'Thank you!<br>' }
                        }
                    ],
                    version: '2.25.0'
                }
            });
            onClose();
            addSuccessToast('Added study integration');
        },
        onError: (_, errorText) => {
            addErrorToast(errorText);
        }
    });

    const onSubmit = async () => {
        if (!selectedMission) {
            addErrorToast('Please select a mission');
            return;
        }

        const missionLink = selectedMission.splashUrl;
        const missionName = selectedMission.name;

        if (!missionLink) {
            addErrorToast('Link to the mission link not found. Please reach out to support@panelfox.io');
            return;
        }

        const studyId = study.id;
        const integration = {
            external_id: selectedMission.id,
            integration_id: INTEGRATION_DSCOUT,
            metadata: {
                mission_type: selectedMission.type,
                url: missionLink,
                name: missionName,
                projectName: selectedMission.project ? selectedMission.project.name : ''
            }
        };

        addIntegration({ studyId, integration });
    };

    return (
        <NiceModal
            isOpen={isOpen}
            shouldCloseOnOverlayClick
            onRequestClose={onClose}
            title="Invite to a dscout mission"
            overflow="visible"
        >
            {integratedDscoutMission ? (
                <Box mb={3}>
                    You have the <b>{integratedDscoutMission.metadata.name}</b> mission linked.
                </Box>
            ) : (
                <Box mb={3} className="text-secondary-dark">
                    You will only see <b>Launched</b> missions, which <b>you have access to</b>. Only <b>one</b> dscout
                    mission can be linked to a Panelfox study.
                </Box>
            )}
            <DscoutMissionSelect onChange={setSelectedMission} selectedMissionId={selectedMissionId} />
            <Box className="modal-actions">
                <Button type="button" variant="secondary-gray" mr={3} onClick={onClose}>
                    Cancel
                </Button>

                <Button type="submit" variant="primary" sx={{ marginRight: 0 }} onClick={onSubmit} disabled={isLoading}>
                    Continue
                </Button>
            </Box>
        </NiceModal>
    );
}

export default DscoutInviteModal;
