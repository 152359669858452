/**
 * <FormValidator />
 */

import React from 'react';
import xss from 'xss';
import { Button } from 'rebass';

const myxss = new xss.FilterXSS({
    whiteList: {
        u: [],
        br: [],
        b: [],
        i: [],
        ol: ['style'],
        ul: ['style'],
        li: [],
        p: ['style'],
        sub: [],
        sup: [],
        div: ['style'],
        em: [],
        strong: [],
        span: ['style']
    }
});

export default class FormValidator extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props);
        this.state = {
            errors: []
        };
    }

    componentDidMount() {
        this.props.emitter.subscribe('formValidation', errors => {
            // Create a new array to force a re-render
            this.setState({ errors: [] }, () => {
                this.setState({ errors });
            });
        });
    }

    componentWillUnmount() {
        //this.subscription.remove();
    }

    dismissModal(e) {
        e.preventDefault();
        this.setState({ errors: [] });
    }

    render() {
        const errors = this.state.errors.map((error, index) => (
            <li key={`error_${index}`} dangerouslySetInnerHTML={{ __html: myxss.process(error) }} />
        ));

        const dismiss_name = this.props.dismiss_name ? this.props.dismiss_name : 'Dismiss';

        return (
            <div>
                {this.state.errors.length > 0 && (
                    <div className="validation-error bg-danger text-danger">
                        <div className="clearfix">
                            <ul className="float-left" role="alert" aria-live="assertive">
                                {errors}
                            </ul>
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <Button
                                mr={3}
                                mt={2}
                                type="button"
                                variant="secondary-gray"
                                onClick={this.dismissModal.bind(this)}
                            >
                                {dismiss_name}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
