import xhr from 'xhr.js';
import auth from 'services/auth';
import config from 'config';
import qs from 'qs';
import { MESSAGE_API_DOWN } from 'utils/constants';
import helpers from 'utils/helpers';
import configs from '../config';

/**
 * @typedef {{
 *     id: number,
 *     group_id: number,
 *     account_id: number,
 *     title: string,
 *     type: string,
 *     pii_masked: number,
 *     definition: { label: string, value: string }[],
 *     pdv_table: string,
 *     created_at: string,
 *     updated_at: string,
 *     options_az: number,
 *     order: number,
 *     can_delete: boolean,
 *     integrations: any[],
 *     group: {
 *         id: number,
 *         name: string,
 *         order: number,
 *         account_id: number,
 *         created_at: string,
 *         updated_at: string
 *     }
 * }} CustomDataColumn
 */

const services = {
    resendVerificationEmail: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/user-resend-email-verification?account_id=${account_id}`,
            {},
            { withCredentials: true }
        );

        return res.data;
    },
    getAwsSignedUrl: async (fileName, fileType) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/people-bulk-signed-url?account_id=${account_id}`,
            { fileName, fileType },
            { withCredentials: true }
        );

        return res.data;
    },
    getAwsSignedUrlForScreenerVideo: async fileType => {
        const res = await xhr.post(`/screener-upload-video-signed-url`, { fileType }, { withCredentials: true });

        return res.data;
    },
    getAwsSignedUrlForScreenerFile: async file_extension => {
        const res = await xhr.post(`/screener-upload-file-signed-url`, { file_extension }, { withCredentials: true });

        return res.data;
    },
    completeOnboardingTask: async task_title => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/me/onboarding?account_id=${account_id}`,
            {
                task_title
            },
            { withCredentials: true }
        );

        auth.checkAuth();

        return res.data;
    },
    getCalendlyEventTypes: async user_uri => {
        let eventTypes = [];

        /*if (window.panelfox_storage_calendly_event_types) {
            eventTypes = window.panelfox_storage_calendly_event_types;
        } else {*/
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/integrations/calendly/event-types?account_id=${account_id}`, {
            withCredentials: true,
            params: {
                user_uri
            }
        });
        const xhr_response = res.data;

        // there are 2 keys available: data (event types) and included (users)
        eventTypes = xhr_response.collection;

        //window.panelfox_storage_calendly_event_types = eventTypes;
        //}

        return eventTypes;
    },
    getCalendlyUsers: async () => {
        let users = [];

        if (window.panelfox_storage_calendly_users) {
            users = window.panelfox_storage_calendly_users;
        } else {
            const account_id = auth.getAccountId();
            const res = await xhr.get(`/integrations/calendly/organization-memberships?account_id=${account_id}`, {
                withCredentials: true
            });
            const xhr_response = res.data;

            // there are 2 keys available: data (event types) and included (users)
            users = xhr_response.collection;

            window.panelfox_storage_calendly_users = users;
        }

        return users;
    },
    sendStudyPersonEmail: async ({ studyId, personId, studyPersonId, emailCampaign }) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${studyId}/study-people/${studyPersonId}/email?account_id=${account_id}`,
            {
                recipientsTo: [{ id: personId }],
                subject: emailCampaign.subject,
                content: emailCampaign.content,
                is_reply_campaign_id: emailCampaign.is_reply_campaign_id,
                attachments: emailCampaign.attachments
            },
            { withCredentials: true }
        );

        return res.data;
    },
    sendStudySessionUpdates: async studyId => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${studyId}/send-session-updates?account_id=${account_id}`,
            {},
            {
                withCredentials: true
            }
        );

        return res.data;
    },
    /**
     * Create zoom meeting and update all future sessions.
     *
     * @param {number} studyId
     * @returns {Promise<any>}
     */
    createZoomMeetingsAndSendSessionUpdates: async studyId => {
        const res = await xhr.post(
            `/studies/${studyId}/zoom-meetings`,
            {},
            {
                withCredentials: true,
                params: {
                    account_id: auth.getAccountId()
                }
            }
        );

        return res.data;
    },
    /**
     * Delete zoom meetings and update all future sessions
     *
     * @param {number} studyId
     * @returns {Promise<any>}
     */
    deleteZoomMeetingsAndSendSessionUpdates: async studyId => {
        const res = await xhr.delete(`/studies/${studyId}/zoom-meetings`, {
            withCredentials: true,
            params: {
                account_id: auth.getAccountId()
            }
        });

        return res.data;
    },
    openPayment: (numUsers, cb) => {
        alert('Disabled');
        return;
        const stripe = window.Stripe(config.stripe_key_public);
        const account_id = auth.getAccountId();

        xhr.post(`/me/payment/session?account_id=${account_id}`, { quantity: numUsers }, { withCredentials: true })
            .then(response => {
                const paymentSession = response.data;
                stripe
                    .redirectToCheckout({
                        // Make the id field from the Checkout Session creation API response
                        // available to this file, so you can provide it as parameter here
                        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                        sessionId: paymentSession.id
                    })
                    .then(function(result) {
                        // If `redirectToCheckout` fails due to a browser or network
                        // error, display the localized error message to your customer
                        // using `result.error.message`.
                        console.log(result);
                    });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                cb(true, errorText);
            });
    },
    getTimezones: async () => {
        const res = await xhr.get(`/me/get-timezones`, {
            withCredentials: true
        });
        return res.data;
    },
    updateUser: async user => {
        const res = await xhr.put(`/me`, user, {
            withCredentials: true
        });

        return res.data;
    },
    /**
     * Get person points. If points are not available, returns null.
     *
     * @param {number|string} personId
     */
    getPersonPoints: async personId => {
        const account_id = auth.getAccountId();
        const response = await xhr.get(`/people/${personId}/points?account_id=${account_id}`, {
            withCredentials: true
        });
        return response.data;
    },
    getPerson: async id => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/people/${id}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    getPersonParticipation: async (id, page, participationType) => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/people/${id}/participation?account_id=${account_id}`, {
            withCredentials: true,
            params: {
                page,
                participation_type: participationType
            }
        });
        return res.data;
    },
    /**
     * Get custom data columns.
     *
     * @param {boolean} [bustCache]
     * @returns {Promise<CustomDataColumn[]>}
     */
    getCustomDataColumnsXHR: async bustCache => {
        bustCache = !!bustCache;
        const cacheKey = 'panelfox_storage_account_panel_columns';
        let columns;

        if (window[cacheKey] && !bustCache) {
            columns = window[cacheKey];
        } else {
            const account_id = auth.getAccountId();
            const res = await xhr.get(`/columns?account_id=${account_id}`, {
                withCredentials: true
            });
            columns = res.data;
            window[cacheKey] = columns;
        }
        return columns;
    },
    /**
     * Get panel properties groups.
     */
    getPanelPropertiesGroups: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/panel-properties-groups?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    /**
     * Get panel properties group.
     */
    getPanelPropertiesGroup: async groupId => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/panel-properties-groups/${groupId}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    /**
     * Create panel properties group.
     *
     * @param {Object} data
     * @param {string} data.name Name of the group
     * @param {string?} data.order Order of the group
     * @returns new group
     */
    createPanelPropertiesGroup: async data => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(`/panel-properties-groups?account_id=${account_id}`, data, {
            withCredentials: true
        });
        return res.data;
    },
    /**
     * Update panel properties group.
     *
     * @param {number} groupId Group ID
     * @param {Object} data
     * @param {string} data.name Name of the group
     */
    updatePanelPropertiesGroup: async (groupId, data) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(`/panel-properties-groups/${groupId}?account_id=${account_id}`, data, {
            withCredentials: true
        });
        return res.data;
    },
    /**
     * Delete panel properties group.
     *
     * @param {number} groupId Group ID
     */
    deletePanelPropertiesGroup: async groupId => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/panel-properties-groups/${groupId}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    /**
     * Reorder panel properties groups.
     *
     * @param {Object} data
     * @param {object} data.groups Panel properties groups
     * @param {number} data.groups.id Group ID
     * @param {number} data.groups.order Order of the group
     */
    reorderPanelPropertiesGroups: async data => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(`/reorder-panel-properties-groups?account_id=${account_id}`, data, {
            withCredentials: true
        });
        return res.data;
    },
    /**
     * Move panel properties to another group.
     *
     * @param {Object} data
     * @param {number} data.to_group_id Group ID
     * @param {Array<number>} data.property_ids Panel properties IDs
     */
    movePanelProperties: async data => {
        const account_id = auth.getAccountId();

        const res = await xhr.post(`/move-panel-properties?account_id=${account_id}`, data, {
            withCredentials: true
        });
        return res.data;
    },
    deleteCustomDataColumn: async column_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/columns/${column_id}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    postCustomDataColumnsXHR: async pdk => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(`/people-new-key?account_id=${account_id}`, pdk, {
            withCredentials: true
        });

        const cacheKey = 'panelfox_storage_account_panel_columns';
        delete window[cacheKey];

        return res.data;
    },
    saveCustomDataColumnsOrder: async (columns, groupId) => {
        //console.log(columns)
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/columns-order?account_id=${account_id}`,
            { columns: columns, group_id: groupId },
            {
                withCredentials: true
            }
        );

        return res.data;
    },
    getStudyAvailabilityLink: async (uuid, sp_uuid) => {
        const res = await xhr.get(`/studies/${uuid}/availability-link?sp_uuid=${sp_uuid}`, {
            withCredentials: true
        });
        return res.data;
    },
    getStudy: async id => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies/${id}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    updateStudy: async study => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(`/studies/${study.id}?account_id=${account_id}`, study, {
            withCredentials: true
        });

        return res.data;
    },
    updateStudyStates: async (study_id, study_states) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/studies/${study_id}/study-states?account_id=${account_id}`,
            { study_states },
            {
                withCredentials: true
            }
        );

        return res.data;
    },
    updateStudyState: async (study_id, study_state) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/studies/${study_id}/study-states/${study_state.id}?account_id=${account_id}`,
            study_state,
            {
                withCredentials: true
            }
        );

        return res.data;
    },
    deleteStudyState: async (study_id, study_state) => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/studies/${study_id}/study-states/${study_state.id}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    updateStudyPeople: async (study_id, study_people) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/studies/${study_id}/study-people?account_id=${account_id}`,
            { study_people },
            {
                withCredentials: true
            }
        );

        return res.data;
    },
    addPersonToStudy: async (study_id, person) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/people?account_id=${account_id}`,
            { person },
            {
                withCredentials: true
            }
        );

        return res.data;
    },
    addPeopleToStudy: async (study_id, payload) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(`/studies/${study_id}/people?account_id=${account_id}`, payload, {
            withCredentials: true
        });

        return res.data;
    },
    createStudyAddPeople: async payload => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(`/studies?account_id=${account_id}`, payload, {
            withCredentials: true
        });

        return res.data;
    },
    createPerson: async person => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(`/people?account_id=${account_id}`, person, {
            withCredentials: true
        });

        return res.data;
    },
    updateScreenerTranslation: async (study_id, screener_translation) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/studies/${study_id}/screener-translation?account_id=${account_id}`,
            { screener_translation },
            {
                withCredentials: true
            }
        );

        return res.data;
    },
    sendEmailCampaign: async (
        study_id,
        people,
        study_states,
        filters,
        subject,
        content,
        templateType,
        email_action,
        from_email,
        from_name,
        test_email_address,
        screener_id,
        search_keyword,
        custom_count_number,
        send_when,
        sort,
        attachments,
        randomize,
        study_integration_id
    ) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/people-campaign?account_id=${account_id}`,
            {
                recipientsTo: people,
                study_states,
                filters,
                subject,
                content,
                template_type: templateType,
                email_action,
                from_email,
                from_name,
                test_email_address,
                screener_id,
                search_keyword,
                custom_count_number,
                send_when,
                sort,
                attachments,
                randomize,
                study_integration_id
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    getScreener: async (uuid, is_preview) => {
        const res = await xhr.get(`/screeners/${uuid}?is_preview=${is_preview}`, {
            withCredentials: true
        });
        return res.data;
    },
    getUserInvites: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/user-invites?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    createUserInvite: async (email, role) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/user-invites?account_id=${account_id}`,
            { email, role },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    resendUserInvite: async user_invite_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/user-invites/${user_invite_id}/resend?account_id=${account_id}`,
            {},
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    deleteUserInvite: async user_invite_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/user-invites/${user_invite_id}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    deleteUser: async user_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/accounts/${account_id}/users/${user_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    getAccountUsers: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/users?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    updateAccountUserRole: async (user_id, role) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/accounts/${account_id}/users/${user_id}/role`,
            { role },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    submitScreenerFormPartial: async (screen_type, uuid, identifier, formData, is_qualified, sp_uuid, anon_uuid) => {
        //console.log(submitScreenerFormPartial);
        //return "";
        const query_string_object = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        const browser_data = {
            current_href: location.href,
            referrer: document.referrer
        };
        //if (screen_type == 'study') {
        const study_person_id = identifier;
        const res = await xhr.post(
            `/screeners/${uuid}/partial`,
            {
                form_answers: formData,
                is_qualified,
                study_person_id,
                screen_type,
                custom_variables: query_string_object,
                browser_data: browser_data,
                anon_uuid
            },
            { withCredentials: true }
        );
        return res.data;
        //}
    },
    /**
     * @returns {Promise<{ uuid?: string, confirmation_redirect_url?: string, disqualification_redirect_url?: string }>}
     */
    submitScreenerForm: async (
        screen_type,
        uuid,
        identifier,
        formData,
        is_qualified,
        sp_uuid,
        is_partial,
        ui_action
    ) => {
        const query_string_object = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        const browser_data = {
            current_href: location.href,
            referrer: document.referrer
        };
        if (screen_type == 'study') {
            const study_person_id = identifier;
            const res = await xhr.post(
                `/screeners/${uuid}`,
                {
                    form_answers: formData,
                    is_qualified,
                    study_person_id,
                    screen_type,
                    custom_variables: query_string_object,
                    browser_data: browser_data
                },
                { withCredentials: true }
            );
            return res.data;
        } else if (screen_type == 'study-screen') {
            const study_person_id = identifier;
            const res = await xhr.post(
                `/screeners/${uuid}`,
                {
                    form_answers: formData,
                    is_qualified,
                    study_person_id,
                    screen_type,
                    custom_variables: query_string_object,
                    browser_data: browser_data,
                    sp_uuid,
                    is_partial,
                    ui_action
                },
                { withCredentials: true }
            );
            return res.data;
        } else if (screen_type == 'panel') {
            const panel_form_id = identifier;
            const res = await xhr.post(
                `/screeners/${uuid}`,
                {
                    form_answers: formData,
                    is_qualified,
                    screen_type,
                    panel_form_id,
                    custom_variables: query_string_object,
                    browser_data: browser_data
                },
                { withCredentials: true }
            );
            return res.data;
        }
    },
    editPanelProfileAnswers: async (account_uuid, panel_form_id, formData, panelist_uuid) => {
        const jwt_token = helpers.getPanelistJWTToken();
        const res = await xhr.put(
            `/panel-signup-forms/${panel_form_id}/answers/${panelist_uuid}?account_uuid=${account_uuid}`,
            { form_answers: formData, token: jwt_token },
            { withCredentials: true }
        );
        return res.data;
    },
    editScreenerAnswers: async (study_id, screener_id, screener_data_id, formData, is_qualified) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/screeners/${screener_id}/answers/${screener_data_id}?account_id=${account_id}&study_id=${study_id}`,
            { form_answers: formData, is_qualified },
            { withCredentials: true }
        );
        return res.data;
    },
    alertIfApiDown: xhrError => {
        if (xhrError && xhrError.response && [502, 503, 504].includes(xhrError.response.status)) {
            alert(MESSAGE_API_DOWN);
        }
    },
    parseAndTrackXhrErrors: xhrError => {
        let errorText = '';

        if (xhrError.response) {
            if ([502, 503, 504].includes(xhrError.response.status)) {
                errorText = MESSAGE_API_DOWN;
            } else if (xhrError.response.data.error) {
                errorText = xhrError.response.data.error;
            } else if (xhrError.response.data.message) {
                const exception = xhrError.response.data.exception ? xhrError.response.data.exception + ': ' : '';
                errorText = `${exception}${xhrError.response.data.message}`;
            } else {
                Object.keys(xhrError.response.data).forEach(property => {
                    errorText += `${xhrError.response.data[property]} `;
                });
            }

            // notify only for unexpected errors (exclude backend validation errors)
            if (![400, 404].includes(xhrError.response.status)) {
                helpers.trackError(xhrError);
            }
        }

        return errorText;
    },
    getPanelSearch: async id => {
        const account_id = auth.getAccountId();
        let res;
        if (id) {
            res = await xhr.get(`/people-saved-search/${id}?account_id=${account_id}`, {
                withCredentials: true
            });
        } else {
            res = await xhr.get(`/people-saved-search?account_id=${account_id}`, {
                withCredentials: true
            });
        }
        return res.data;
    },
    createPanelSearch: async (title, url_state) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/people-saved-search?account_id=${account_id}`,
            { title, url_state },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    updatePanelSearch: async (id, title, url_state) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/people-saved-search/${id}?account_id=${account_id}`,
            { title, url_state },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    deletePanelSearch: async id => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/people-saved-search/${id}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },

    // returns array of study people [ studyPerson, ... ]
    getSessions: async study_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/study-people?account_id=${account_id}&study_id=${study_id || ''}`, {
            withCredentials: true
        });
        return res.data;
    },
    getPanelColumnShow: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/people-saved-columns-show?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data && res.data.columns ? res.data.columns : [];
    },
    updatePanelColumnShow: async columns => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/people-saved-columns-show?account_id=${account_id}`,
            { columns },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    getTags: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/tags?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    // this method is non-standard, just fyi
    // it users a callback
    getPersonTags(account_id, cb) {
        xhr.get(`/tags?account_id=${account_id}&type=Person`, {
            withCredentials: true
        }).then(res => {
            const tags = res.data;

            cb(false, tags);
        });
    },
    createTag: async (tag_type, title) => {
        const account_id = auth.getAccountId();

        const res = await xhr.post(
            `/tags`,
            {
                title,
                account_id: account_id,
                type: tag_type
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    updateTag: async (tag_id, tag_type, title) => {
        const account_id = auth.getAccountId();

        const res = await xhr.put(
            `/tags/${tag_id}`,
            {
                title,
                account_id: account_id,
                type: tag_type
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    addTagToStudy: async (study_id, tag_id) => {
        const account_id = auth.getAccountId();

        const res = await xhr.post(
            `/studies/${study_id}/tags/${tag_id}`,
            {
                account_id: account_id
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    removeTagFromStudy: async (study_id, tag_id) => {
        const account_id = auth.getAccountId();

        const res = await xhr.delete(`/studies/${study_id}/tags/${tag_id}`, {
            withCredentials: true,
            params: {
                account_id: account_id
            }
        });
        return res.data;
    },
    deleteTag: async tag_id => {
        const account_id = auth.getAccountId();

        const res = await xhr.delete(`/tags/${tag_id}`, {
            withCredentials: true,
            params: {
                account_id: account_id
            }
        });
        return res.data;
    },
    sendForgotPasswordEmailUser: async email => {
        const res = await xhr.post(
            `/user-forgot-password`,
            { email },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    useResetPasswordToken: async (token, password, password_confirmation) => {
        const res = await xhr.post(
            `/reset-password-use-token`,
            { token, password, password_confirmation },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    uploadEmailAttachment: async formData => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(`/study-email-attachment?account_id=${account_id}`, formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return res.data;
    },
    deletePanelSignupCustomLink: async panel_form_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/panel-signup-forms/${panel_form_id}/custom-link?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    setPanelSignupCustomLink: async (panel_form_id, slug) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/panel-signup-forms/${panel_form_id}/custom-link?account_id=${account_id}`,
            { slug },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    productUpdates: async () => {
        const cacheKey = 'panelfox_storage_feature_updates';
        let feature_updates;

        if (window[cacheKey]) {
            feature_updates = window[cacheKey];
            //console.log('cached feature updates');
        } else {
            const account_id = auth.getAccountId();
            const res = await xhr.get(`misc/feature-updates?account_id=${account_id}`, {
                withCredentials: true
            });
            feature_updates = res.data;
            window[cacheKey] = feature_updates;
        }
        return feature_updates;
    },

    getDscoutMissions: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/integrations/dscout/missions?account_id=${account_id}`, { withCredentials: true });
        return res.data;
    },
    /**
     * Get Dscout mission info for a given mission ID.
     *
     * @param {string} missionId A composite ID of Dscout mission to retrieve: {mission_type}:{mission_id}.
     * @returns {Promise<Object>}
     */
    getDscoutMission: async missionId => {
        const accountId = auth.getAccountId();
        const res = await xhr.get(`/integrations/dscout/missions/${missionId}?account_id=${accountId}`, {
            withCredentials: true
        });
        return res.data;
    },
    uploadPersonFiles: async (personId, formData, studyId, options) => {
        const account_id = auth.getAccountId();
        const searchParams = new URLSearchParams({
            account_id: account_id
        });

        if (studyId) {
            searchParams.append('study_id', studyId);
        }

        const res = await xhr.post(`/people/${personId}/files?${searchParams.toString()}`, formData, {
            withCredentials: true,
            onUploadProgress: progressEvent => {
                if (options && options.onUploadProgress) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    options.onUploadProgress(percentCompleted);
                }
            }
        });
        return res.data;
    },
    getPersonFiles: async (personId, studyId) => {
        const account_id = auth.getAccountId();
        const searchParams = new URLSearchParams({
            account_id: account_id
        });

        if (studyId) {
            searchParams.append('study_id', studyId);
        }

        const res = await xhr.get(`/people/${personId}/files?${searchParams.toString()}`, { withCredentials: true });
        return res.data;
    },
    getPersonFileUrl: async (personId, fileId) => {
        const account_id = auth.getAccountId();
        const url = `${configs.API_URL}/people/${personId}/files/${fileId}?account_id=${account_id}`;
        return url;
    },
    deletePersonFile: async (personId, fileId) => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/people/${personId}/files/${fileId}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    updatePersonFile: async (personId, fileId, data) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(`/people/${personId}/files/${fileId}?account_id=${account_id}`, data, {
            withCredentials: true
        });
        return res.data;
    },
    /**
     * Get form answers for person
     *
     * @param {string|number} personId person id
     * @param {string|number} formId form id
     */
    getFormAnswers: async (personId, formId) => {
        const accountId = auth.getAccountId();
        const res = await xhr.get(`/people/${personId}/form-answers/${formId}`, {
            withCredentials: true,
            params: {
                account_id: accountId
            }
        });
        return res.data;
    },
    /**
     * Move study people to another study
     *
     * @param {number} sourceStudyId
     * @param {number} destinationStudyId
     * @param {{ custom_count_number?: number, filters?: Array<Object>, randomize?: boolean, search_keyword?: string, sort?: string, people_as_array: Array<{ id: number }> }} payload
     */
    shareStudyPeople: async (sourceStudyId, destinationStudyId, payload) => {
        const accountId = auth.getAccountId();
        const res = await xhr.post(`/studies/${sourceStudyId}/share-study-people/${destinationStudyId}`, payload, {
            withCredentials: true,
            params: {
                account_id: accountId
            }
        });

        return res.data;
    },
    /**
     * Create study
     *
     * @param {{ description?: string, title: string }} payload
     */
    createStudy: async payload => {
        const accountId = auth.getAccountId();
        const res = await xhr.post('/studies', payload, {
            withCredentials: true,
            params: {
                account_id: accountId
            }
        });

        return res.data;
    }
};

export default services;
