import React, { useEffect, useState } from 'react';
import TextAreaAutosize from 'react-textarea-autosize';
import { Box, Button } from 'rebass';
import { Label } from '@rebass/forms';
import NiceModal from 'components/NiceModal';

export default props => {
    const [textAreaValue, setTextAreaValue] = useState(props.value);

    useEffect(() => {
        setTextAreaValue(props.value);
    }, [props.value]);

    return (
        <NiceModal isOpen={props.isOpen} onRequestClose={props.onRequestClose} title={props.title}>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    props.onSubmit(textAreaValue);
                    props.onRequestClose();
                }}
            >
                <Box>
                    <Label>Body</Label>
                    <TextAreaAutosize
                        className="theme-input"
                        value={textAreaValue}
                        onChange={e => setTextAreaValue(e.target.value)}
                        minRows={4}
                        autoFocus
                        style={{ marginBottom: '8px' }}
                    ></TextAreaAutosize>
                    {props.belowTextArea && <Box mb={3}>{props.belowTextArea}</Box>}
                </Box>
                <Box className="modal-actions">
                    <Button type="button" variant="secondary-gray" mr={3} onClick={props.onRequestClose}>
                        Cancel
                    </Button>

                    <Button type="submit" variant="primary" mr={0}>
                        Save
                    </Button>
                </Box>
            </form>
        </NiceModal>
    );
};
