import React, { useEffect, useRef, useState } from 'react';
import { Box, Button } from 'rebass';
import ReactQuill, { Quill } from 'react-quill';
import classNames from 'classnames';
import { FiEdit } from 'react-icons/fi';

const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

const NULL_HTML = '<div><br></div>';

/**
 * The editor cover. Used to show the edit button.
 *
 * @param {() => void} onClick The click handler.
 * @param {boolean} isEditing Whether the editor is in editing mode.
 */
export const EditorEditCover = ({ onClick, isEditing }) => {
    if (isEditing) return null;

    return (
        <button type="button" onClick={onClick} className="save-edit-editor--edit-cover">
            <FiEdit className="save-edit-editor--edit-icon" />
            <span className="fs-accent-14">Edit</span>
        </button>
    );
};

/**
 * SaveEditEditor component.
 *
 * @param {string | undefined | null} defaultValue The default value of the editor.
 * @param {Function} onSave The save function.
 * @param {Quill.QuillOptions['modules']} modules
 * @param {string} [className] The class name.
 * @param {React.CSSProperties} [style] The style.
 * @returns {JSX.Element} The rendered component.
 */
export function SaveEditEditor({ defaultValue, onSave, modules, className, style }) {
    const [html, setHtml] = useState(defaultValue);
    const [shadowHtml, setShadowHtml] = useState(null); // Used to store the original value when editing.
    const [isEditing, setIsEditing] = useState(false);
    /**
     * @type {React.MutableRefObject<ReactQuill>}
     */
    const ref = useRef();

    const onRevert = () => {
        setHtml(shadowHtml);
        setShadowHtml(null);
        setIsEditing(false);
    };

    const onInternalSave = () => {
        const content = html === NULL_HTML ? null : html;
        onSave(content);
        setShadowHtml(null);
        setIsEditing(false);
    };

    const focusEditor = () => {
        if (ref.current) {
            const editor = ref.current.getEditor();
            editor.focus();

            // Move the cursor to the end of the editor.
            const length = editor.getLength();
            editor.setSelection(length - 1, 0);
        }
    };

    const onEdit = () => {
        setIsEditing(true);
        setShadowHtml(html);
    };

    useEffect(() => {
        if (isEditing) {
            focusEditor();
        }
    }, [isEditing]);

    return (
        <Box
            style={style}
            className={classNames('save-edit-editor--wrapper', { 'save-edit-editor--readonly': !isEditing }, className)}
        >
            <EditorEditCover onClick={onEdit} isEditing={isEditing} />
            <ReactQuill
                ref={ref}
                theme="snow"
                className="quill-render"
                readOnly={!isEditing}
                modules={modules}
                value={html}
                onChange={html => setHtml(html)}
            />
            <Box className="save-edit-editor--footer">
                <Button variant="transparent" onClick={onRevert} style={{ marginRight: '24px' }}>
                    Cancel
                </Button>
                <Button onClick={onInternalSave} style={{ margin: 0 }}>
                    Save
                </Button>
            </Box>
        </Box>
    );
}

export default SaveEditEditor;
