import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import qs from 'qs';

import services from 'services/services';
import scheduling_service from 'services/scheduling';
import helpers from 'utils/helpers';
import { SCHEDULING_SESSION_CHANGE_ALLOWED } from 'utils/constants';

import { Box, Flex, Button } from 'rebass';
import { FiCheckCircle } from 'react-icons/fi';

import useIsMobile from 'hooks/useIsMobile';
import NiceModal from 'components/NiceModal';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import SchedulingPageError from 'containers/PublicSchedulingPage/components/SchedulingPageError';
import SchedulingPageWrapper from 'containers/PublicSchedulingPage/components/SchedulingPageWrapper';
import SchedulingPageNiceDateTime from 'containers/PublicSchedulingPage/components/SchedulingPageNiceDateTime';
import myxss from 'components/ReactFormBuilder/form-elements/myxss';

const PublicSchedulingResultPage = props => {
    const isMobile = useIsMobile(520);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [isCancelled, setIsCancelled] = useState(null);
    const [isPreview, setIsPreview] = useState(null);
    const [data, setData] = useState();
    const [errorText, setErrorText] = useState(null);
    const [showCancelSessionModal, setShowCancelSessionModal] = useState(false);
    const [cancellingSession, setCancellingSession] = useState(false);
    const rescheduleHref = useMemo(() => {
        const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
        const query = new URLSearchParams({
            token: queryParams.token,
            study_uuid: queryParams.study_uuid
        });

        if (isPreview) {
            query.set('spot', data.spot);
            query.set('timezone', data.timezone);
            query.set('duration', data.duration);
            query.set('logo', data.logo);
            query.set('location', data.location);
            query.set('is_preview', 1);

            if (data.schedulingConfirmation) {
                query.set('scheduling_confirmation', data.schedulingConfirmation);
            }
        } else {
            query.set('sp_uuid', queryParams.sp_uuid);
            query.set('is_preview', 0);
        }

        return `/scheduling/reschedule?${query.toString()}`;
    }, [isPreview, data, props.location.search]);

    const getSession = () => {
        const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
        const is_preview = !!parseInt(queryParams.is_preview);
        const is_cancel = !!parseInt(queryParams.is_cancel);

        if (is_preview) {
            setIsPreview(true);
            setData({
                spot: queryParams.spot,
                timezone: queryParams.timezone,
                duration: queryParams.duration,
                location: queryParams.location || 'Location will be shared at a later time',
                logo: queryParams.logo,
                allow_session_changes:
                    parseInt(queryParams.allow_session_changes) === SCHEDULING_SESSION_CHANGE_ALLOWED,
                schedulingConfirmation: queryParams.scheduling_confirmation,
                title: queryParams.title || 'Company Preview Name'
            });
        } else {
            const study_uuid = queryParams.study_uuid;
            const sp_uuid = queryParams.sp_uuid;
            scheduling_service
                .getScheduledStudyPerson(study_uuid, sp_uuid)
                .then(res_data => {
                    if (res_data.spot === null) {
                        setIsCancelled(true);
                    } else {
                        setIsPreview(false);
                        setData({
                            spot: res_data.spot,
                            timezone: res_data.timezone,
                            duration: res_data.study.duration,
                            location:
                                helpers.getStudyPersonLocation(res_data, res_data.study) ||
                                'Location will be shared at a later time',
                            logo: res_data.person.account.logo,
                            allow_session_changes:
                                res_data.person.account.allow_session_changes === SCHEDULING_SESSION_CHANGE_ALLOWED,
                            schedulingConfirmation: res_data.scheduling_confirmation,
                            title: res_data.person.account.title
                        });
                    }
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);
                    setErrorText(errorText);
                });
        }

        if (is_cancel) {
            setShowCancelSessionModal(true);
        }
    };

    const cancelSession = () => {
        setCancellingSession(true);

        const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
        const sp_uuid = queryParams.sp_uuid;

        scheduling_service
            .cancelSession(sp_uuid, isPreview)
            .then(res_data => {
                setCancellingSession(false);
                if (res_data.success) {
                    setIsCancelled(true);
                } else {
                    setErrorText('Session cancelation failed');
                }
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                setErrorText(errorText);
            });
    };

    useEffect(() => {
        getSession();
    }, []);

    useEffect(() => {
        if (errorText !== null || isCancelled !== null || (isPreview !== null && data)) {
            setIsLoading(false);
        }
    }, [errorText, isCancelled, isPreview, data]);

    if (isLoading) {
        return (
            <div style={{ width: '100%', height: '200px' }}>
                <LoadingWrapper>
                    <LoadingIndicator />
                </LoadingWrapper>
            </div>
        );
    }

    if (errorText !== null) {
        return <SchedulingPageError> {errorText} </SchedulingPageError>;
    }

    if (isCancelled) {
        return (
            <SchedulingPageWrapper isPreview={isPreview}>
                <Flex style={{ flexDirection: 'column', gap: '28px' }}>
                    <Flex style={{ justifyContent: 'center' }}>
                        <FiCheckCircle className="color-success" style={{ width: '48px', height: '48px' }} />
                    </Flex>
                    <h1 className="fs-headline-20 color-text-primary" style={{ textAlign: 'center', margin: 0 }}>
                        Session cancelled
                    </h1>
                </Flex>
            </SchedulingPageWrapper>
        );
    }

    return (
        <SchedulingPageWrapper
            isPreview={isPreview}
            status="session-scheduled"
            logo={data.logo || '/logo.svg'}
            account={data}
        >
            <Box>
                <h2 className="fs-accent-12 color-text-secondary" style={{ margin: 0, marginBottom: '8px' }}>
                    When
                </h2>
                <SchedulingPageNiceDateTime spot={data.spot} timezone={data.timezone} duration={data.duration} />
                {/*<div className="fs-body-12 color-text-secondary" style={{ marginTop: '12px' }}>
                    A calendar invite is not automatically sent to you. <br />
                    We suggest adding a reminder to your calendar.
                </div>*/}
            </Box>
            <Box>
                <h2 className="fs-accent-12 color-text-secondary" style={{ margin: 0, marginBottom: '8px' }}>
                    Where
                </h2>
                {helpers.isValidUrl(data.location) ? (
                    <a className="fs-accent-14 link-primary" href={data.location}>
                        {data.location}
                    </a>
                ) : (
                    <div className="fs-accent-14 color-text-primary">{data.location}</div>
                )}
            </Box>
            {data.schedulingConfirmation && (
                <Box>
                    <h2 className="fs-accent-12 color-text-secondary" style={{ margin: 0, marginBottom: '8px' }}>
                        Info
                    </h2>
                    {/* eslint-disable-next-line */}
                    <div
                        className="quill-render quill-render-compact"
                        dangerouslySetInnerHTML={{ __html: myxss.process(data.schedulingConfirmation) }}
                    />
                </Box>
            )}
            {data.allow_session_changes && (
                <>
                    <div className="divider color-bg-line-strong" />
                    <Flex style={{ gap: isMobile ? '8px' : '16px', flexDirection: isMobile ? 'column' : 'row' }}>
                        <h3 style={{ margin: 0 }} className="fs-accent-14 color-text-primary">
                            Can't make this time?
                        </h3>
                        <Flex style={{ gap: '8px' }}>
                            <Link className="fs-accent-14 link-primary" to={rescheduleHref}>
                                Reschedule
                            </Link>
                            <div className="fs-body-14 color-text-secondary">or</div>
                            <div
                                role="button"
                                tabIndex={0}
                                className="fs-accent-14 link-danger"
                                onKeyDown={event => event.key === 'Enter' && setShowCancelSessionModal(true)}
                                onClick={() => setShowCancelSessionModal(true)}
                            >
                                Cancel session
                            </div>
                        </Flex>
                    </Flex>
                </>
            )}
            <NiceModal
                isOpen={showCancelSessionModal}
                shouldCloseOnOverlayClick
                onRequestClose={() => setShowCancelSessionModal(false)}
                title="Cancel session"
                style={{ content: { width: '560px' } }}
            >
                <Flex style={{ flexDirection: 'column', gap: '32px' }}>
                    <Box style={{ flex: 1 }}>
                        <div className="fs-body-14 color-text-primary">
                            Are you sure you want to cancel this session? If you are unable to attend the session at the
                            specified time, you can{' '}
                            <Link className="fs-accent-14 link-primary underlined" to={rescheduleHref}>
                                reschedule
                            </Link>{' '}
                            the session to a convenient time for you.
                        </div>
                        <div
                            className="color-bg-danger-light"
                            style={{ borderRadius: '4px', marginTop: '20px', padding: '16px' }}
                        >
                            <SchedulingPageNiceDateTime
                                spot={data.spot}
                                timezone={data.timezone}
                                duration={data.duration}
                            />
                        </div>
                    </Box>
                    <Flex style={{ gap: '16px' }}>
                        <Button
                            variant="secondary-gray"
                            height="40px"
                            flex="1"
                            onClick={() => setShowCancelSessionModal(false)}
                        >
                            Go back
                        </Button>
                        <Button
                            variant="danger-filled"
                            height="40px"
                            flex="1"
                            disabled={cancellingSession}
                            onClick={cancelSession}
                        >
                            {cancellingSession ? 'Cancelling session...' : 'Cancel session'}
                        </Button>
                    </Flex>
                </Flex>
            </NiceModal>
        </SchedulingPageWrapper>
    );
};

export default PublicSchedulingResultPage;
