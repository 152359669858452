import React from 'react';
import ReactTooltip from 'react-tooltip';
import { FiInfo } from 'react-icons/fi';
import { Box } from 'rebass';

/**
 * This component is used to render an information icon with a tooltip.
 * When the user hovers over the icon, a tooltip will appear on the right side.
 *
 * @param {Object} props props
 * @param {string} props.id The id for the tooltip. This is required for 'data-for' attribute.
 * @param {React.ReactNode} props.children The content to be displayed inside the tooltip.
 * @param {React.CSSProperties} props.style The styles to be applied to the information icon.
 */
export function InformationTooltip({ id, children, style }) {
    return (
        <>
            <FiInfo data-tip data-for={id} style={{ color: '#606E85', minWidth: 'max-content', ...style }} />
            <ReactTooltip css={{ maxWidth: '400px', whiteSpace: 'break-spaces' }} id={id} effect="solid" place="right">
                <Box>{children}</Box>
            </ReactTooltip>
        </>
    );
}

export default InformationTooltip;
