exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".matrix__hZetk {\n  width: 100%;\n}\n\n.matrix__hZetk th, .matrix__hZetk td {\n  padding: 8px;\n  width: auto;\n  word-wrap: break-word;\n  white-space: normal;\n}\n\n.matrix__hZetk th {\n  max-width: 200px;\n  min-width: 140px;\n}\n\n.stickyColumn__1Idjl {\n  position: sticky;\n  left: 0;\n  z-index: 1;\n}\n\n.backgroundWhite__28uQK {\n  background-color: white;\n}\n\n.backgroundGrey__2MlK6 {\n  background-color: #f9f9f9;\n}\n\n.cell__2PTOx {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 4px;\n}\n", ""]);

// Exports
exports.locals = {
	"matrix": "matrix__hZetk",
	"stickyColumn": "stickyColumn__1Idjl",
	"backgroundWhite": "backgroundWhite__28uQK",
	"backgroundGrey": "backgroundGrey__2MlK6",
	"cell": "cell__2PTOx"
};