import classNames from 'classnames';
import React, { useMemo, useRef, useState } from 'react';
import { FiArrowLeft, FiArrowRight, FiChevronDown } from 'react-icons/fi';
import { Box, Button, Flex } from 'rebass';

import helpers from 'utils/helpers';
import Portal from 'components/Portal';
import { PositionerV2 } from 'components/Positioner';
import NiceDropdown from 'components/NiceDropdown';

/**
 * NumberedPagination. Component to display a numbered pagination.
 * It also includes a select to change the number of items per page.
 *
 * @param {Object} props
 * @param {Number} props.total total items
 * @param {Array} props.links links to the pages
 * @param {Number} props.from from item
 * @param {Number} props.to to item
 * @param {(page: number) => void} props.onPageChange Callback to change the page
 * @param {Number} props.perPage How many items per page
 * @param {Number} props.page Current page
 * @param {(perPage: number) => void} props.onPerPageChange Callback to change the number of items per page
 * @param {String?} props.className class name
 */
export function NumberedPagination({
    total,
    links,
    from,
    to,
    onPageChange,
    perPage,
    page,
    onPerPageChange,
    className
}) {
    const perPageRef = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const navigation = useMemo(() => {
        const previousLink = links[0];
        const nextLink = links[links.length - 1];

        // get the next and previous page numbers
        const next = nextLink && nextLink.url ? Number(helpers.getQueryParam(nextLink.url, 'page')) : null;
        const prev = previousLink && previousLink.url ? Number(helpers.getQueryParam(previousLink.url, 'page')) : null;

        return {
            next,
            prev,
            // remove the first and last links (previous and next)
            links: links.slice(1, -1)
        };
    }, [links]);

    return (
        <Flex
            height="44px"
            sx={{ position: 'relative' }}
            alignItems="center"
            p="12px 12px"
            className={classNames('bg-pure', className)}
        >
            <Button variant="transparent" className="text-pure strong" ref={perPageRef} onClick={() => setIsOpen(true)}>
                {perPage} per page
                <FiChevronDown style={{ margin: '4px 0 0 8px' }} />
            </Button>
            <Portal isOpen={isOpen}>
                <PositionerV2 anchorRef={perPageRef}>
                    <NiceDropdown
                        style={{ inset: 'auto', position: 'static' }}
                        width="140px"
                        onClose={() => setIsOpen(false)}
                        items={[
                            {
                                title: '20 per page',
                                onClick: () => onPerPageChange(20)
                            },
                            {
                                title: '100 per page',
                                onClick: () => onPerPageChange(100)
                            },
                            {
                                title: '500 per page',
                                onClick: () => onPerPageChange(500)
                            }
                        ]}
                    />
                </PositionerV2>
            </Portal>
            <Box as="span" className="vertical-line divider-color" px={3}>
                |
            </Box>
            <Box as="p" className="fs-title-14 color-text-primary">
                {helpers.numberFormat(Number(from))}-{helpers.numberFormat(Number(to))} of{' '}
                {helpers.numberFormat(Number(total))}
            </Box>
            <Flex sx={{ gap: 2, position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                <Button
                    variant="secondary-gray"
                    onClick={() => onPageChange(navigation.prev)}
                    disabled={!navigation.prev}
                >
                    <FiArrowLeft css={{ marginRight: '6px' }} />
                    Prev
                </Button>
                {navigation.links.map((link, index) => {
                    const variant =
                        page === Number(link.label)
                            ? helpers.isMinimalUi()
                                ? 'primary-outline'
                                : 'primary'
                            : 'secondary-gray';
                    return (
                        <Button
                            key={`${link.label}-${index}`}
                            variant={variant}
                            className="pagination-button"
                            disabled={!link.url}
                            onClick={() => onPageChange(Number(link.label))}
                        >
                            {!link.url ? '...' : helpers.numberFormat(Number(link.label))}
                        </Button>
                    );
                })}
                <Button
                    variant="secondary-gray"
                    onClick={() => onPageChange(navigation.next)}
                    disabled={!navigation.next}
                >
                    Next
                    <FiArrowRight css={{ marginRight: '0px !important', marginLeft: '6px !important' }} />
                </Button>
            </Flex>
        </Flex>
    );
}

export default NumberedPagination;
