import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NiceWrapper from 'components/NiceWrapper';
import { Flex, Box, Heading, Button } from 'rebass';
import { Label, Switch } from '@rebass/forms';
import helpers from 'utils/helpers';
import service_people from 'services/people';
import services from 'services/services';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import LoadingIndicator from 'components/LoadingIndicator';
import { useToasts } from 'react-toast-notifications';

function ConsentManagementPage(props) {
    const [loading, setLoading] = useState(true);
    const [personData, setPersonData] = useState({});
    const [account, setAccount] = useState({});

    const { addToast } = useToasts();

    useEffect(() => {
        getPersonXHR(props.match.params.account_uuid, props.match.params.person_uuid);
    }, [props.match.params.account_uuid, props.match.params.person_uuid]);

    function getPersonXHR(account_uuid, person_uuid) {
        service_people
            .getPersonUuid(account_uuid, person_uuid)
            .then(person => {
                //console.log('person', person);
                setPersonData(person);
                setLoading(false);
                setAccount(person.account);
            })
            .catch(e => helpers.trackError(e));
    }

    function processOptOutOptIn() {
        // yeah I wanted to be super explicit here b/c its a bit backwards :)
        const opt_out_value_current = helpers.personIsOptedOut(personData);
        const opt_in_value_current = !opt_out_value_current;
        const opt_in_value_new = !opt_in_value_current;

        service_people
            .consentOptIn(props.match.params.account_uuid, props.match.params.person_uuid, opt_in_value_new)
            .then(person => {
                setPersonData(person);
            })
            .catch(e => helpers.trackError(e));
    }

    function processDeleteData() {
        // eslint-disable-next-line
        if (confirm('Deleting your data is irreversible. Would you like to proceed?')) {
            service_people
                .consentDeleteData(props.match.params.account_uuid, props.match.params.person_uuid)
                .then(() => {
                    //alert('Your deletion request was submitted.');
                    // eslint-disable-next-line
                    location.reload();
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    addToast(errorText, {
                        appearance: 'error',
                        autoDismiss: false
                    });
                });
        }
    }

    return (
        <div style={{ margin: 'auto' }}>
            <NiceWrapper style={{ background: 'white', color: 'black', padding: '32px', margin: '32px' }}>
                <Heading fontSize={[2, 3]}>Consent Management</Heading>
                {/* eslint-disable-next-line */}
                {loading ? (
                    <Box my={4} style={{ width: '100%', height: '80px', position: 'relative' }}>
                        <LoadingWrapper>
                            <LoadingIndicator />
                        </LoadingWrapper>
                    </Box>
                ) : personData && personData.uuid ? (
                    <Box mt={4} fontSize={1}>
                        <Box mb={3} fontWeight={600}>
                            {helpers.personGetCustomValue(personData, 'First Name')}{' '}
                            {helpers.personGetCustomValue(personData, 'Last Name')}
                            <br />
                            {helpers.personGetCustomValue(personData, 'Email')}
                        </Box>

                        {props.match.params.cm_action && props.match.params.cm_action == 'opt-out' && (
                            <Box>
                                <Label mb={2}>Change your communication preference</Label>
                                <Flex>
                                    <Box>
                                        <Switch
                                            id="opt-out-opt-in"
                                            onClick={processOptOutOptIn}
                                            checked={!helpers.personIsOptedOut(personData)}
                                            mr={2}
                                        />
                                    </Box>
                                    <Box>
                                        <Label htmlFor="opt-out-opt-in" className="loud">
                                            Opted {helpers.personIsOptedOut(personData) ? 'out' : 'in'}
                                        </Label>
                                    </Box>
                                </Flex>

                                <Button
                                    as={Link}
                                    to={`/consent-management/${props.match.params.account_uuid}/${props.match.params.person_uuid}`}
                                    variant="transparent"
                                    width="100%"
                                    style={{ padding: '5px 16px' }}
                                    mt={4}
                                >
                                    Back to profile
                                </Button>
                            </Box>
                        )}
                        {props.match.params.cm_action && props.match.params.cm_action == 'delete' && (
                            <Box>
                                <Label mb={2}>Delete my profile and all of my data?</Label>
                                <Button type="button" variant="danger" width="100%" onClick={processDeleteData}>
                                    Delete my data
                                </Button>

                                <Button
                                    as={Link}
                                    to={`/consent-management/${props.match.params.account_uuid}/${props.match.params.person_uuid}`}
                                    variant="transparent"
                                    width="100%"
                                    style={{ padding: '5px 16px' }}
                                    mt={4}
                                >
                                    Back to profile
                                </Button>
                            </Box>
                        )}
                        {/*props.match.params.cm_action && props.match.params.cm_action == 'update' && (
                            <Box>
                                UPDATE ME

                                <Link
                                    to={`/consent-management/${props.match.params.account_uuid}/${props.match.params.person_uuid}`}
                                >
                                    <Button type="button" variant="tertiary" width="100%" mt={4}>
                                        Back to profile
                                    </Button>
                                </Link>
                            </Box>
                        )*/}
                        {!props.match.params.cm_action && (
                            <>
                                {/*<Box mt={2} mb={2}>
                                    <Link
                                        to={`/consent-management/${props.match.params.account_uuid}/${props.match.params.person_uuid}/update`}
                                    >
                                        <Button type='button' variant='primary'>
                                            Update profile
                                        </Button>
                                    </Link>
                                </Box>*/}
                                <Box mt={2}>
                                    <Link
                                        to={`/consent-management/${props.match.params.account_uuid}/${props.match.params.person_uuid}/opt-out`}
                                    >
                                        Manage communication opt-out
                                    </Link>
                                </Box>
                                <Box mt={2}>
                                    <Link
                                        to={`/consent-management/${props.match.params.account_uuid}/${props.match.params.person_uuid}/delete`}
                                    >
                                        Manage deletion of my profile and data
                                    </Link>
                                </Box>
                            </>
                        )}
                    </Box>
                ) : (
                    <Box mt={4}>Profile not found.</Box>
                )}
            </NiceWrapper>

            {helpers.renderScreenerFooter(account)}
        </div>
    );
}

export default ConsentManagementPage;
