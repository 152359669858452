import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { Button } from 'rebass';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';

import { PositionerV2 } from 'components/Positioner';
import Portal from 'components/Portal';
import InputSearch from 'components/InputSearch';
import Card from 'components/Card';

import styles from './Combobox.module.css';

/**
 * Combobox component. Kind of like a select, but with a search input.
 *
 * @param {{ value: string | number; label: string }[]} items List of items (options)
 * @param {string | number} value Currently selected value
 * @param {(value: string | number) => void} onChange Callback when the value changes
 * @param {string} [className]
 * @param {React.CSSProperties} [style]
 * @param {string} [ariaLabel] Aria label for the combobox
 */
export function Combobox({ items = [], value, onChange, className, style, ariaLabel }) {
    const anchorRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [activeIndex, setActiveIndex] = useState(null);

    // Find the currently selected item’s label
    const label = useMemo(() => {
        const selectedItem = items.find(item => item.value === value);
        return selectedItem ? selectedItem.label : '';
    }, [items, value]);

    // Filter options based on the query
    const options = useMemo(() => items.filter(item => new RegExp(query, 'i').test(item.label)), [items, query]);

    const handleKeyDown = e => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (activeIndex === null) {
                setActiveIndex(0);
            } else {
                setActiveIndex(prev => Math.min(options.length - 1, prev + 1));
            }
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            if (activeIndex === null) {
                setActiveIndex(options.length - 1);
            } else {
                setActiveIndex(prev => Math.max(0, prev - 1));
            }
        } else if (e.key === 'Enter') {
            e.preventDefault();
            // Fallback to the first option if activeIndex is null
            const selectedOption = activeIndex !== null ? options[activeIndex] : options[0];

            if (selectedOption) {
                onChange(selectedOption.value);
                setIsOpen(false);
                setQuery('');
            }
        } else if (e.key === 'Escape') {
            e.preventDefault();
            setIsOpen(false);
        } else if (e.key === 'Tab') {
            setIsOpen(false);
        }
    };

    const markFirstOptionAsActive = () => {
        if (options.length > 0) {
            setActiveIndex(0);
        } else {
            setActiveIndex(null);
        }
    };

    const handleOptionClick = optionValue => {
        onChange(optionValue);
        setIsOpen(false);
        setQuery('');
    };

    const search = query => {
        setQuery(query);
    };

    useEffect(() => {
        if (isOpen && options.length > 0) {
            markFirstOptionAsActive();
        }
    }, [isOpen, options]);

    useEffect(() => {
        if (activeIndex !== null && options[activeIndex]) {
            const activeElement = document.getElementById(`option-${options[activeIndex].value}`);
            if (activeElement) {
                activeElement.scrollIntoView({ block: 'nearest' });
            }
        }
    }, [activeIndex, options]);

    return (
        <>
            <Button
                id="combobox-button"
                width="100%"
                display="flex"
                className={classNames('flex items-center justify-between', className)}
                ref={anchorRef}
                variant="secondary-gray"
                role="combobox"
                aria-haspopup="listbox"
                aria-expanded={isOpen}
                aria-owns="combobox-list"
                aria-controls="combobox-list"
                aria-describedby="combobox-trigger-instructions"
                onClick={() => setIsOpen(true)}
                style={style}
                aria-label={ariaLabel}
            >
                {label || 'Select an option'} <FiChevronDown role="img" aria-label="Arrow down" style={{ margin: 0 }} />
            </Button>
            <div id="combobox-trigger-instructions" className="visually-hidden">
                Press Enter to open the combobox options.
            </div>
            {isOpen && (
                <Portal isOpen={isOpen}>
                    <PositionerV2 anchorRef={anchorRef}>
                        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                            <Card className={styles.card} style={{ width: anchorRef.current.offsetWidth }}>
                                <div className={styles.searchContainer}>
                                    <InputSearch
                                        ref={ref => ref && ref.focus({ preventScroll: true })}
                                        id="combobox-input"
                                        className="nice-underlined nice-dropdown-search"
                                        value={query}
                                        onChange={event => search(event.target.value)}
                                        aria-label="Search options"
                                        aria-activedescendant={
                                            activeIndex !== null && options[activeIndex]
                                                ? `option-${options[activeIndex].value}`
                                                : undefined
                                        }
                                        aria-autocomplete="list"
                                        aria-describedby="combobox-instructions"
                                        onKeyDown={handleKeyDown}
                                    />
                                    <div id="combobox-instructions" className="visually-hidden">
                                        Type to search. Use up and down arrows to navigate. Press Enter to select.
                                        Escape to close.
                                    </div>
                                </div>
                                <div
                                    id="combobox-list"
                                    role="listbox"
                                    aria-live="assertive"
                                    aria-relevant="additions removals"
                                    className={styles.list}
                                >
                                    {options.length > 0 &&
                                        options.map((option, index) => (
                                            <div
                                                role="option"
                                                aria-selected={option.value === value}
                                                id={`option-${option.value}`}
                                                key={option.value}
                                                tabIndex={-1}
                                                onClick={() => handleOptionClick(option.value)}
                                                onMouseEnter={() => setActiveIndex(index)}
                                                className={classNames(
                                                    styles.option,
                                                    index === activeIndex && styles.activeOption
                                                )}
                                            >
                                                {option.label}
                                            </div>
                                        ))}
                                    {options.length === 0 && (
                                        <div
                                            className={styles.empty}
                                            role="status"
                                            aria-live="assertive"
                                            aria-atomic="true"
                                        >
                                            <strong>0 results</strong>
                                        </div>
                                    )}
                                </div>
                            </Card>
                        </OutsideClickHandler>
                    </PositionerV2>
                </Portal>
            )}
        </>
    );
}
