import React from 'react';
import helpers from 'utils/helpers';
import config from 'config';

function ErrorBoundaryFallback() {
    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
            }}
        >
            <img src="/logo.svg" alt="Panelfox Logo" style={{ width: '100px', marginBottom: '20px' }} />
            <p style={{ textAlign: 'center' }}>
                An error occurred. Please try refreshing the page.
                <br />
                If the problem persists, please contact support at{' '}
                <a href={`mailto:${config.SUPPORT_EMAIL}`} style={{ color: '#0A73EB', textDecoration: 'underline' }}>
                    {config.SUPPORT_EMAIL}
                </a>
                .
            </p>
        </div>
    );
}

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, info) {
        helpers.trackError(error, {
            stack: info.componentStack
        });
    }

    render() {
        if (this.state.hasError) {
            return <ErrorBoundaryFallback />;
        }

        return this.props.children;
    }
}
