import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const activeClassName = 'nav-item-active';

export const HeaderSubnavLinkStyle = `display: flex;
  padding: 4px 0 10px 0;
  margin: 0 32px 0 0 ;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  font-size: 14px;
  color: #001233;
  transition: background-color 250ms, color 250ms;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
  font-weight: 500;

  svg {
    margin: 4px 8px 0 0;
    font-size: 16px;
    stroke-width: 2px;
  }

  &.basic-subnav {
      color: #001233;
      flex-direction: row;
  }

  .subnav-active-only {
      display: none;
  }
  &.active .subnav-active-only {
      display: inline-block;
  }

  .subnav-label {
      color: #606E85;
  }
  &.active .subnav-label {
      color: #0a73eb !important;
  }
  &:hover .subnav-label{
      color: #0a73eb;
  }

  .subnav-number{
      padding: 0 0 0 0;
    
    font-size: 16px;
    //font-weight: 600;
  }

  .subnav-primary{
    padding: 8px 0 0 0;
    font-size: 14px;
    color: #666;
    font-weight: 500;
  }
  &:hover .subnav-primary{
       color: #0a73eb;
  }
  &.nav-item-active .subnav-primary{
      color: #0a73eb !important;
  }

  &:hover {
      //color: #000;
      color: #0a73eb;
  }

  &.nav-item-active {
    color: #0a73eb !important;
    border-bottom: 2px solid #0a73eb !important;
  }

  .textStyle {
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1 1 0%;
    overflow: hidden;
  }

  .companyName {
      color: rgba(46, 31, 130, 1);
  }

  .iconWrapper {
      flex: 0 0 10px;
      pointer-events: none;
  }

  .iconEnd {
      position: absolute;
      right: 0;

      svg {
          margin-right: 8px;
      }
  }

  &.link-accountSwitcher .accountSwitcher {
      background: #fff;
      display: block;
      position: absolute;
      top: 0;
      left: calc(100% + 8px + 8px);
      word-break: keep-all;
      padding: 0;
      border-radius: 4px;

      div {
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 200px;
      }
  }

`;

export const HeaderSubnavLink = styled(NavLink).attrs({ activeClassName })`
    ${HeaderSubnavLinkStyle}
`;
