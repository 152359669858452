import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Button, Flex } from 'rebass';
import { Input, Select } from '@rebass/forms';
import { FiTrash2, FiEdit, FiPlus } from 'react-icons/fi';
import xhr from 'xhr.js';
import { withToastManager } from 'react-toast-notifications';
import H2 from 'components/H2';
import SideNav from 'components/SideNav';
import ReactTooltip from 'react-tooltip';
import services from 'services/services';
import service_studies from 'services/studies';
import helpers from 'utils/helpers';
import { CURRENCIES, CURRENCY_SYMBOL } from 'utils/constants';

import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import StudyPageSubNav from 'components/Study/StudyPageSubNav';
import CustomAttributeEdit from 'components/Study/CustomAttributeEdit';
import NiceDropdown from 'components/NiceDropdown';
import NiceModal from 'components/NiceModal';
import TextAreaAutosize from 'react-textarea-autosize';
import BreadcrumbBack, { BreadcrumbTitle } from 'components/BreadcrumbBack';
import cache from 'utils/cache';
import { ManageStudyMembers } from 'components/ManageStudyMembersModal';
import { StudyUpdatedPopup } from 'components/StudyUpdatedPopup';
import studyService from 'services/studies';

class StudySettingsPage extends React.Component {
    constructor(props) {
        super(props);

        const studyId = this.props.match.params.id;
        const study = cache.get(`study.${studyId}`);

        this.state = {
            loading: !study,
            study: {
                title: '',
                title_public: '',
                description: '',
                ...study
            },
            account_users: [],
            tremendous_campaigns: [],
            showModalCspaEdit: false,
            cspaEditing: {},
            tags: [],
            showChangeStatusTip: false,
            isSendUpdatesPopupOpen: false
        };

        this.onFormChange = this.onFormChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.deleteStudyConfirm = this.deleteStudyConfirm.bind(this);
        this.saveCustomAttribute = this.saveCustomAttribute.bind(this);
        this.removeCustomAttribute = this.removeCustomAttribute.bind(this);
        this.duplicateStudy = this.duplicateStudy.bind(this);

        this.studyInformationRef = React.createRef();
        this.customAttributesRef = React.createRef();
        this.tagsRef = React.createRef();
        this.otherActionsRef = React.createRef();
        this.studyMembersSectionRef = React.createRef();
    }

    duplicateStudy() {
        if (!confirm('Are you sure you want to duplicate this study?')) {
            return false;
        }

        const { toastManager } = this.props;

        service_studies
            .duplicateStudy(this.state.study.id)
            .then(newStudy => {
                window.location = `/studies/${newStudy.id}`;
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    componentDidMount() {
        const { toastManager } = this.props;

        xhr.get(`/studies/${this.props.match.params.id}?account_id=${this.props.auth.account.id}`, {
            withCredentials: true
        }).then(res => {
            const study = res.data;
            cache.set(`study.${study.id}`, study);

            this.setState({
                study,
                loading: false
            });

            services
                .getAccountUsers()
                .then(account_users_xhr => {
                    this.setState({ account_users: account_users_xhr });
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });

            service_studies
                .getTremendousCampaigns()
                .then(tremendous_campaigns => {
                    this.setState({ tremendous_campaigns });
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });

            this.getStudyTags();
        });
    }

    onCreateStudyTag(tag_title) {
        const { toastManager } = this.props;

        services
            .createTag('Study', tag_title)
            .then(tag => {
                let { tags } = this.state;
                tags.push(tag);
                this.setState({ tags });

                this.addTagToStudy(tag.id);

                toastManager.add('Successfully created tag', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    addTagToStudy(tag_id) {
        const { toastManager } = this.props;

        services
            .addTagToStudy(this.state.study.id, tag_id)
            .then(study_tags => {
                let { study } = this.state;
                study.tags = study_tags;

                this.setState({ study });

                toastManager.add('Successfully added tag to this study', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    removeTagFromStudy(tag_id) {
        const { toastManager } = this.props;

        services
            .removeTagFromStudy(this.state.study.id, tag_id)
            .then(study_tags => {
                let { study } = this.state;
                study.tags = study_tags;

                this.setState({ study });

                toastManager.add('Successfully removed tag from this study', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    getStudyTags() {
        service_studies
            .getStudyTags()
            .then(tags => {
                let mv2l = {};
                tags.forEach(t => {
                    mv2l[t.id] = t.title;
                });

                this.setState({ tags, mapValueToLabel: mv2l });
            })
            .catch(error => {
                helpers.trackError(error);
            });
    }

    updateStatusXhr(newStatus) {
        const { toastManager } = this.props;

        xhr.put(
            `/studies/${this.state.study.id}/update-status?account_id=${this.props.auth.account.id}`,
            { status: newStatus },
            { withCredentials: true }
        )
            .then(response => {
                console.log(response);

                if (newStatus == 'deleted') {
                    toastManager.add('Study deleted', {
                        appearance: 'success',
                        autoDismiss: true
                    });

                    this.props.history.push(`/studies`);
                } else {
                    toastManager.add('Status updated', {
                        appearance: 'info',
                        autoDismiss: true
                    });
                }
            })
            .catch(error => {
                services.parseAndTrackXhrErrors(error);
                let errorText = 'Error';

                if (error.response) {
                    errorText = '';
                    Object.keys(error.response.data).forEach(property => {
                        errorText += `${error.response.data[property]} `;
                    });
                }

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    deleteStudyConfirm() {
        // eslint-disable-next-line no-restricted-globals
        const conf = confirm('Are you sure you want to delete this study?');
        if (conf == true) {
            this.updateStatusXhr('deleted');
        }
    }

    onFormSubmit(e) {
        e.preventDefault();

        const study = { ...this.state.study };
        this.saveStudyXHR(study);
    }

    onFormChange(e) {
        if (!e.target.name) {
            return;
        }
        const study = { ...this.state.study };
        study[e.target.name] = e.target.value;
        //console.log('....here1')
        if (e.target.name == 'status') {
            //console.log('....here2')
            this.setState({ showChangeStatusTip: true });
        }

        this.setState({ study });

        // detect change, and clear timeout if exists
        if (this.saveDelay) {
            clearTimeout(this.saveDelay);
        }

        this.saveDelay = setTimeout(
            function() {
                this.saveStudyXHR(study);
            }.bind(this),
            500
        );
    }

    saveStudyXHR(study) {
        const { toastManager } = this.props;

        xhr.put(`/studies/${study.id}?account_id=${this.props.auth.account.id}`, study, { withCredentials: true })
            .then(() => {
                toastManager.add('Saved', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                services.parseAndTrackXhrErrors(error);
                let errorText = 'Error';
                if (error.response) {
                    errorText = '';
                    Object.keys(error.response.data).forEach(property => {
                        errorText += `${error.response.data[property]} `;
                    });
                }

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    removeCustomAttribute(cspa) {
        if (confirm(`Are you sure you want to delete the "${cspa.title}" attribute permanently?`)) {
            const { study } = this.state;
            if (study.custom_sp_attributes) {
                const found = study.custom_sp_attributes.find(a => a.id == cspa.id);
                const found_index = study.custom_sp_attributes.indexOf(found);
                if (found_index > -1) {
                    study.custom_sp_attributes.splice(found_index, 1);
                    this.setState({ study }, () => {
                        this.saveStudyXHR(study);
                    });
                }
            }
        }
    }

    saveCustomAttribute(cspa) {
        console.log('saveCustomAttribute', cspa);
        const { study } = this.state;

        if (cspa.id) {
            const cspaIndex = study.custom_sp_attributes.findIndex(c => c.id == cspa.id);
            study.custom_sp_attributes[cspaIndex] = cspa;
            this.setState({ study }, () => {
                this.saveStudyXHR(study);
            });
        } else if (cspa.title) {
            study.custom_sp_attributes.push({
                id: helpers.IDFromString(cspa.title) + helpers.ID(),
                title: cspa.title,
                type: cspa.type,
                definition: cspa.definition
            });

            this.setState({ study }, () => {
                this.saveStudyXHR(study);
            });
        }
    }

    async checkIfRespondentsScheduledInFuture() {
        const { toastManager } = this.props;

        try {
            const { in_future: inFuture } = await studyService.checkIfRespondentsScheduledInFuture(this.state.study.id);

            if (inFuture) {
                this.setState({ isSendUpdatesPopupOpen: true });
            }
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    render() {
        return (
            <Flex style={{ width: '100%' }}>
                <AppPageWrapper>
                    <Helmet>
                        <title>{this.state.study.title} Settings</title>
                    </Helmet>
                    <AppPageWrapperSectionHeader className="experience experience-no-subnav">
                        <Box>
                            <BreadcrumbBack to="/studies">Back to all studies</BreadcrumbBack>
                            <BreadcrumbTitle title={this.state.study.title} isLoading={this.state.loading} />
                        </Box>
                    </AppPageWrapperSectionHeader>
                    <Flex>
                        <StudyPageSubNav study_id={this.props.match.params.id} study={this.state.study} />
                    </Flex>

                    <AppPageWrapperSectionBody>
                        {this.state.loading ? (
                            <div style={{ width: '100%' }}>
                                <LoadingWrapper>
                                    <LoadingIndicator />
                                </LoadingWrapper>
                            </div>
                        ) : (
                            <Flex mt={3} mx={4} flex="1 1 0%" justifyContent="space-between">
                                <Box flex="0 0 auto">
                                    <form onSubmit={this.onFormSubmit}>
                                        <Box
                                            id="study-information"
                                            ref={this.studyInformationRef}
                                            className="form-wrapper2 form-wrapper2-first"
                                        >
                                            <Flex className="settings-header">
                                                <Box className="form-data">
                                                    <H2>Study Information</H2>
                                                    <Box className="form-section-information">
                                                        Internal properties are never shown to panelists, and the Public
                                                        Title is shown in the panelist's logged in profile.
                                                    </Box>
                                                </Box>
                                            </Flex>
                                            <Box className="form-data">
                                                <Flex sx={{ gridGap: '24px' }}>
                                                    <Box width={1 / 2}>
                                                        <Box className="form-label">Internal Title</Box>
                                                        <Box>
                                                            <Input
                                                                type="text"
                                                                name="title"
                                                                placeholder="Internal Title"
                                                                value={this.state.study.title}
                                                                onChange={this.onFormChange}
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <Box width={1 / 2}>
                                                        <Box className="form-label">Public Title</Box>
                                                        <Box>
                                                            <Input
                                                                type="text"
                                                                name="title_public"
                                                                placeholder="Public Title"
                                                                value={this.state.study.title_public}
                                                                onChange={this.onFormChange}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Flex>

                                                <Box className="form-label form-row-margin">Internal Description</Box>
                                                <Box>
                                                    <TextAreaAutosize
                                                        name="description"
                                                        placeholder="Internal study description"
                                                        value={this.state.study.description}
                                                        onChange={this.onFormChange}
                                                        style={{ width: '100%' }}
                                                        minRows={4}
                                                        className="theme-input"
                                                    ></TextAreaAutosize>
                                                </Box>

                                                <Box className="form-label form-row-margin">Status</Box>
                                                <Box>
                                                    <Select
                                                        name="status"
                                                        value={this.state.study.status}
                                                        onChange={this.onFormChange}
                                                        width={160}
                                                    >
                                                        <option value="active">Active</option>
                                                        <option value="paused">Paused</option>
                                                        <option value="complete">Complete</option>
                                                    </Select>

                                                    {this.state.showChangeStatusTip &&
                                                    ['paused', 'complete'].includes(this.state.study.status) ? (
                                                        <Box mt={1} className={'fs-13 green'}>
                                                            This status change automatically marked all public screeners
                                                            as "inactive".
                                                        </Box>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Box>

                                                {/* <Label mt={3}>
                                                    Recurring{' '}
                                                    <FiInfo
                                                        data-tip
                                                        data-for="tooltipRecurring"
                                                        style={{ color: '#606E85', margin: '3px 0 0 4px' }}
                                                    />
                                                </Label>
                                                <Select
                                                    value={this.state.study.recurring}
                                                    onChange={this.onFormChange}
                                                    name="recurring"
                                                    width={160}
                                                >
                                                    <option value="once">No</option>
                                                    <option value="weekly">Weekly</option>
                                                </Select> */}
                                                <ReactTooltip id="tooltipRecurring" effect="solid" place="right">
                                                    <span>
                                                        You can choose to recruit a new panel every week.
                                                        <br />
                                                        If you recruit weekly, your study will fill up the quota for a
                                                        week,
                                                        <br />
                                                        and start to find new participants starting the next Monday.
                                                    </span>
                                                </ReactTooltip>

                                                <Flex sx={{ gridGap: '24px' }} className="form-row-margin">
                                                    <Box width={1 / 2}>
                                                        <Box className="form-label">Incentive</Box>
                                                        <Flex sx={{ gridGap: '24px' }}>
                                                            <Box width={1 / 2}>
                                                                <Select
                                                                    name="incentive_currency"
                                                                    value={this.state.study.incentive_currency}
                                                                    onChange={this.onFormChange}
                                                                    sx={{ width: '100% !important' }}
                                                                >
                                                                    {CURRENCIES.map(c => {
                                                                        return (
                                                                            <option value={c}>
                                                                                {CURRENCY_SYMBOL[c]} ({c})
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Box>
                                                            <Box width={1 / 2}>
                                                                <Input
                                                                    type="number"
                                                                    name="incentive"
                                                                    onChange={this.onFormChange}
                                                                    required
                                                                    value={this.state.study.incentive}
                                                                />
                                                            </Box>
                                                        </Flex>
                                                    </Box>

                                                    {this.state.tremendous_campaigns &&
                                                        this.state.tremendous_campaigns.length > 0 && (
                                                            <Box width={1 / 2}>
                                                                <Box className="form-label">Tremendous Campaign</Box>
                                                                <Box>
                                                                    <Select
                                                                        name="tremendous_campaign_id"
                                                                        value={this.state.study.tremendous_campaign_id}
                                                                        onChange={this.onFormChange}
                                                                        sx={{ width: '100% !important' }}
                                                                    >
                                                                        <option value={''}>None</option>
                                                                        {this.state.tremendous_campaigns.map(tr => {
                                                                            return (
                                                                                <option value={tr.id}>{tr.name}</option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                </Box>
                                                            </Box>
                                                        )}
                                                </Flex>
                                            </Box>
                                        </Box>
                                        <Box
                                            id="custom-attributes"
                                            ref={this.customAttributesRef}
                                            className="form-wrapper2 form-wrapper2-border"
                                        >
                                            <Flex className="settings-header">
                                                <Box>
                                                    <H2>Custom Attributes</H2>
                                                    <Box className="form-section-information">
                                                        These are additional study grid colums (e.g. Call Notes).{' '}
                                                        <a
                                                            href="#"
                                                            data-beacon-article="630f030a90c29a3d732c3495"
                                                            className="help-question"
                                                        >
                                                            ?
                                                        </a>
                                                    </Box>
                                                </Box>
                                            </Flex>
                                            <Box className="form-data">
                                                <Box>
                                                    {this.state.study.custom_sp_attributes &&
                                                    this.state.study.custom_sp_attributes.length ? (
                                                        this.state.study.custom_sp_attributes.map(cspa => (
                                                            <Box className="row-hover row-basic-grid">
                                                                <Flex sx={{ gridGap: 3, width: '100%' }}>
                                                                    <Box
                                                                        sx={{
                                                                            flexGrow: 1,
                                                                            color: 'black',
                                                                            padding: '4px 0 0 0'
                                                                        }}
                                                                        fontSize={1}
                                                                    >
                                                                        <input value={cspa.id} hidden disabled />
                                                                        {cspa.title}
                                                                        <br />
                                                                        <Box
                                                                            sx={{
                                                                                flexGrow: 0,
                                                                                padding: '4px 0 0 0',
                                                                                fontSize: 12
                                                                            }}
                                                                            className="text-secondary-dark"
                                                                        >
                                                                            ID: {cspa.id}
                                                                        </Box>
                                                                    </Box>
                                                                    <Box>
                                                                        <Button
                                                                            type="button"
                                                                            variant="secondary-gray"
                                                                            className="secondary-icon"
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    showModalCspaEdit: true,
                                                                                    cspaEditing: cspa
                                                                                });
                                                                            }}
                                                                            mr={2}
                                                                        >
                                                                            <FiEdit />
                                                                        </Button>
                                                                        <Button
                                                                            type="button"
                                                                            variant="secondary-gray"
                                                                            className="secondary-icon"
                                                                            onClick={() =>
                                                                                this.removeCustomAttribute(cspa)
                                                                            }
                                                                        >
                                                                            <FiTrash2 style={{ marginRight: 0 }} />
                                                                        </Button>
                                                                    </Box>
                                                                </Flex>
                                                            </Box>
                                                        ))
                                                    ) : (
                                                        <Box mt={3} className="text-primary">
                                                            0 custom attributes
                                                        </Box>
                                                    )}
                                                </Box>
                                                <Button
                                                    type="button"
                                                    mr={0}
                                                    mt={3}
                                                    variant="secondary"
                                                    onClick={() => {
                                                        this.setState({ showModalCspaEdit: true, cspaEditing: {} });
                                                    }}
                                                >
                                                    <FiPlus /> Add Attribute
                                                </Button>
                                            </Box>
                                        </Box>
                                        <Box
                                            id="study-members-section"
                                            ref={this.studyMembersSectionRef}
                                            className="form-wrapper2 form-wrapper2-border"
                                        >
                                            <Flex className="settings-header">
                                                <Box className="form-data">
                                                    <H2>Study Members</H2>
                                                    <Box className="form-section-information">
                                                        These individuals will get notifications for this study.
                                                        <br />
                                                        <br />
                                                        Enable calendar priority to respect collaborator availability
                                                    </Box>
                                                </Box>
                                            </Flex>
                                            <Box className="form-data">
                                                <ManageStudyMembers
                                                    studyId={this.state.study.id}
                                                    onCollaboratorsUpdate={() =>
                                                        this.checkIfRespondentsScheduledInFuture()
                                                    }
                                                    onObserversUpdate={() => this.checkIfRespondentsScheduledInFuture()}
                                                />
                                            </Box>
                                        </Box>
                                        <Box
                                            id="tags"
                                            ref={this.tagsRef}
                                            className="form-wrapper2 form-wrapper2-border"
                                        >
                                            <Flex className="settings-header">
                                                <Box>
                                                    <H2>Tags</H2>
                                                    <Box className="form-section-information">
                                                        Use study tags to filter Studies or Panelists.{' '}
                                                        <a
                                                            href="#"
                                                            data-beacon-article="630f030a90c29a3d732c3495"
                                                            className="help-question"
                                                        >
                                                            ?
                                                        </a>
                                                    </Box>
                                                </Box>
                                            </Flex>
                                            <Box className="form-data">
                                                <Box>
                                                    {this.state.study.tags && this.state.study.tags.length > 0 ? (
                                                        <Box>
                                                            {this.state.study.tags.map(t => {
                                                                return (
                                                                    <Box className="row-hover row-basic-grid">
                                                                        <Flex>
                                                                            <span
                                                                                className="black"
                                                                                style={{
                                                                                    flexGrow: 1,
                                                                                    marginTop: '6px',
                                                                                    display: 'inline-block',
                                                                                    verticalAlign: 'top'
                                                                                }}
                                                                            >
                                                                                {t.title}
                                                                            </span>
                                                                            <Button
                                                                                ml={2}
                                                                                type="button"
                                                                                variant="secondary-gray"
                                                                                className="secondary-icon"
                                                                                onClick={() =>
                                                                                    this.removeTagFromStudy(t.id)
                                                                                }
                                                                            >
                                                                                <FiTrash2 />
                                                                            </Button>
                                                                        </Flex>
                                                                    </Box>
                                                                );
                                                            })}
                                                        </Box>
                                                    ) : (
                                                        <Box mt={3} className="text-primary">
                                                            0 tags
                                                        </Box>
                                                    )}

                                                    {/*this.state.study.tags && this.state.study.tags.length == 0 && (
                                                        <Box mb={3} color="black" fontSize={1}>
                                                            0 tags
                                                        </Box>
                                                    )*/}
                                                </Box>

                                                <Box sx={{ position: 'relative' }} mt={3}>
                                                    <Button
                                                        type="button"
                                                        variant="secondary"
                                                        onClick={() => this.setState({ showTagsDropdown: true })}
                                                    >
                                                        <FiPlus /> Add Tag
                                                    </Button>
                                                    {this.state.showTagsDropdown && (
                                                        <NiceDropdown
                                                            showSearch
                                                            onClose={() => {
                                                                this.setState({ showTagsDropdown: false });
                                                            }}
                                                            items={this.state.tags.map(tag => {
                                                                if (!this.state.study.tags.find(t => t.id == tag.id)) {
                                                                    return {
                                                                        title: tag.title,
                                                                        id: tag.id
                                                                    };
                                                                } else {
                                                                    return;
                                                                }
                                                            })}
                                                            onChange={tag_id => {
                                                                this.addTagToStudy(tag_id);
                                                            }}
                                                            renderBottomStickyButton={
                                                                <Button
                                                                    type="button"
                                                                    variant="secondary"
                                                                    onClick={() => {
                                                                        const tag_title = prompt(
                                                                            'What is the tag title?'
                                                                        );
                                                                        if (tag_title) {
                                                                            this.onCreateStudyTag(tag_title);
                                                                        }
                                                                    }}
                                                                >
                                                                    <FiPlus /> Create Study Tag
                                                                </Button>
                                                            }
                                                        />
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box
                                            id="other-actions"
                                            ref={this.otherActionsRef}
                                            className="form-wrapper2 form-wrapper2-border"
                                        >
                                            <Flex className="settings-header">
                                                <Box>
                                                    <H2>Other Actions</H2>
                                                </Box>
                                            </Flex>

                                            {/*<Box mb={3}>
                                                <Box className="text-secondary">
                                                    Find duplicate screener responses by:
                                                </Box>
                                                <a href={`${config.API_URL}/studies/${this.props.match.params.id}/study-people-duplicates?account_id=${this.props.auth.account.id}`} target="_blank">
                                                    <Button type="button" variant="secondary-gray">
                                                        Phone Number
                                                    </Button>
                                                </a>
                                                <a href={`${config.API_URL}/studies/${this.props.match.params.id}/screeners-duplicate-ips?account_id=${this.props.auth.account.id}`} target="_blank">
                                                    <Button type="button" variant="secondary-gray" ml={3}>
                                                        IP
                                                    </Button>
                                                </a>
                                            </Box>*/}

                                            <Flex className="form-data">
                                                <Box mr={3}>
                                                    <Button
                                                        type="button"
                                                        variant="secondary-gray"
                                                        onClick={this.duplicateStudy}
                                                    >
                                                        Duplicate Study
                                                    </Button>
                                                </Box>

                                                <Box>
                                                    <Button
                                                        type="button"
                                                        variant="danger"
                                                        onClick={this.deleteStudyConfirm}
                                                    >
                                                        Delete Study
                                                    </Button>
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </form>
                                </Box>
                                <Box flex="0 0 auto">
                                    <SideNav
                                        sections={[
                                            {
                                                id: 'study-information',
                                                ref: this.studyInformationRef,
                                                label: 'Study Information',
                                                display: true
                                            },
                                            {
                                                id: 'custom-attributes',
                                                ref: this.customAttributesRef,
                                                label: 'Custom Attributes',
                                                display: true
                                            },
                                            {
                                                id: 'study-members-section',
                                                ref: this.studyMembersSectionRef,
                                                label: 'Study Members',
                                                display: true
                                            },
                                            {
                                                id: 'tags',
                                                ref: this.tagsRef,
                                                label: 'Tags',
                                                display: true
                                            },
                                            {
                                                id: 'other-actions',
                                                ref: this.otherActionsRef,
                                                label: 'Other Actions',
                                                display: true
                                            }
                                        ]}
                                    />
                                </Box>
                            </Flex>
                        )}
                        <NiceModal
                            isOpen={this.state.showModalCspaEdit}
                            shouldCloseOnOverlayClick
                            onRequestClose={() => this.setState({ showModalCspaEdit: false })}
                            title="Edit Custom Attribute"
                        >
                            <CustomAttributeEdit
                                cspa={this.state.cspaEditing}
                                onClose={() => this.setState({ showModalCspaEdit: false })}
                                onSave={cspa => this.saveCustomAttribute(cspa)}
                            />
                        </NiceModal>
                        <StudyUpdatedPopup
                            studyId={this.state.study.id}
                            isOpen={this.state.isSendUpdatesPopupOpen}
                            onClose={() => this.setState({ isSendUpdatesPopupOpen: false })}
                        />
                    </AppPageWrapperSectionBody>
                </AppPageWrapper>
            </Flex>
        );
    }
}

export default withToastManager(StudySettingsPage);
