import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import H1 from 'components/H1';
import { Flex, Box, Button } from 'rebass';
import { Input, Label, Textarea } from '@rebass/forms';
import people_service from 'services/people';
import services from 'services/services';
import helpers from 'utils/helpers';
import config from 'config';
import moment from 'moment-timezone';
import { FORM_TYPES } from 'utils/constants';
import { FiInfo, FiAlertCircle } from 'react-icons/fi';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import SettingsPageSubNav from 'containers/SettingsPage/SettingsPageSubNav';
import ItemWrapper from 'components/ItemWrapper';
import NiceModal from 'components/NiceModal';
import { ActionButton } from 'components/Button/ActionButton';
import CreatePanel from 'components/Participant/CreatePanel';
import PanelSelect from 'components/PanelSelect';
import NiceSelect from 'components/NiceSelect';
import NotificationCard from 'components/NotificationCard';
import Badge from 'components/Badge';

let saveDelay;

function PanelSignupFormsList(props) {
    const [panelForms, setPanelForms] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [panels, setPanels] = useState(null);
    const [panelIdSelected, setPanelIdSelected] = useState(null);
    const [formIdSelected, setFormIdSelected] = useState(0);
    const [showModalCreatePanel, setShowModalCreatePanel] = useState(false);
    const { addToast } = useToasts();
    const copyFormItems = [{ id: 0, title: 'Do not copy' }, ...panelForms];
    const copyFormItemsMap = copyFormItems.reduce((acc, item) => {
        acc[item.id] = item.title;
        return acc;
    }, {});

    useEffect(() => {
        xhrGetPanelSignupForms();
        xhrGetPanels();
    }, []);

    function xhrGetPanelSignupForms() {
        people_service
            .getPanelSignupForms()
            .then(st => {
                setPanelForms(st);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function xhrGetPanels(selectId) {
        people_service
            .getPanels()
            .then(panels_response => {
                if (panels_response.length) {
                    setPanels(panels_response);
                    setPanelIdSelected(selectId ? selectId : panels_response[0].id);
                }
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function onCreatePanel(panel) {
        addToast('Successfully created a new panel', {
            appearance: 'success',
            autoDismiss: true
        });
        xhrGetPanels(panel.id);
    }

    function saveFormXHR(e) {
        e.preventDefault();

        setIsSubmitting(true);

        const title = document.getElementById('new-form-title').value;
        const description = document.getElementById('new-form-description').value;

        const form_type_elements = document.getElementsByName('new-form-type');
        let form_type = null;
        for (let i = 0; i < form_type_elements.length; i++) {
            if (form_type_elements[i].checked) form_type = form_type_elements[i].value;
        }

        people_service
            .createPanelSignupForm(title, description, panelIdSelected, formIdSelected, form_type)
            .then(form_xhr => {
                addToast('Created', {
                    appearance: 'success',
                    autoDismiss: true
                });

                props.history.push(`/settings/forms/${form_xhr.id}`);
            })
            .catch(error => {
                setIsSubmitting(false);
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    return (
        <Flex style={{ width: '100%' }}>
            <AppPageWrapper>
                <Helmet>
                    <title>Forms</title>
                </Helmet>

                <Flex>
                    <SettingsPageSubNav />
                </Flex>

                <AppPageWrapperSectionHeader className="experience">
                    <Box>
                        <H1>Forms</H1>

                        <Box className="experience-description">
                            <Box mb={2}>
                                Central page to manage Signup, Consent, and Screener forms.{' '}
                                <a
                                    href="#"
                                    data-beacon-article="62fb00536d67192dc61bb069"
                                    className="help-question"
                                    style={{ display: 'inline-block', margin: 0 }}
                                >
                                    ?
                                </a>
                                <br />
                                <a
                                    href={`${config.getAccountPrefixUrl(props.auth.account)}/panelist/${
                                        props.auth.account.uuid
                                    }/signup`}
                                    target="_blank"
                                >
                                    Panel Sign Up
                                </a>{' '}
                                and&nbsp;
                                <a
                                    href={`${config.getAccountPrefixUrl(props.auth.account)}/panelist/${
                                        props.auth.account.uuid
                                    }/login`}
                                    target="_blank"
                                >
                                    Panel Log In
                                </a>{' '}
                                links.
                            </Box>
                        </Box>
                    </Box>

                    <Box mt={2}>
                        <ActionButton title="New Form" action={() => setShowModal(true)} keyLabel="N" mr={0} />
                    </Box>
                </AppPageWrapperSectionHeader>
                <AppPageWrapperSectionBody>
                    <Box mx={0} my={3} width="100%">
                        <ItemWrapper className="header-row">
                            <Box width={4 / 8}>Title & Description</Box>
                            <Box width={1 / 8}>Form Type</Box>
                            <Box width={1 / 8}>Panels</Box>
                            {/*<Box width={1 / 8}>Show In Profile</Box>*/}
                            <Box width={1 / 8}>Created</Box>
                        </ItemWrapper>
                        {!isLoading && panelForms && panelForms.length == 0 && (
                            <Box mt={4} mx={3}>
                                No results, create your first Signup Form!
                            </Box>
                        )}
                        {panelForms.map(pf => {
                            return (
                                <Link to={`/settings/forms/${pf.id}`}>
                                    <ItemWrapper key={pf.id}>
                                        <Box width={4 / 8}>
                                            <Link to={`/settings/forms/${pf.id}`} className="muted bold">
                                                {pf.title ? pf.title : '(no title)'}
                                            </Link>
                                            <Box
                                                style={{ width: '100%' }}
                                                mt={1}
                                                className="ellipsis text-secondary fs-12"
                                            >
                                                {pf.description}
                                            </Box>
                                        </Box>
                                        <Box width={1 / 8} className="text-primary">
                                            {helpers.getFormTypeTitle(pf.form_type)}
                                        </Box>
                                        {/*<Box width={1 / 8} className="text-primary">
                                            {pf.show_in_profile ? 'Yes' : 'No'}
                                        </Box>*/}

                                        <Box width={1 / 8} className="text-primary">
                                            {pf.panels.length ? (
                                                <>
                                                    {pf.panels.length} panel{pf.panels.length == 1 ? '' : 's'}
                                                </>
                                            ) : (
                                                '0 panels'
                                            )}
                                        </Box>
                                        <Box width={1 / 8} className="text-primary">
                                            {moment.utc(pf.created_at).format('MMMM D, YYYY')}
                                        </Box>
                                    </ItemWrapper>
                                </Link>
                            );
                        })}
                    </Box>

                    <NiceModal
                        isOpen={showModal}
                        shouldCloseOnOverlayClick
                        onRequestClose={() => setShowModal(false)}
                        style={{ content: { width: '510px' } }}
                        title={`Create a new form`}
                        overflow="visible"
                    >
                        <form onSubmit={saveFormXHR}>
                            {/*panels !== null && panelIdSelected !== null && (
                                <Box mb={3}>
                                    <Label>Associated Panel</Label>
                                    <PanelSelect
                                        width="100%"
                                        items={panels}
                                        value={panelIdSelected}
                                        onChange={panel_id => setPanelIdSelected(panel_id)}
                                        onCreatePanel={() => setShowModalCreatePanel(true)}
                                    />
                                </Box>
                            )*/}
                            <Label htmlFor="type">Form Type</Label>

                            {FORM_TYPES.map(ft => {
                                return (
                                    <Box>
                                        <label>
                                            <input type="radio" name="new-form-type" value={ft.id} /> {ft.title}
                                        </label>
                                    </Box>
                                );
                            })}

                            <Label htmlFor="title" mt={3}>
                                Title
                            </Label>

                            <Input
                                id="new-form-title"
                                type="text"
                                name="title"
                                placeholder="Title"
                                autoComplete="off"
                                required
                                autoFocus
                            />

                            <Label mt={3} htmlFor="description">
                                Description
                            </Label>

                            <Textarea
                                id="new-form-description"
                                type="text"
                                name="description"
                                placeholder="Description"
                            />

                            {panelForms !== null && (
                                <Box mt={3} mb={3}>
                                    <Label>Copy Form</Label>
                                    <NiceSelect
                                        showSearch
                                        mimicSelectStyle
                                        defaultValue={0}
                                        items={copyFormItems}
                                        mapValueToLabel={copyFormItemsMap}
                                        onChange={formId => setFormIdSelected(formId)}
                                    />
                                </Box>
                            )}

                            <Box className="modal-actions">
                                <Button
                                    type="button"
                                    variant="secondary-gray"
                                    mr={3}
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    mr={0}
                                    type="submit"
                                    variant="primary"
                                    className="modal-primary"
                                    disabled={isSubmitting}
                                >
                                    Create form
                                </Button>
                            </Box>
                        </form>
                        <NiceModal
                            isOpen={showModalCreatePanel}
                            shouldCloseOnOverlayClick
                            onRequestClose={() => setShowModalCreatePanel(false)}
                            title={`Create new panel`}
                        >
                            <CreatePanel onCreate={onCreatePanel} onClose={() => setShowModalCreatePanel(false)} />
                        </NiceModal>
                    </NiceModal>
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
        </Flex>
    );
}

export default PanelSignupFormsList;
