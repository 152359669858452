import React from 'react';
import { Box, Button } from 'rebass';
import { Label, Input } from '@rebass/forms';
import xhr from 'xhr.js';
import { withToastManager } from 'react-toast-notifications';
import H1 from 'components/H1';
import helpers from 'utils/helpers';
import services from 'services/services';
import service_studies from 'services/studies';
import { Link } from 'react-router-dom';
import { CURRENCY_SYMBOL, INCENTIVE_PAYMENT_METHOD, INCENTIVE_PAYMENT_METHOD_LABEL } from 'utils/constants';

/**
 * @deprecated
 */
class ParticipantPay extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            study_person: this.props.study_person,
            incentive: {
                amount: this.props.study.incentive ? this.props.study.incentive : null,
                personal_message: ''
            },
            study: this.props.study,
            processingIncentive: false
        };

        //console.log('CHILD', props);

        this.onFormChange = this.onFormChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        // this.deleteParticipantConfirm = this.deleteParticipantConfirm.bind(this);
    }

    /* deleteParticipantConfirm() {
    var conf = confirm("Are you sure you want to delete this participant?");
    if (conf == true) {
      this.parentCallback('delete')
    }
  } */

    onFormSubmit(e) {
        e.preventDefault();

        // eslint-disable-next-line
        if (confirm('Are you sure?')) {
            this.payParticipantXHR();
        }
    }

    onFormChange(e) {
        if (!e.target.name) {
            return;
        }
        const incentive = { ...this.state.incentive };
        incentive[e.target.name] = e.target.value;
        // console.log(e.target.name);
        this.setState({ incentive });
    }

    payParticipantXHR() {
        const { toastManager } = this.props;

        this.setState({ processingIncentive: true });

        let people_ids = [this.state.study_person.person_id];
        service_studies
            .bulkPayAccept(this.state.study.id, people_ids, this.props.paymentMethod, this.state.incentive.amount)
            .then(() => {
                toastManager.add('Payment process initiated', {
                    appearance: 'success',
                    autoDismiss: true
                });

                this.parentCallback('done');

                this.setState({ processingIncentive: false });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: false
                });
                this.setState({ processingIncentive: false });
            });
    }

    parentCallback(action, study_person) {
        if (this.props.parentCallback) {
            this.props.parentCallback(action, study_person);
        }
    }

    render() {
        return (
            <Box fontSize={1}>
                <form style={{ padding: '24px' }} onSubmit={this.onFormSubmit}>
                    <H1>
                        {this.props.paymentMethod == INCENTIVE_PAYMENT_METHOD.TREMENDOUS
                            ? INCENTIVE_PAYMENT_METHOD_LABEL.TREMENDOUS_MODAL_TITLE
                            : INCENTIVE_PAYMENT_METHOD_LABEL.FIRST_PARTY_MODAL_TITLE}
                    </H1>
                    <Label htmlFor="title">Name</Label>{' '}
                    {helpers.personGetFullName(this.state.study_person.person).trim()
                        ? helpers.personGetFullName(this.state.study_person.person)
                        : 'Name missing'}
                    <Label htmlFor="title" mt={3}>
                        E-mail
                    </Label>{' '}
                    {helpers.personGetCustomValue(this.state.study_person.person, 'Email')}
                    {this.props.paymentMethod == INCENTIVE_PAYMENT_METHOD.TREMENDOUS && (
                        <>
                            <Label htmlFor="title" mt={3}>
                                Tremendous campaign
                            </Label>
                            <Box>
                                {this.state.study.tremendous_campaign_id ? (
                                    this.state.study.tremendous_campaign_id
                                ) : (
                                    <>
                                        <span className="error">Missing Campaign Id.</span>
                                        <br />
                                        Update in{' '}
                                        <Link to={`/studies/${this.state.study.id}/settings`}>Study &gt; Settings</Link>
                                    </>
                                )}
                            </Box>
                        </>
                    )}
                    <Label htmlFor="title" mt={3}>
                        Amount ({CURRENCY_SYMBOL[this.state.study.incentive_currency]})
                    </Label>
                    <Input
                        type="number"
                        name="amount"
                        placeholder="e.g. 50"
                        style={{ width: '160px' }}
                        value={this.state.incentive.amount}
                        onChange={this.onFormChange}
                        required
                    />
                    {/* <Label htmlFor='title'>Personal Message</Label>
            <Textarea
              type='text'
              name='personal_message'
              placeholder='Personal message'
              value={this.state.incentive.personal_message}
              onChange={this.onFormChange}
            ></Textarea> 
                    <div style={{ fontSize: '12px', margin: '16px 0', color: '#606E85' }}>
                        This participant will receive an email with a link to a{' '}
                        <a href="https://tremendous.com" target="_blank">
                            Tremendous
                        </a>{' '}
                        Gift Card. I acknowledge this payment will be billed to the Credit Card on this Account.
                    </div>*/}
                    {/*helpers.getActiveAccountSubscriptions(this.props.auth.account).length == 0 && (
                        <Box mb={3} className="box-error">
                            <div>Please add a Subscription to your account in order to pay this incentive.</div>
                            <Button
                                type="button"
                                variant="primary"
                                mt={3}
                                onClick={() => {
                                    services.openPayment();
                                }}
                            >
                                Add a Subscription
                            </Button>
                        </Box>
                    )*/}
                    <Box style={{ textAlign: 'right' }}>
                        {/* <Button type="button" variant="danger" onClick={this.deleteParticipantConfirm} style={{float: 'left'}}>Delete participant</Button> */}
                        <Button type="button" variant="tertiary" mr={2} onClick={() => this.parentCallback('close')}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            mr={0}
                            //disabled={!!(!this.props.auth.account.stripe_id || this.state.processingIncentive)}
                            disabled={
                                (this.props.paymentMethod == INCENTIVE_PAYMENT_METHOD.TREMENDOUS &&
                                    !this.state.study.tremendous_campaign_id) ||
                                this.state.processingIncentive
                            }
                        >
                            {this.state.processingIncentive ? 'Processing...' : 'Pay Incentive'}
                        </Button>
                    </Box>
                </form>
            </Box>
        );
    }
}

export default withToastManager(ParticipantPay);
