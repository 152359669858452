import React, { useRef } from 'react';
import { Flex, Box } from 'rebass';
import { Input, Switch } from '@rebass/forms';
import helpers from 'utils/helpers';
import ReactQuill from 'react-quill';
import { QUILL_MODULES, EMAIL_TEMPLATE_TYPE } from '../utils/constants';

import H2 from 'components/H2';
import SideNav from 'components/SideNav';
import { MergeTagsDropdown } from 'components/MergeTagsDropdown';

function ScreenerTranslationComponent({ study, ...props }) {
    const confirmationPageRef = useRef();
    const disqualificationPageRef = useRef();
    const buttonsAndValidationRef = useRef();

    const isConfirmationRedirect = !!props.screener_translation.confirmation_redirect;
    const isDisqualificationRedirect = !!props.screener_translation.disqualification_redirect;

    return (
        <Flex flex="1 1 0%" justifyContent="space-between">
            <Box flex="0 0 auto">
                <Box id="confirmation-page" ref={confirmationPageRef} className="form-wrapper2 form-wrapper2-first">
                    <Box className="settings-header">
                        <H2>Confirmation Page</H2>
                        <Box className="form-section-information">
                            Configure what happens when respondent submits a survey response, and does not disqualify.
                        </Box>
                    </Box>
                    <Box className="form-data">
                        <Box className="form-label">Confirmation Page Text</Box>
                        <ReactQuill
                            theme="snow"
                            className="quill-render"
                            modules={QUILL_MODULES}
                            value={props.screener_translation.confirmation_page_text}
                            //placeholder="Thank you!"
                            onChange={html => {
                                props.handleTranslationChange({
                                    target: {
                                        name: 'confirmation_page_text',
                                        value: html
                                    }
                                });
                            }}
                        />
                        <Box className="form-label form-row-margin">Redirect after Confirmation</Box>
                        <Flex minHeight="32px" mb="12px">
                            <Box>
                                <Switch
                                    id="opt-out-opt-in"
                                    className="switch-small"
                                    onClick={e => {
                                        props.handleTranslationChange({
                                            target: {
                                                name: 'confirmation_redirect',
                                                value: props.screener_translation.confirmation_redirect ? false : true
                                            }
                                        });
                                    }}
                                    checked={props.screener_translation.confirmation_redirect}
                                    mr={3}
                                    style={{ marginTop: '8px' }}
                                />
                            </Box>
                            <Box flexGrow={1}>
                                {isConfirmationRedirect && (
                                    <>
                                        <Input
                                            type="text"
                                            name="confirmation_redirect_url"
                                            value={props.screener_translation.confirmation_redirect_url}
                                            placeholder="https://link-to-page.com"
                                            onChange={e => props.handleTranslationChange(e)}
                                            className={
                                                helpers.isValidUrl(props.screener_translation.confirmation_redirect_url)
                                                    ? ''
                                                    : 'error'
                                            }
                                            style={{ marginBottom: '10px' }}
                                        />
                                        <MergeTagsDropdown study={study} templateType={EMAIL_TEMPLATE_TYPE.PLAIN} />
                                    </>
                                )}
                            </Box>
                        </Flex>
                    </Box>
                </Box>
                <Box
                    id="disqualification-page"
                    ref={disqualificationPageRef}
                    className="form-wrapper2 form-wrapper2-border"
                >
                    <Box className="settings-header">
                        <H2>Disqualification Page</H2>
                        <Box className="form-section-information">
                            Configure what happens when a respondent submits a survey response, and is disqualified.
                        </Box>
                    </Box>
                    <Box className="form-data">
                        <Box className="form-label">Disqualification Page Text</Box>
                        <ReactQuill
                            theme="snow"
                            className="quill-render"
                            modules={QUILL_MODULES}
                            value={props.screener_translation.disqualification_page_text}
                            //placeholder="Unfortunately you have been disqualified."
                            onChange={html => {
                                props.handleTranslationChange({
                                    target: {
                                        name: 'disqualification_page_text',
                                        value: html
                                    }
                                });
                            }}
                        />
                        <Box className="form-label form-row-margin">Redirect after Disqualification</Box>
                        <Flex minHeight="32px" mb="12px">
                            <Box>
                                <Switch
                                    id="opt-out-opt-in"
                                    className="switch-small"
                                    onClick={e => {
                                        props.handleTranslationChange({
                                            target: {
                                                name: 'disqualification_redirect',
                                                value: props.screener_translation.disqualification_redirect
                                                    ? false
                                                    : true
                                            }
                                        });
                                    }}
                                    checked={props.screener_translation.disqualification_redirect}
                                    mr={3}
                                    style={{ marginTop: '8px' }}
                                />
                            </Box>
                            <Box flexGrow={1}>
                                {isDisqualificationRedirect && (
                                    <>
                                        <Input
                                            type="text"
                                            name="disqualification_redirect_url"
                                            value={props.screener_translation.disqualification_redirect_url}
                                            placeholder="https://link-to-page.com"
                                            onChange={e => props.handleTranslationChange(e)}
                                            className={
                                                helpers.isValidUrl(
                                                    props.screener_translation.disqualification_redirect_url
                                                )
                                                    ? ''
                                                    : 'error'
                                            }
                                            style={{ marginBottom: '10px' }}
                                        />
                                        <MergeTagsDropdown study={study} templateType={EMAIL_TEMPLATE_TYPE.PLAIN} />
                                    </>
                                )}
                            </Box>
                        </Flex>
                    </Box>
                </Box>
                <Box
                    id="buttons-and-validation"
                    ref={buttonsAndValidationRef}
                    className="form-wrapper2 form-wrapper2-border"
                >
                    <Box className="settings-header">
                        <H2>Buttons and Validation</H2>
                        <Box className="form-section-information">
                            Use this for translation or changing button text.
                        </Box>
                    </Box>
                    <Box className="form-data">
                        <Box className="form-label">Next Button Text</Box>
                        <Input
                            name="next_button_text"
                            value={props.screener_translation.next_button_text}
                            placeholder="Next"
                            onChange={e => props.handleTranslationChange(e)}
                        />
                        <Box className="form-label form-row-margin">Submit Button Text</Box>
                        <Input
                            name="submit_button_text"
                            value={props.screener_translation.submit_button_text}
                            placeholder="Submit"
                            onChange={e => props.handleTranslationChange(e)}
                        />
                        <Box className="form-label form-row-margin">Cancel Button Text</Box>
                        <Input
                            name="cancel_button_text"
                            value={props.screener_translation.cancel_button_text}
                            placeholder="Cancel"
                            onChange={e => props.handleTranslationChange(e)}
                        />
                        <Box className="form-label form-row-margin">Validation: Is required Text</Box>
                        <Input
                            name="is_required_text"
                            value={props.screener_translation.is_required_text}
                            placeholder="is required"
                            onChange={e => props.handleTranslationChange(e)}
                        />
                        <Box className="form-label form-row-margin">Validation: Dismiss Text</Box>
                        <Input
                            name="dismiss_text"
                            value={props.screener_translation.dismiss_text}
                            placeholder="Dismiss"
                            onChange={e => props.handleTranslationChange(e)}
                        />
                    </Box>
                </Box>
            </Box>
            <Box flex="0 0 auto">
                <SideNav
                    sections={[
                        {
                            id: 'confirmation-page',
                            ref: confirmationPageRef,
                            label: 'Confirmation Page',
                            display: true
                        },
                        {
                            id: 'disqualification-page',
                            ref: disqualificationPageRef,
                            label: 'Disqualification Page',
                            display: true
                        },
                        {
                            id: 'buttons-and-validation',
                            ref: buttonsAndValidationRef,
                            label: 'Buttons and Validation',
                            display: true
                        }
                    ]}
                />
            </Box>
        </Flex>
    );
}

export default ScreenerTranslationComponent;
