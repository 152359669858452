import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import LoadingIndicator from 'components/LoadingIndicator';
const AppWrapper = styled.div`
    display: flex;
    position: relative;
    z-index: 0;
    width: 100vw;
`;

export default function(ComposedComponent, auth) {
    // eslint-disable-next-line react/prefer-stateless-function
    class NoAuth extends React.Component {
        // Otherwise render the original component
        render() {
            // these pages should be viewable, whether the user is authorized or not
            const ignoreRedirect = [
                '/scheduling/schedule',
                '/scheduling/reschedule',
                '/scheduling/session',
                '/screen/:hash',
                '/study-screen/:screen_uuid',
                '/s/:screen_uuid',
                '/panel-profile/:account_hash/:panel_form_id',
                '/p/:panel_signup_custom_slug',
                '/support',
                '/privacy',
                '/zoom-support',
                '/docs',
                '/docs/:section/:page',
                '/grids/:study_uuid',
                '/panelist/:account_uuid/signup',
                '/panelist/:account_uuid/login',
                '/panelist/:account_uuid/forgot-password',
                '/new-password/:token',
                '/panelist/:account_uuid/profile',
                '/panelist/:account_uuid/profile/:panel_form_id',
                '/emails/:email_campaign_uuid',
                '/consent-management',
                '/consent-management/:account_uuid/:person_uuid',
                '/consent-management/:account_uuid/:person_uuid/:cm_action'
            ];

            if (!auth.isFetchingAuth && auth.isAuthenticated && !ignoreRedirect.includes(this.props.match.path)) {
                return <Redirect to="/studies" />;
            }

            return (
                <>
                    <Helmet>
                        <meta
                            name="viewport"
                            content="width=device-width, height=device-height, initial-scale=1, maximum-scale=5, viewport-fit=cover"
                        />
                    </Helmet>
                    {auth.isFetchingAuth ? (
                        <AppWrapper>
                            <div style={{ width: '100%' }}>
                                <br />
                                <LoadingWrapper>
                                    <LoadingIndicator />
                                </LoadingWrapper>
                            </div>
                        </AppWrapper>
                    ) : (
                        <AppWrapper>
                            <ComposedComponent {...this.props} auth={auth} />
                        </AppWrapper>
                    )}
                </>
            );
        }
    }

    return NoAuth;
}
