import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    html,
    body {
        height: 100%;
        width: 100%;
        line-height: 1.6;
        background-color: #ffffff !important;
        overscroll-behavior-x: none;
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        scroll-behavior: smooth;
    }

    body, input, select, textarea {
        font-family: 'Inter', sans-serif;
        -webkit-font-smoothing:antialiased;
        -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
    }

    body.fontLoaded {
        font-family: 'Inter', sans-serif;
    }

    #app {
        min-height: 100%;
        min-width: 100%;
        color: #666;
    }

    p {
        margin: 8px 0 12px 0;
    }


    a, .link {
        cursor: pointer;
        text-decoration: none;
        color: #0A73EB;
        transition: color 150ms;
    }

    a svg, .svg-link {
        display: inline-block;
        margin: -3px 0 0 4px;
        stroke-width: 2px;
        font-size: 16px;
    }

    a:hover, .link:hover {
        color: #0A73EB;
    }

    a.muted, .link.muted {
        color: #001233;
    }
    a.muted:hover, .link.muted:hover {
        color: #0A73EB;
        //text-decoration: underline;
    }
    a.muted-light, .link.muted-light {
        color: #6B7A94;
    }
    a.muted-light:hover, .link.muted-light:hover {
        color: #0A73EB;
        //text-decoration: underline;
    }

    a.quiet, .link.quiet {
        color: #606E85 !important;
    }
    a.quiet:hover, .link.quiet:hover {
        color: #555 !important;
    }

    a.breadcrumb-link {
        color: #929DB0;
        font-size: 12px;
        //font-weight: 300;
        max-width: 240px;
        display: inline-block;
    }
    a.breadcrumb-link:hover {
        text-decoration: underline;
    }

    .breadcrumb-back {
        display: block;
        font-size: 12px;
        font-style: normal;
        font-weight: 500; 
        color: #606E85;
        margin-bottom: 4px;
    }
    .breadcrumb-back:hover {
        color: #0A73EB;
    }
    .breadcrumb-back:focus, .breadcrumb-back:active {
        color: #0A73EB;
    }
    .breadcrumb-back svg {
        font-size: 13px;
        margin: 3px 4px 0 0;
        vertical-align: top;
    }

    .sticky-top-banner{
        z-index: 100000;
        position: sticky;
        top: 0;
        text-align: center;
    }
    .experience-like-h1 {
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
    }
    .tertiary-nav, .subnav-border {
        width: 100%;
        border-bottom: 1px solid #e9ecef;
    }
    .tertiary-nav.tertiary-nav-padded {
        margin-top: 12px;
    }
    .fs-18 { font-size: 18px !important; }
    .fs-16 { font-size: 16px !important; }
    .fs-14 { font-size: 14px !important; }
    .fs-13 { font-size: 13px !important; }
    .fs-12 { font-size: 12px !important; }
    .lh-20 { line-height: 20px; }
    input[type="color"]{
        height: 24px;
        width: 24px;
        border: none;
        padding: 0;
        border-radius: 8px;
        overflow: hidden;
        margin: 8px;
        cursor: pointer;
    }
    input[type=checkbox] {
        accent-color: #0A73EB
    }

    .input-search-icon:before {
        content: url("/search.svg");
        position: absolute;
        right: 12px;
        top: 6px;
        pointer-events: none;
    }

    .overflow-hidden {
        overflow: hidden;
    }

    // background
    .bg-main {
        background-color: #E5F7FE;
    }
    .bg-success {
        background-color: #D6F3DD;
    }
    .bg-warn {
        background-color: #FFEECC;
    }
    .bg-danger {
        background-color: #F3D9D9;
    }
    .bg-info {
        background-color: #D1E9FF;
    }
    .bg-pure {
        background-color: #F6F6F6;
    }
    .bg-main-light {
        background-color: rgb(234, 222, 252, 0.3);
    }
    .bg-success-light {
        background-color: rgba(214, 243, 221, 0.30);
    }
    .bg-warn-light {
        background-color: rgba(255, 238, 204, 0.30);
    }
    .bg-danger-light {
        background-color: rgba(243, 217, 217, 0.30);
    }
    .bg-info-light {
        background-color: rgba(209, 233, 255, 0.30);
    }
    .bg-pure-light {
        background-color: #F6F6F6;
    }
    // border
    .border-main, .border.border-main {
        border-color: #0A73EB;
    }
    .border-success, .border.border-success {
        border-color: #10B736;
    }
    .border-warn, .border.border-warn {
        border-color: #FF8A00;
    }
    .border-danger, .border.border-danger {
        border-color: #D42220;
    }
    .border-info, .border.border-info {
        border-color: #0035C9;
    }
    // text color
    .text-main {
        color: #0A73EB;
    }
    .text-success {
        color: #10B736;
    }
    .text-warn {
        color: #FF8A00;
    }
    .text-danger {
        color: #8A0505;
    }
    .text-info {
        color: #0035C9;
    }
    .text-pure.text-pure {
        color: #001233;
    }
    // layout
    .justify-center {
        justify-content: center;
    }
    .justify-between {
        justify-content: space-between;
    }
    .items-center {
        align-items: center;
    }
    .items-start {
        align-items: start;
    }
    .items-end {
        align-items: end;
    }
    .flex-col {
        flex-direction: column;
    }
    .flex-row {
        flex-direction: row;
    }
    // shadow
    .shadow-solid-main {
        box-shadow: 0 0 0 1px #0A73EB;
    }
    .shadow-solid-success {
        box-shadow: 0 0 0 1px #10B736;
    }
    .shadow-solid-warn {
        box-shadow: 0 0 0 1px #FF8A00;
    }
    .shadow-solid-danger {
        box-shadow: 0 0 0 1px #D42220;
    }
    .shadow-solid-info {
        box-shadow: 0 0 0 1px #0035C9;
    }
    .shadow-solid-danger--important {
        box-shadow: 0 0 0 1px #D42220 !important;
    }

    .mt-16 {
        margin-top: 16px !important;
    }
    .mb8px {
        margin-bottom: 8px;
    }

    .cdx-text-color {
        color: inherit;
    }

    .float-left {
        float: left;
    }
    .float-right {
        float: right;
    }
    .clearfix::after {
        content: "";
        clear: both;
        display: table;
    }
    .not-clickable {
        cursor: default !important;
    }
    .color-purple {
        color: #0a73eb;
    }
    .rounded-small {
        border-radius: 2px;
    }
    .rounded {
        border-radius: 4px !important;
    }
    .rounded-full {
        border-radius: 10000px;
    }
    .rounded-8 {
        border-radius: 8px !important;
    }
    .rounded-12 {
        border-radius: 12px !important;
    }
    .rounded-16 {
        border-radius: 16px !important;
    }
    .circle {
        border-radius: 50% !important;
    }
    .border {
        border-radius: 4px;
        border: 1px solid #ece9e9;
    }
    .border.border-hover, .border.border-clickable {
        cursor:pointer
    }
    .border.border-hover:hover, .border.border-clickable:hover {
        border: 1px solid #0A73EB !important;
        background-color: rgba(10, 115, 235, 0.02);
    }

    .border-top-gray {
        border-top: 1px solid #E7E8F1;
    }
    .border-bottom-gray {
        border-bottom: 1px solid #E7E8F1;
    }

    .flex-vcenter {
        align-items: center;
        display: flex;
    }

    .app-blocked-overlay {
        position: fixed;
        top: 114px;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,0.2);
        z-index: 100;
    }
    .react-tel-input .flag-dropdown {
        border: 0 !important;
    }
    .pointer {
        cursor: pointer !important;
    }
    .title {
        color: #000;
        font-size: 18px;
    }

    .new-feature-tag {
        max-height: 19px;
        background: #FFC300;
        color: #fff;
        border-radius: 4px;
        padding: 2px 4px;
        font-size: 10px;
        line-height: 15px;
        display: inline-block;
        vertical-align: top;
        font-weight: 500;
    }

    .table-main {
        border-spacing: 0;
        border-collapse: separate;
        min-width: 100%;
    }

    .table-main thead {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 5;
    }
    .table-main thead th {
        color: #929DB0;
        font-size: 12px;
        border-bottom: 1px solid #E7E8F1;
        padding: 12px 16px;
        text-align: left;
        white-space: nowrap;
        font-weight: 400;
        background: #fff;
        //top: 0;
        //box-shadow: rgba(36,18,77,0.05) inset -1px 0px 0px 0px;
    }
    .table-main thead th .th-clickable {
        cursor: pointer;
    }
    .table-main thead th .th-clickable:hover {
        color: #001233;
    }
    .table-main thead th:hover {
        //background: #FBFCFF;
    }
    .table-main thead th {
        position: relative;
        overflow: visible;
    }
    .th-inner-content {
        min-width: 40px !important;
    }
    .resizer {
        /* Displayed at the right side of column */
        position: absolute;
        top: 0;
        right: -12px;
        width: 24px;
        cursor: col-resize;
        user-select: none;
        z-index: 1;
    }
    .resizer-vl {
        margin-left: 10px;
        width: 2px;
        height: 100%;
    }
    .resizer:hover .resizer-vl {
        background-color: #0A73EB;
    }
    .resizing .resizer-vl {
        background-color: #0A73EB !important;
    }

    .table-main tbody {
        margin-top: 5px;
    }
    .table-main tbody td {
        background: #fff;
        border-bottom: 1px solid #eee;
        word-break: normal;

        padding: 12px 16px;
        vertical-align: middle;

        z-index: 1;
        position: relative;
        //box-shadow: rgba(36,18,77,0.05) inset -1px 0px 0px 0px;
        color: #001233;
    }
    .table-main tbody td .td-child-max-width {
        max-width: 400px;
    }

    .table-main tbody tr td .study-person-more-hover {
        visibility: hidden;
    }
    .table-main tbody tr td .study-person-more-hover-parent {
        position: absolute;
        right: 0;
        height: 24px;
        text-align: right;
        top: 50%;
        transform: translateY(-50%);
    }
    .table-main tbody tr:hover td .study-person-more-hover-parent {
        background: #FBFCFF;
    }
    .table-main tbody tr:hover td .study-person-more-hover {
        visibility: visible;
    }
    .table-main tbody tr input[type=checkbox] {
        display: none;
    }
    .table-main tbody tr:hover input[type=checkbox], .table-main tbody tr input[type=checkbox]:checked {
        display: inline;
    }
    .table-main tbody tr:hover td {
        background-color: #FBFCFF;
    }

    .table-main tbody tr.row-selected td,
    .table-main tr.row-selected:hover td.sticky-row-style,
    .table-main tbody tr.row-selected:hover td .study-person-more-hover-parent {
        background-color: #FBFCFF;
    }

    .table-main tbody tr.row-being-viewed td,
    .table-main tr.row-being-viewed:hover td.sticky-row-style,
    .table-main tbody tr.row-being-viewed:hover td .study-person-more-hover-parent {
        background-color: #FFF9E6;
    }

    .table-main tr .sticky-row-style {
        box-shadow: #E7E8F1 inset -1px 0px 0px 0px;
        z-index: 1;
    }
    .table-main tr:hover td.sticky-row-style {
        background-color: #FBFCFF;
    }

    .table-main tr:hover .sticky-row-style {
        /* we have 3 here because on the panel page, zindex 2 is used for the row; and we want to show the tooltip */
        z-index: 3 !important;
    }



    .label-highlight-number {
        padding: 2px 4px;
        font-weight: 500;
        font-size: 12px;
        color: #001233;
        border-radius: 4px;
        background: #F6F6F6;
    }

    .label-highlight-number.active {
        color: #0a73eb;
        background: #E5F7FE;
    }


    .docs-wrapper {
        border-top: 1px solid #eee;
    }
    .docs-wrapper.mobile {
        flex-direction: column;
    }
    .docs-body {
        flex: 1;
        padding: 48px;
        color: #444;
        line-height: 24px;
        max-width: 750px;
    }
    .docs-body.mobile {
        padding: 24px;
    }
    .docs-nav {
        border-right: 1px solid #eee;
        background: #f9f5f1;
        width: 220px;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        height: 100vh;
        overflow-y: scroll;
        padding: 32px 12px 32px 24px;
    }
    .docs-nav.mobile {
        position: relative;
        width: auto;
        height: auto;
        padding: 24px;
    }
    .docs-nav .section-header {
        margin: 32px 0 8px;
        color: #000;
        font-weight: 500;

    }
    .docs-nav .section-header:first-child {
        margin-top: 0;
    }
    .docs-nav a {
        color: #666;
        display: block;
        font-size: 13px;
        margin-bottom: 4px;
        font-weight: 500;
    }
    .docs-nav a:hover {
        //text-decoration: underline;
        color: #0a73eb;
    }
    .docs-nav a.is-active {
        color: #0a73eb;
        font-weight: 600;
    }

    .blockquote-notes {
        border-left: 1px solid #E7E8F1;
        padding: 0 16px;
    }

    .disabled {
        opacity: 0.8;
        cursor: not-allowed;
    }
    .nice-element {
        background: #FBFAFC;
        padding: 12px 16px;
    }
    .nice-boxshadow {
        box-shadow: 0px 17px 100px rgba(0, 0, 0, 0.06), 0px 5.125px 30.1471px rgba(0, 0, 0, 0.0390953), 0px 2.12866px 12.5216px rgba(0, 0, 0, 0.03), 0px 0.769896px 4.5288px rgba(0, 0, 0, 0.0209047);
        border-radius: 8px;
    }
    .nice-boxshadow-light {
        box-shadow: 0px 4px 77px rgba(0, 0, 0, 0.03), 0px 0.893452px 17.199px rgba(0, 0, 0, 0.0178832), 0px 0.266004px 5.12058px rgba(0, 0, 0, 0.0121168);
    }

    .basic-card {
        border-radius: 10px;
        border: 1px solid var(--day-line-light, rgba(73, 95, 120, 0.12));
        background: var(--day-background-screen, #FFF);
        box-shadow: 0px 4px 64px 0px rgba(0, 0, 0, 0.03), 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
    }

    .card-grid {
        display: grid;
        grid-template-rows: auto 1fr auto;
        grid-template-columns: 32px auto;
        grid-template-areas:
            "icon header"
            "body body"
            "footer footer";
    }

    .card-grid.grid-no-icon {
        grid-template-columns: auto;
        grid-template-areas:
            "header"
            "body"
            "footer";
    }

    .card-grid.grid-no-footer {
        grid-template-rows: auto 1fr;
        grid-template-areas:
            "icon header"
            "body body";
    }

    .card-grid.grid-no-header {
        grid-template-rows: auto 1fr auto;
        grid-template-areas:
            "icon ."
            "body body"
            "footer footer";
    }

    .card-grid.grid-no-header.grid-no-footer {
        grid-template-rows: auto 1fr;
        grid-template-areas:
            "icon ."
            "body body";
    }

    .card-grid.grid-no-header.grid-no-icon {
        grid-template-columns: auto;
        grid-template-rows: 1fr auto;
        grid-template-areas:
            "body"
            "footer";
    }

    .card-grid.grid-no-icon.grid-no-footer {
        grid-template-columns: auto;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            "header"
            "body";
    }

    .card-grid.grid-no-header.grid-no-icon.grid-no-footer {
        grid-template-rows: auto;
        grid-template-columns: auto;
        grid-template-areas:
            "body";
    }

    .card-body {
        grid-area: body;
    }

    .card-footer {
        grid-area: footer;
    }

    .basic-card .card-footer {
        margin-top: 12px;
    }

    .card-header {
        grid-area: header;
        display: flex;
        align-items: center;
        gap: 8px;
    }
    .basic-card .card-header {
        margin-bottom: 16px;
    }

    .card-icon {
        grid-area: icon;
    }

    .basic-card .card-icon {
        margin-right: 8px;
    }

    .underlined {
        text-decoration: underline;
    }

    .text-primary, label.text-primary, .hover-black:hover { color: #001233; }
    .text-secondary { color: #929DB0; }
    .text-secondary-dark { color: #6B7A94; }



    .label-green { background: #D7FFE4 !important; color: #04AE3E !important; }
    .label-yellow { background: #FFF5D3 !important; color: rgb(223, 128, 7) !important; }
    .label-red { background: #FFD9D6 !important; color: #EA4335 !important; }
    .label-purple { background: #E2D4FF !important; color: #590DF5 !important; }

    .yellow { color: rgb(223, 128, 7); }

    .label-error {
        display: flex;
        align-items: center;
        height: 32px;
        width: fit-content;
        background: #FFD9D6;
        color: #D42220;
        padding: 0px 12px;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 500;
    }
    .label-warning {
        background: #FEE788;
        color: #001233 !important;
        padding: 8px 16px;
        border-radius: 6px;
        font-size: 13px;
    }
    .label-info {
        background: #E7E8F1;
        //color: black;
        padding: 4px 8px;
        border-radius: 6px;
        font-size: 13px;
    }

    .bg-warning { background: #F2A001 !important; }
    .bg-red { background: #EA4335 !important; }
    .bg-error { background: #FFD9D6 !important; }
    .bg-white { background: #fff; }
    .bg-gray { background: #f6f6f6; }
    .bg-green { background: #ecfeed !important; }
    .relative { position: relative; }
    .absolute { position: absolute; }
    .sticky { position: sticky; }
    .inline { display: inline-block !important; }
    .inline-block { display: inline-block !important; }
    .block { display: block !important; }
    .flex { display: flex; }
    .light { font-weight: 400 !important; }
    .normal { font-weight: 400; }
    .medium { font-weight: 500 !important; }
    .strong, .bold, strong { font-weight: 600 !important; }
    .uppercase { text-transform: uppercase; }
    .italic { font-style: italic; }
    .black { color: black !important; }
    .gray, .color-sp-status-unknown { color: #606E85 !important; }
    .green, .color-sp-status-1, .color-sp-status-4
        { color: #00BA3F !important; }
    .green-dark { color: #377F21 !important; }
    .red, .color-sp-status-2, .color-sp-status-3, .color-sp-status-7
        { color: #EA4335; }
    .text-purple, .purple { color: #0a73eb; }
    .warning, .orange, .color-sp-status-5, .color-sp-status-6
        { color: #F2A001; }
    .white { color: white; }
    .center { text-align: center; }
    .vertical-line {
        color: #E7E8F1;
        padding: 0 10px 0 2px;
        font-size: 11px;
    }
    .vertical-divider {
        background: #D4D9DD;
        width: 1px;
        height: 18px;
        display: inline-block;
        margin: 8px 20px 0;
        vertical-align: top;
    }
    .divider-color {
        color: #D4D9DD;
    }
    .fill-green { background: #00BA3F !important; }

    // Status Indicator Component
    .status-indicator-dot {
        display: inline-block;
        height: 8px;
        width: 8px;
        margin-right: 4px;
        border-radius: 1000px;
    }
    .status-indicator { color: #001233; }
    .status-indicator-gray.status-indicator-fully-colored { color: #606E85; }
    .status-indicator-gray > .status-indicator-dot { background: #606E85; }
    .status-indicator-green.status-indicator-fully-colored { color: #026B1F; }
    .status-indicator-green > .status-indicator-dot { background: #026B1F; }
    .status-indicator-red.status-indicator-fully-colored { color: #EA4335; }
    .status-indicator-red > .status-indicator-dot { background: #EA4335; }
    .status-indicator-yellow.status-indicator-fully-colored { color: #F2A001; }
    .status-indicator-yellow > .status-indicator-dot { background: #F2A001; }

    .sp-data-label-top-border { 
        border-top: 1px solid #e7e8f1;
        padding-top: 12px;
        width: 100%;
    }

    hr {
        border: 0;
        border-top: 1px solid rgba(73, 95, 120, 0.12);
        margin: 0;
        display: block;
    }

    ::selection {
        background: #0A73EB;
        color: white;
    }
    h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        font-weight: 600;
        margin-bottom: 8px;
        color: #222;
    }
    h2 {
        margin: 32px 0 8px 0;
    }

    ul, li {
        margin: 0 0 8px 16px;
    }
    ul, ol {
        padding: 0;
        margin: 0 0 24px 16px;
    }
    ol li p, ul li p {
        margin: 0;
    }

    ul.tight {
        margin: 0;
        padding: 0;
    }
    ul.tight li {
        margin: 2px 0 2px 16px;
    }

    ul.screener-mass-options, ul.screener-mass-options li {
        margin: 0 0 2px 12px;
    }

    ol.onboarding {
        list-style: none;
        counter-reset: my-awesome-counter;

    }
    ol.onboarding li {
        counter-increment: my-awesome-counter;
        position: relative;
        margin-left: 32px;
        margin-bottom: 16px;
    }
    ol.onboarding li::before {
        content: counter(my-awesome-counter);
        color: white;
        font-weight: bold;
        position: absolute;
        left: -48px;
        line-height: 32px;
        width: 32px;
        height: 32px;
        top: 0;
        background: #0A73EB;
        border-radius: 50%;
        text-align: center;
    }

    ol.onboarding li.onboarding-item-todo {
        color: black;
    }

    ol.onboarding li.onboarding-item-done::before {
        background: #eee;
    }

    ol.onboarding li.onboarding-item-done {
        color: #999;
    }
    .thin {
        font-weight: 400 !important;
    }
    .va-top {
        vertical-align: top !important;
    }
    .form-label-less-margin-bottom {
        margin-bottom: 8px !important;
    }

    .form-section-information {
        font-size: 12px;
        line-height: 18px;
        color: #6B7A94;
        padding-right: 60px;
    }

    .section-header-sticky {
        position: sticky;
        top: 0;
        background: white;
    }

    .section-body-sticky-header-scroll-offset .form-wrapper2 {
        scroll-margin-top: 96px;
    }

    .section-body-sticky-header-scroll-offset .form-wrapper2-border {
        scroll-margin-top: 60px!important;
    }

    .form-wrapper2 {
        width: 800px;
        //margin-bottom: 24px !important;
        display: flex;
    }
    .form-wrapper2-first {
        padding: 16px 0 32px 0;
    }
    .form-wrapper2-border {
        //border-radius: 12px;
        border-top: 1px solid #e7e8f1;
        padding: 32px 0;
    }
    .form-wrapper2 .form-label, .form-label {
        color: #001233;
        font-weight: 500;
        padding: 0 0 6px 0;
    }

    .form-wrapper2 .form-label-border {
        border-bottom: 1px solid rgba(73, 95, 120, 0.12);
    }
    .form-row-margin { margin-top: 22px !important; }
    .form-wrapper2 .form-data {
        //width: 550px;
        width: 100%;
    }
    .form-wrapper2 .form-data select {
        width: 500px;
    }

    .form-wrapper {
        flex-direction: row;
        flex-wrap: wrap;
        border: 1px solid #eee;
        border-radius: 4px;
        width: 800px;
        padding: 32px;
        align-items: flex-start;
    }
    .form-wrapper.plain {
        border: 0;
        padding: 0;
    }
    .form-wrapper .form-label {
        text-align: right;
        font-size: 13px;
        padding: 6px 16px 0 0;
        color: #929DB0;
        margin-bottom: 16px;
        line-height: 16px;
        font-weight: 300;
    }
    .form-wrapper2 h2 {
        font-size: 18px;
        font-weight: 600;
    }
    .form-wrapper .form-data {
        //margin-bottom: 16px;
        position: relative;
    }
    .form-hr {
        height:0;
        border-top: 1px solid #eee;
    }
    .viewed-by {
        z-index: 100;
        //border-radius: 6px;
        //border: 1px solid black;
        //border-bottom: 1px solid #E7E8F1;
        padding: 16px 0 12px 32px;
        margin: 0 -32px 0 -32px !important;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        background: #fff;
    }
    .theme-button-primary {
        tab-size: 4;
        display: inline-block;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        border: 0;
        font-size: 14px;
        font-weight: 400;
        color: white;
        background-color: #0A73EB;
        border-radius: 4px;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;
        box-shadow: rgba(36,18,77,0.08) 0px 0px 0px 1px,rgba(36,18,77,0.32) 0px 4px 4px -4px;
        text-align: center;
        width: auto;
        margin: 0px;
        outline: none;
        transition: box-shadow 150ms;

        ::selection {
            background: #fff;
            color: #0A73EB;
        }
    }

    .theme-button-dscout-integration {
        background-image: url(/dscout-white.png);
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: 12px 12px;
        padding-left: 38px;
    }

    .theme-input-dropshadow {
        border: 1px solid #E7E8F1 !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03) !important;
    }
    .theme-input-dropshadow::placeholder {
        color: #747474 !important;
        opacity: 1;
    }
    .mimic-theme {
        background: #fbfafc;
        box-shadow: rgba(36, 18, 77, 0.08) 0px 0px 0px 1px;
    }

    .theme-input {
        //background: #fbfafc;
        background: #fff;
        box-shadow: rgba(36, 18, 77, 0.08) 0px 0px 0px 1px;
        border: 0;
        font-size: 14px;
        color: #000 !important;
        height: 32px;
        padding: 8px 12px;
        border-radius: 4px !important;
        width: 100%;
        :disabled {
            color: #999 !important;
        };
        :hover {
            outline: none;
            box-shadow: rgba(10, 115, 235, .5) 0px 0px 0px 1px;
        };
        :focus {
            outline: none;
            box-shadow: rgba(10, 115, 235, .5) 0px 0px 0px 1px;
        }
        ::placeholder {
            color: #747474;
            opacity: 1;
        }
        &.input-with-icon {
            padding-left: 32px;
        }
    }
    .theme-input.input-darker {
        background: #FAFAFA;
        border: 1px solid rgba(73, 95, 120, 0.12);
        box-shadow: none;
        :hover {
            outline: none;
            border-color: #0A73EB;
        }
        :focus {
            outline: none;
            border-color: #0A73EB;
        }
    }
    .theme-input.error, .error-button {
        box-shadow: rgba(255, 0, 0, .5) 0px 0px 0px 1px, rgba(255, 0, 0, .3) 0px 0px 0 2px
    }

    .form-wrapper2 .theme-input {
        background: #FAFAFA;
        border: 1px solid rgba(73, 95, 120, 0.12);
        box-shadow: none;
    }
    .form-wrapper2 .theme-input:hover {
        border-color: #0A73EB;
    }
    
    .form-wrapper2 .theme-input:focus {
        border-color: #0A73EB;
    }

    .form-wrapper2 select {
        background: #FAFAFA;
        border: 1px solid rgba(73, 95, 120, 0.12);
        box-shadow: none;
    }
    .form-wrapper2 select:hover {
        border-color: #0A73EB;
    }
    .form-wrapper2 select:focus {
        border-color: #0A73EB;
    }
    .form-wrapper2 select:invalid {
        color: #929DB0;
    }

    .mw-100 {
        max-width: 100%;
    }
    .w120 {
        width: 120px !important;
    }
    .theme-input.theme-input-select {
        width: auto;
    }

    .theme-input.theme-input-screener-main {
        font-size: 15px;
        padding: 8px 12px;
        min-height: 36px;
    }

    .theme-input.theme-input-simple {
        border-radius: 0 !important;
        background: transparent;
        box-shadow: none;
        border-bottom: 1px solid rgba(36, 18, 77, 0.05);
        padding-left: 0;
        padding-right: 0;
        font-size: 13px;

        :hover {
            border-bottom: 1px solid rgba(36, 18, 77, 0.5);
            box-shadow: none;
        };
        :focus {
            border-bottom: 1px solid #0A73EB;
            box-shadow: none;
        };
    }
    .theme-input.theme-input-simple.error {
        border-bottom: 1px solid rgba(255, 0, 0, 1) !important;
        box-shadow: none !important;
    }

    /**


     EditorJS (email) styles

     */

    .codex-editor {
        background: #fff;
        padding: 48px;
    }

    .ce-popover__item-icon {
        /*we dont have icons for the buttons, so just hide this*/
        display: none;
    }
    .ce-block {
        border: 2px solid transparent;
        border-radius: 4px;
    }

    .ce-block:hover, .ce-block--focused {
        border: 2px solid #ddd;
    }

    .ce-toolbar__plus {
        box-shadow: rgba(36,18,77,0.08) 0px 0px 0px 1px, rgba(36,18,77,0.1) 0px 0px 8px 0px;
        background: #fff;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        //left: 14px;
        margin-right: 4px;
        svg {
            color: black;
            height: 12px;
            width: 12px;
        }
    }
    .ce-toolbar__plus--hidden {
        //display: flex;
    }
    .ce-toolbar__actions {
        //right: 16px;
    }
    .ce-toolbar__settings-btn {
        box-shadow: rgba(36,18,77,0.08) 0px 0px 0px 1px, rgba(36,18,77,0.1) 0px 0px 8px 0px;
        background: #fff;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        svg {
            color: black;
            height: 8px;
            width: 8px;
        }
    }
    .image-tool__image-picture {
        display: inline-block !important;
    }

    .ce-toolbar__settings-btn {
        //background: #0A73EB;
        //color: #fff;
        //border-radius: 4px;
        //overflow: hidden;
        &:hover {
            //color: #fff;
            //opacity: 0.7;
        }
    }

    .ce-toolbox__button {
        //justify-content: left;
    }



    .ce-toolbox {
        //left: 48px;
        //flex-direction: column;
        //background: #fff;
        //box-shadow: rgba(36,18,77,0.08) 0px 0px 0px 1px, rgba(36,18,77,0.1) 0px 0px 8px 0px;
        //border-radius: 4px;
        //padding: 8px 16px;
        //text-align: left;
    }

    .ce-toolbox li {
        //font-weight: 500;
        //margin: 0 0 0 0;
        //padding: 0;
        //color: black;
        //width: auto;
        //text-align: left;
    }


    .react-modal-size-huge {
        width: 1060px;
    }

    .react-tooltip-hover-stay {
        pointer-events: auto !important;
        &:hover {
            visibility: visible !important;
            opacity: 1 !important;
        }
    }

    .__react_component_tooltip {
        text-transform: initial;
        
        display: inline-block;
        font-size: 13px !important;
        left: -999em;
        opacity: 0;
        position: fixed;
        pointer-events: none;
        transition: opacity 0.3s ease-out;
        top: -999em;
        visibility: hidden;
        z-index: 9999;
     
        padding: 8px 12px !important;

     

        /* Shadow 2 */
        box-shadow: 0px 17px 100px rgba(0, 0, 0, 0.06), 0px 5.125px 30.1471px rgba(0, 0, 0, 0.0390953), 0px 2.12866px 12.5216px rgba(0, 0, 0, 0.03), 0px 0.769896px 4.5288px rgba(0, 0, 0, 0.0209047);
        border: 0;
        border-radius: 8px !important;


        background: #fff !important;
        color: #001233 !important;

        &.allow_hover,
        &.allow_click {
            pointer-events: auto;
        }

        &::before,
        &::after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
        }

        &.show {
            opacity: 1 !important;
            margin-top: 0;
            margin-left: 0;
            visibility: visible;
        }

        // Place
        &.place-top {
            &::before {
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                bottom: -9px !important;
                left: 50%;
                margin-left: -10px;
                border-top-color: rgba(155, 155, 155, 0.1) !important;
            }
            &::after {
                bottom: -7px !important;
                border-top-color: #fff !important;
            }
        }

        &.place-bottom {
            &::before {
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                top: -9px;
                left: 50%;
                margin-left: -10px;
                border-bottom-color: rgba(155, 155, 155, 0.1) !important;
            }

            &::after {
                bottom: -7px !important;
                border-bottom-color: #fff !important;
            }
        }

        &.place-left {
            &::before {
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                right: -8px;
                top: 50%;
                margin-top: -5px;

                border-left-color: rgba(155, 155, 155, 0.1) !important;
            }

            &::after {
                border-left-color: #fff !important;
            }
        }

        &.place-right {
            &::before {
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                left: -8px;
                top: 50%;
                margin-top: -5px;

                border-right-color: rgba(155, 155, 155, 0.1) !important;
            }

            &::after {
                border-right-color: #fff !important;
            }
        }

        // Wrapping
        .multi-line {
            display: block;
            padding: 2px 0;
            text-align: center;
        }
    }
    
    .error {
        color: rgb(255,0,0, .8);
    }

    .screener-error-message {
        color: rgb(255,0,0, .8);
        font-size: 12px;
        margin: 4px 0 0 0;
    }

    .danger-box {
        background: #D42220;
        color: #fff;
        border-radius: 4px;
        padding: 8px;
    }

    .danger-box-small {
        background: #D42220;
        color: #fff;
        border-radius: 6px;
        padding: 6px 16px;
        font-size: 12px;
        font-weight: 500;
    }

    .warning-box {
        background: #F2A001;
        color: #fff;
        border-radius: 4px;
        padding: 8px;
    }

    .warning-box-small {
        background: #F2A001;
        color: #fff;
        border-radius: 6px;
        padding: 6px 16px;
        font-size: 12px;
        font-weight: 500;
    }

    .notification-box-small {
        color: #fff;
        border-radius: 6px;
        padding: 6px 12px;
        font-size: 12px;
        font-weight: 500;
    }
    .notification-box-large {
        border-radius: 6px;
        padding: 16px 16px 8px 16px;
        border-width: 1px;
        border-style: solid;
    }

    

    .svg-sw-2px {
        stroke-width: 2px !important;
    }
    .svg-sw-3px {
        stroke-width: 3px !important;
    }

    .nowrap {
        white-space: nowrap;
    }
    .ellipsis, .ellipsis-with-hover {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .ellipsis-with-hover:hover {
        text-overflow: ellipsis;
        white-space: normal;
        word-break: break-all;
        max-height: 72px;
        overflow: hidden;
    }

    .arrow-down {
        vertical-align: middle;
        display: inline-block;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;

        border-top: 4px solid #fff;

        &.purple {
            border-top-color: #0A73EB;
        }
    }

    .arrow-down-dark {
        vertical-align: middle;
        display: inline-block;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;

        border-top: 4px solid #000;
    }


    .custom-radio, .custom-checkbox {
        padding: 2px 4px;
        border-radius: 4px;
        transition: background-color 100ms;
        cursor: pointer;
        input {
            vertical-align: top;
            //margin-top: 5px;
        }
        label {
            cursor: pointer;
            display: flex !important;
            align-items: center;
        }
    }
    .custom-radio:hover, .custom-checkbox:hover {
        background: #eee !important;
        cursor: pointer;
    }

    .react-datepicker-popper {
        z-index: 9999 !important;
    }
    .react-datepicker-time__input input {
        width: 100px;
    }

    /* item box */
    .select-box-header {
        flex-shrink: 0;
        padding: 4px 0 8px 0;
        //font-size: 12px
        width: 87px;
        height: 82px;
        text-align: center;
        display: block;
        //font-weight: 500;
        //color: #999;
        background: #FBFBFF;
        border-radius: 4px;
        line-height: 26px;
    }
    .select-box-header-wrapper {
        z-index: 1;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        //box-shadow: rgba(36, 18, 77, 0.05) 0px 0px 0px 1px, rgba(36, 18, 77, 0.1) 0px 2px 10px 0px;
        background: #fff;
        //margin: 0 8px !important;
        //border-radius: 4px;
        border-bottom: 8px solid white;
        z-index: 10;
    }
    .select-box-header-wrapper .select-box-header:first-child {
        //padding-left: 20px;
        //width: 112px;
    }
    .select-box-header-wrapper .select-box-header:last-child {
        //padding-right: 20px;
        //width: 112px;
    }
    .select-box {
        border: 1px solid #e7e8f1;
        //text-decoration: line-through;
        width: 87px;
        //height: 24px;
        padding: 0 0 0 8px;
        text-align: center;
        line-height: 16px;
        display: block;
        margin: 0 0 4px;
        border-radius: 4px;
        //border: 1px solid #ccc;
        font-size: 11px;
        box-sizing: border-box;
        height: 18px;
    }

    /* selected item box */
    .select-box.selected {
        border-color: #0A73EB !important;
        background: #0A73EB !important;
        color: transparent;
        text-decoration: none;
        opacity: 1 !important;
        //border-bottom: 0;
        //border-top: 0;
        cursor: default !important;
        text-decoration: none !important;
        text-align: left;
        height: 22px;
        margin-bottom: 0;
        border-radius: 0;
    }

    .select-box.is-busy {
        background: red !important;
    }
    
    .select-box.range-start {
        //border-top: 1px solid #0A73EB !important;
        color: white;
        font-weight: 500;
        border-radius: 4px 4px 0 0 !important;
        height: 22px !important;
    }
    .select-box.range-end {
        //border-top: 1px solid #0A73EB !important;
        //margin-bottom: 4px;
        height: 18px;
        border-radius: 0 0 4px 4px;
        position: relative;
        //margin-top: 8px;
    }
    .select-box.range-end:not(.selected) {
        //margin-top: 8px;
    }
    .select-box.range-end.selected {
        //margin-top: 0;   
    }
    .select-box.selected.range-end:before, .select-box.range-end:before {
        content: "";
        position: absolute;
        left: -1px;
        right: -1px;
        height: 4px;
        background: white;
        top: -5px;
    }
    .select-box.unavailable:not(.selected), .select-box.unavailable:not(.selected):hover {
        cursor: not-allowed;
        pointer-events: all !important;
        background: #FBFBFF;
        //text-decoration: line-through;
        color: transparent !important;
        border-color: #FBFBFF !important;
        position: relative;
    }
    .select-box.unavailable:not(.selected):after {
        content: url("data:image/svg+xml,%3Csvg stroke='%23929db0' fill='none' stroke-width='2' viewBox='0 0 24 24' stroke-linecap='round' stroke-linejoin='round' height='12px' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='3' y='11' width='18' height='11' rx='2' ry='2'%3E%3C/rect%3E%3Cpath d='M7 11V7a5 5 0 0 1 10 0v4'%3E%3C/path%3E%3C/svg%3E");
        position: absolute;
        left: 0;
        right: 0;
        pointer-events: none;
        top: 1px;
    }
    .select-box .range-delete {
        cursor: pointer;
        background: transparent;
        height: 16px;
        width: 16px;
        margin: 2px 2px 0 0;
        line-height: 14px;
        text-align: center;
        border-radius: 2px;
        font-size: 14px;
    }
    .select-box .range-delete svg {
        color: white;
    }

    /* selection container */
    .selection {
        position: relative;
    }

    /* selection box */
    .selection-border {
        background: rgba(0, 162, 255, 0.4);
        position: fixed;
        z-index: 99;
    }
    .noselect {
      -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
           -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
    }

    .modal-info {
        color: #606E85;
    }

    .children-flex-grow > div {
        //flex-grow: 1;
        //background-color: red;
    }

    .condi-text {
        display: flex;
        margin-top: 6px !important;
        color: #606E85;
    }

    .email-info-style {
        vertical-align: top;
        margin: 4px 0 0 2px;
        color: #6B7A94;
        stroke-width: 2.5;
    }

    .email-reminder {
        display: inline-block;
        max-width: 100%;
        font-weight: 500;
        text-align: center;
        border-radius: 24px;
        //border: 1px solid #999;
        background: #E6E6E6;
        color: #001233;
        padding: 6px 12px;
        margin: 0 0 24px;
        font-size: 12px;
        //box-shadow: rgba(100,100,100,.5) 0px 0px 8px -3px, rgba(222, 222, 222, .3) 0px 0px 0 1px
        position: relative;
    }
    .email-reminder svg {
        vertical-align: top;
        margin: 4px 4px 0 0;
        color: #001233;
        stroke-width: 2.5;
    }
    .email-reminder-line {
        background: #E6E6E6;
        height: 1px;
        content: "hi";
        position: absolute;
        left: 0;
        right: 0;
        top: 15px;
    }

    .select-modal-item .select-modal-item-remove {
        visibility: hidden;
    }
    .select-modal-item:hover .select-modal-item-remove {
        visibility: visible;
    }

    .account-email-footer {
        max-width: 600px;
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: 40px;
        white-space: pre-wrap;
        text-align: center;

        -webkit-transition: color 200ms linear;
        -ms-transition: color 200ms linear;
        transition: color 200ms linear;
    }
    .clouds {
        position: absolute;
        bottom: -90px;
        left: 0;
        right: 0;
        height:150px;
        border-radius: 0 0 50px 50px;
        background:
            radial-gradient(150px 150px,white 99%,transparent 100%) -100px 0 / 300px 300px,
            radial-gradient(150px 150px,white 99%,transparent 100%) 70px 30px / 300px 300px,
            radial-gradient(50px 50px,white 99%,transparent 100%) 270px 30px / 100px 100px;
      background-repeat:no-repeat;
    }
    .shaking {
      /* Start the shake animation and make the animation last for 0.5 seconds */
      animation: shaking 1s;

      /* When the animation is finished, start again */
      animation-iteration-count: infinite;
    }

    @keyframes shaking {
      0% { transform: translate(1px, 1px) rotate(0deg); }
      10% { transform: translate(-1px, -2px) rotate(-1deg); }
      20% { transform: translate(-3px, 0px) rotate(1deg); }
      30% { transform: translate(3px, 2px) rotate(0deg); }
      40% { transform: translate(1px, -1px) rotate(1deg); }
      50% { transform: translate(-1px, 2px) rotate(-1deg); }
      60% { transform: translate(-3px, 1px) rotate(0deg); }
      70% { transform: translate(3px, 1px) rotate(-1deg); }
      80% { transform: translate(-1px, -1px) rotate(1deg); }
      90% { transform: translate(1px, 2px) rotate(0deg); }
      100% { transform: translate(1px, -2px) rotate(-1deg); }
    }

    .react-toast-notifications__container {
        /*HelpScout zindex is 1050, so this should be above*/
        z-index: 1100 !important;
    }

    .react-toast-notifications__toast {
        background: #fff !important;
        border-radius: 8px !important;
    }
    .react-toast-notifications__toast--success {

    }
    .react-toast-notifications__toast--info {

    }
    .react-toast-notifications__toast__icon-wrapper {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
        padding-top: 10px !important;
        padding-bottom: 4px !important;
    }
    .react-toast-notifications__toast__content {
        color: #333;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 24px !important;
    }
    .react-toast-notifications__toast__dismiss-button {
        color: #333 !important;
        padding: 10px 12px 4px 12px !important;
    }

    .checkbox-label {
        padding: 4px 0 0 2px;
        font-weight: 400;
    }

    .toggle-label {
        display: inline-block;
        padding: 6px 0 0 8px;
        font-weight: 400;
        vertical-align: top;
    }

    .basic-avatar {
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #FFCB14;
        color: #fff;
        vertical-align: top;
        line-height: 24px;
        font-size: 10px;
        font-weight: 500;
        overflow: hidden;
        text-align: center;

        img {
            width: 100%;
            height: 100%;
            vertical-align: top;
        }
    }

    .all-breaks {
        white-space: pre-wrap;
    }

    .ql-editor{
        min-height:160px;
    }

    .sms-label {
        color: #377F21;
        border-radius: 0;
        padding: 8px 0 8px 0 !important;
        margin: 0 16px !important;
        display: block;
        margin: 0;
        border-top: 1px solid #d8d1e7;
    }
    .sms-background {
        white-space: pre-wrap;
    }


    .sms-icon {
        font-weight: 500;
        font-size: 12px;
        stroke-width: 2.5;
        vertical-align: top !important;
        margin: 4px 0 0 0;
    }

    .grab-hand-nice-dropdown {
        color: #999;
        fontSize: 12px;
        margin: 8px 12px 0 0;
        cursor: grab;
    }
    .grab-hand-nice-dropdown:hover {
        color: black;
        background-color: rgba(36, 18, 77, 0.04);
    }

    .hover-gray-bg:hover {
        background: #f7f7f7;
        border-radius: 4px;
    }

    .question-logic-group {
        border-radius: 4px;
        background: #eee;
        padding: 8px 16px;
    }
    .question-logic-sub-group {
        border-radius: 4px;
        background: #ddd;
        padding: 8px 16px;
    }

     /* Dropdown Button */
    .dropbtn {
        background-color: #04AA6D;
        color: white;
        padding: 16px;
        font-size: 16px;
        border: none;
    }

    /* The container <div> - needed to position the dropdown content */
    .nice-submenu {
        position: relative;
    }

    .nice-submenu:after {
        content: url("/caret-right.svg");
        position: absolute;
        right: 12px;
        top: 12px;
        pointer-events: none;
    }
    .nice-submenu:hover:after {
        content: url("/caret-right-purple.svg");
    }

    /* Dropdown Content (Hidden by Default) */
    .nice-submenu-content-wrapper {
        display: none;
        position: absolute;
        z-index: 1000;
        left: 100%;
        top: -8px;
    }
    .nice-submenu-content {
        background-color: #fff;
        box-shadow: rgba(36, 18, 77, 0.05) 0px 0px 0px 1px, rgba(36, 18, 77, 0.1) 0px 2px 10px 0px;
        min-width: 160px;
        max-width: 240px;
        margin-left: 8px;
        border-radius: 4px;
        padding: 8px 0;
    }

    /* Links inside the nice-submenu */
    .nice-submenu-content div {
        //color: black;
        text-decoration: none;
        display: block;
        vertical-align: top;
    }

    /* Show the nice-submenu menu on hover */
    .nice-submenu:hover {
        background: #f7f7f7;
    }

    .nice-submenu:hover .dd-parent {
        color: #0A73EB !important;
    }

    .nice-submenu:hover .nice-submenu-content-wrapper {display: block;}
    
    .modal-actions {
        margin-top: 24px !important;
        text-align: right;
    }

    .modal-actions.modal-actions-sticky {
        position: sticky;
        bottom: 0;
        background: #fff;
        padding: 24px 32px 24px 32px;
        margin: 0 -32px -32px;
        border-top: 1px solid rgb(238, 238, 238);
    }

    .modal-sticky-top {
        position: sticky;
        top: 70px;
        background: #fff;
        padding: 10px 0 16px 0;
        margin: -10px 0 10px 0!important;
        z-index: 1;
    }

    .video-rec-wrapper {
        position: relative;
        maxWidth: 640px;
        height: auto;
        border-radius: 8px;
        //border: 1px solid #aaa;
        background: #555;
        overflow: hidden;
    }

    .video-rec-controls {
        text-align: center;
        margin: 0 auto;
        background: #333;
        padding: 16px;
    }

    .video-rec-timer {
        display: none;
        pointer-events: none;
        color: white;
        font-size: 64px;
        line-height: 160px;
        position: absolute;
        text-align: center;
        margin: 0 auto;
        top: 116px;
        bottom: 0;
        left: 0;
        right: 0;
        width: 160px;
        height: 160px;
        background: rgba(0,0,0,.5);
        border-radius: 8px;
    }

    .recording-circle {
        width: 14px;
        height: 14px;
        background: #B10000;
        border-radius: 50%;
        display: inline-block;
        margin: 5px 4px 0 0;
        vertical-align: top;
    }
    .recording-square {
        width: 14px;
        height: 14px;
        background: #B10000;
        border-radius: 4px;
        display: inline-block;
        margin: 4px 4px 0 0;
        vertical-align: top;
    }

    .message-contains-html {
        white-space: normal !important;
    }

    .screener-q-scroll-margin-top {
        scroll-margin-top: 174px;
    }
    .hover-bg-gray:hover {
        background: #eee;
    }
    .panelist-profile-h2 {
        font-size: 18px;
        font-weight: 600;
    }

    .ql-container.ql-snow {
        border-color: rgba(36,18,77,0.08) !important;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .ql-toolbar.ql-snow {
        border-color: rgba(36,18,77,0.08) !important;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .quill-render h1, .quill-render .ql-snow .ql-editor h1 {
        font-size: 24px;
        font-weight: 600;
        color: #001233;
        line-height: 32px;
        margin: 0 0 12px 0;
    }

    .quill-render h2, .quill-render .ql-snow .ql-editor h2 {
        font-size: 20px;
        font-weight: 600;
        color: #001233;
        line-height: 28px;
        margin: 0 0 8px 0;
    }
    
    .quill-render p, .quill-render .ql-snow .ql-editor p, .quill-render div, .quill-render .ql-snow .ql-editor div {
        font-size: 14px;
        font-weight: 400;
        color: #001233;
        line-height: 20px;
        margin: 0;
    }

    .quill-render blockquote, .quill-render .ql-snow .ql-editor blockquote {
        font-size: 16px;
        font-weight: 400;
        color: #001233;
        line-height: 20px;
        border-left: 5px solid #e1e1e1;
        padding: 1px 0 2px 10px;
        margin-left: 15px;
    }

    .quill-render ul li, .quill-render .ql-snow .ql-editor ul li, .quill-render ol li, .quill-render .ql-snow .ql-editor ol li {
        font-size: 16px;
        font-weight: 400;
        color: #001233;
        line-height: 20px;
        margin-bottom: 2px;
    }

    .quill-render ul, .quill-render .ql-snow .ql-editor ul, .quill-render ol, .quill-render .ql-snow .ql-editor ol {
        margin-bottom: 10px;
    }

    .quill-render:not(:has(.ql-editor)) ol li {
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-0;

        &::marker {
            content: counter(list-0) ". ";
        }
    }

    .quill-render:not(:has(.ql-editor)) ol li.ql-indent-1 {
        counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-1;
        margin-left: 4.5em;

        &::marker {
            content: counter(list-1, lower-alpha) ". ";
        }
    }

    .quill-render:not(:has(.ql-editor)) ol li.ql-indent-2 {
        counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-2;
        margin-left: 7.5em;

        &::marker {
            content: counter(list-2, lower-roman) ". ";
        }
    }

    .quill-render:not(:has(.ql-editor)) ol li.ql-indent-3 {
        counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-3;
        margin-left: 10.5em;

        &::marker {
            content: counter(list-3) ". ";
        }
    }

    .quill-render:not(:has(.ql-editor)) ol li.ql-indent-4 {
        counter-reset: list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-4;
        margin-left: 13.5em;

        &::marker {
            content: counter(list-4, lower-alpha) ". ";
        }
    }

    .quill-render:not(:has(.ql-editor)) ol li.ql-indent-5 {
        counter-reset: list-6 list-7 list-8 list-9;
        counter-increment: list-5;
        margin-left: 16.5em;

        &::marker {
            content: counter(list-5, lower-roman) ". ";
        }
    }

    .quill-render:not(:has(.ql-editor)) ol li.ql-indent-6 {
        counter-reset: list-7 list-8 list-9;
        counter-increment: list-6;
        margin-left: 19.5em;

        &::marker {
            content: counter(list-6) ". ";
        }
    }

    .quill-render:not(:has(.ql-editor)) ol li.ql-indent-7 {
        counter-reset: list-8 list-9;
        counter-increment: list-7;
        margin-left: 22.5em;

        &::marker {
            content: counter(list-7, lower-alpha) ". ";
        }
    }

    .quill-render:not(:has(.ql-editor)) ol li.ql-indent-8 {
        counter-reset: list-9;
        counter-increment: list-8;
        margin-left: 25.5em;

        &::marker {
            content: counter(list-8, lower-roman) ". ";
        }
    }

    .quill-render:not(:has(.ql-editor)) ol li.ql-indent-9 {
        counter-increment: list-9;
        margin-left: 28.5em;

        &::marker {
            content: counter(list-9) ". ";
        }
    }

    .quill-render:not(:has(.ql-editor)) ul li {
        margin-left: 0;
        list-style-type: disc;
    }

    .quill-render:not(:has(.ql-editor)) ul li.ql-indent-1 {
        margin-left: 4.5em;
    }

    .quill-render:not(:has(.ql-editor)) ul li.ql-indent-2 {
        margin-left: 7.5em;
    }

    .quill-render:not(:has(.ql-editor)) ul li.ql-indent-3 {
        margin-left: 10.5em;
    }

    .quill-render:not(:has(.ql-editor)) ul li.ql-indent-4 {
        margin-left: 13.5em;
    }

    .quill-render:not(:has(.ql-editor)) ul li.ql-indent-5 {
        margin-left: 16.5em;
    }

    .quill-render:not(:has(.ql-editor)) ul li.ql-indent-6 {
        margin-left: 19.5em;
    }

    .quill-render:not(:has(.ql-editor)) ul li.ql-indent-7 {
        margin-left: 22.5em;
    }

    .quill-render:not(:has(.ql-editor)) ul li.ql-indent-8 {
        margin-left: 25.5em;
    }

    .quill-render:not(:has(.ql-editor)) ul li.ql-indent-9 {
        margin-left: 28.5em;
    }

    .save-edit-editor--wrapper {
        border: 1px solid #1D6AF7;
        border-radius: 4px;
        overflow: hidden;
        
        .ql-container.ql-snow {
            border: none;
        }

        .ql-toolbar.ql-snow {
            border: none;
            border-bottom: 1px solid rgba(36, 18, 77, 0.08);
        }
        
        .save-edit-editor--footer {
            padding: 0 15px 12px 15px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        
        .save-edit-editor--edit-cover {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            z-index: 1;
            cursor: pointer;
            border: 0;
            font-size: 20px;
            opacity: 0;
            color: #FFF;
            background: #00123340;
            transition: opacity 0.2s ease-in;
            
            .save-edit-editor--edit-icon {
                margin-right: 6px;
                height: 20px;
                width: 20px;
                padding: 2px;
            }
            
            &:hover {
                opacity: 1;
            }
        }

        // Read-only mode
        &.save-edit-editor--readonly {
            position: relative;
            border-color: rgba(36, 18, 77, 0.08);
            
            .ql-toolbar {
                display: none;
            }
            
            .save-edit-editor--footer {
                display: none;
            }
        }
    }

    /* In the 'Range' element of screeners, we use the ReactBootstrapSlider for the slider. The problem is that tooltips
    displayed above the slider are always visible and positioned incorrectly, so let's just hide them for now. */
    .range .slider .tooltip {
        display: none !important;
    }

    /*.quill-render p > br:only-child {
        display: block;
        margin: 12px 0 0;
    }*/

    .screener-quota-type-enabled {
        cursor: pointer !important;
    }

    .screener-quota-type-disabled {
        cursor: not-allowed !important;
        opacity: 0.5;
    }

    .ul-feature-updates {
        margin: 4px 0 0 0;
        color: #929DB0;
        list-style: none;
    }

    .ul-feature-updates li {
        line-height: 20px;
        margin: 0 0 8px 12px;
        text-indent: -12px;
    }

    .help-question {
        background: #f2f2f2;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        display: inline-block;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        color: #929DB0;
    }
    .help-question:hover {
        color: #001233;
        background: #e7e7e7;
    }

    .help-question.warn {
        color: #FFEECC;
        background-color: #FF8A00;
        transition: all .2s ease-in-out;

        &:hover {
            opacity: 0.8;
        }
    }

    .row-hover {

    }
    .row-hover:hover {
        background: #FBFCFF;
    }

    .row-basic-grid {
        border-top: 1px solid #E7E8F1;
        padding: 8px 0;
    }
    .row-basic-grid:first-child, .row-basic-grid:first-child {
        border: 0;
    }

    @keyframes row_recently_updated_animation {
        0% {
            color: rgb(255, 40, 180);
            font-weight: 900;
            text-shadow: 0px 0px 20px rgb(255, 140, 180), 0px 0px 2px rgb(255, 40, 180);
        }
        50% {
            color: rgb(255, 40, 180);
            font-weight: normal;
            text-shadow: none;
        }
        100% {
            color: black;
        }
    }

    .row-recently-updated-animation {
        animation: row_recently_updated_animation 1s ease-out 0s 1;
        -webkit-animation: row_recently_updated_animation 1s ease-out 0s 1;
    }

    .settings-header {
        flex-shrink: 0;
        width: 280px;
        //padding-right: 80px;
        justify-content: space-between;
        margin-top: 42px !important;
        margin-bottom: 6px !important;
        clear: both;
        line-height: 18px;
    }
    .settings-header h2 {
        margin-bottom: 8px;
    }

    .settings-header:first-child {
        margin-top: 0 !important;
    }

    tr .td-row-hover-parent .td-row-hover-child, .show-on-hover-container .show-on-hover-element {
        visibility: hidden;
    }
    tr:hover .td-row-hover-parent .td-row-hover-child, .show-on-hover-container:hover .show-on-hover-element {
        visibility: visible;
    }

    .subnav-3 {
        border-bottom: 1px solid #E7E8F1;
        width: 100%;
        justify-content: space-between;
    }
    .subnav-3 .link {
        font-weight: 600;
        padding-bottom: 9px;
        border-bottom: 2px solid #0a73eb;
        margin-bottom: -1px;
        line-height: 23px;
    }
    .subnav-3 .link svg {
        vertical-align: top;
        margin: 4px 4px 0 0;
        stroke-width: 2.2px;
        font-size: 16px;
    }
    .subnav-3 .inactive {
        border-bottom: 2px solid transparent;
    }

    .subnav-svg {
        margin: 2px 6px 0 0;
        font-size: 18px;
        stroke-width: 1.5px;
        vertical-align: top;
    }

    .question-has-page-logic {
        height: 56px;
        background: #F8F8FD;
        border-top: 1px solid #F8F8FD;
        border-left: 1px solid #F8F8FD;
        border-right: 1px solid #F8F8FD;
        padding: 16px 24px;
        border-radius: 8px 8px 0 0;
        margin: -1px !important;
    }
    .rfb-item:hover .question-has-page-logic, .rfb-item:focus-within .question-has-page-logic {
        border-color: #E7E8F1;
    }

    .switch-label {
        display: inline-block;
        vertical-align: top;
        margin: -3px 0 0 8px;
    }

    .text-left {
        text-align: left;
    }

    .text-right {
        text-align: right;
    }

    .range-nav {
        width: 33.333%;
    }

    @media only screen and (max-width: 769px) {
        .range-nav {
            width: 40%;
        }
    }
    
    .option-label__tag {
        p {
            margin: 0
        }
    }

    .ql-editor {
        max-height: 400px;
        overflow-y: scroll;
    }

    .upload-file__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
    }

    .upload-file__wrapper:hover {
        box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
        transition: all .2s ease-in-out;
    }

    .upload-file__wrapper button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }

    .upload-file__wrapper button svg {
        transition: all .2s ease-in-out;
    }

    .upload-file__wrapper svg:hover {
        transform: scale(1.5);
    }

    .fs-headline-24 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
    }

    .fs-headline-20 {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
    }

    .fs-title-18 {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
    }

    .fs-title-16 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    .fs-title-14 {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }

    .fs-title-12 {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
    }

    .fs-accent-18 {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
    }

    .fs-accent-16 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

    .fs-accent-14 {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }

    .fs-accent-12 {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
    }

    .fs-body-16 {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
    }

    .fs-body-16 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .fs-body-14 {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }

    .fs-body-12 {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }

    .color-light { color: #FFF; }
    .color-light-70 {
        color: rgba(255, 255, 255, 0.7);
    }
    .color-bg-light { background: #FFF; }
    .color-dark { color: #000; }
    .color-bg-dark { background: #000; }

    .color-text-primary, .link-navigation-primary { color: #001233; }
    .color-bg-text-primary { background: #001233; }
    .color-text-secondary, .link-navigation-secondary, .color-text-secondary.transparent-muted-button { color: #606E85; }
    .color-text-secondary--important { color: #606E85 !important; }
    .color-bg-text-secondary { background: #606E85; }
    .color-text-muted { color: #929DB0;}
    .color-bg-text-muted { background: #929DB0;}

    .color-primary, .link-primary { color: #0A73EB; }
    .color-bg-primary { background: #0A73EB; }
    .color-border-primary { border-color: #0A73EB; }
    .color-primary-dark, .link-primary:hover { color: #0A73EB; }
    .color-bg-primary-dark { background: #0A73EB; }
    .color-primary-light { color: #E5F7FE; }
    .color-bg-primary-light { background: #E5F7FE; }

    .color-success, .link-success { color: #00BA3F }
    .color-bg-success { background: #00BA3F }
    .color-success-dark, .link-success:hover { color: #0A7723 }
    .color-bg-success-dark { background: #0A7723 }
    .color-success-light { color: #F0FFF3 }
    .color-bg-success-light { background: #F0FFF3 }

    .color-warning, .link-warning { color: #F2A001; }
    .color-bg-warning { background: #F2A001 }
    .color-warning-dark, .link-warning:hover { color: #D08A03 }
    .color-bg-warning-dark { background: #D08A03 }
    .color-warning-light { color: #FFFAF0 }
    .color-bg-warning-light { background: #FFFAF0 }

    .color-danger, .link-danger { color: #D42220 }
    .color-bg-danger { background: #D42220 }
    .color-danger-dark, .link-danger:hover { color: #971110 }
    .color-bg-danger-dark { background: #971110 }
    .color-danger-light { color: #FFF0F1 }
    .color-bg-danger-light { background: #FFF0F1 }

    .color-bg-line-strong { background: #D4D9DD }
    .color-bg-line-light { background: #e8ecef }

    .link-navigation-primary:hover, .link-navigation-secondary:hover { color: #0A73EB; }

    .link-primary:hover, .link-success:hover, .link-warning:hover, .link-danger:hover, .link-navigation-primary:hover, .link-navigation-secondary:hover {
        cursor: pointer;
    }

    .divider {
        width: 100%;
        height: 1px;
        margin: 4px 0;
    }

    .horizontal-line {
        width: 8px;
        height: 1px;
        margin: 0 4px;
    }

    .icon-16 {
        width: 16px;
        height: 16px;
    }

    .icon-20 {
        width: 20px;
        height: 20px;
    }
    
    .hidden-print {
        display: none;
    }

    @media print {
        .no-print {
            display: none;
            
            * {
                display: none !important;
            }
        }
        
        .hidden-print {
            display: block;
        }
    }

    .upload-dnd {
        width: 100%;
        padding: 32px 64px;
        text-align: center;
        border-radius: 4px;
        border: 1px dashed #E5F7FE;
        background: #FAFAFA;
        cursor: pointer;
        transition: all .3s ease-in-out;
    }

    .upload-dnd:hover {
        background: #f5f5f5;
    }

    .upload-dnd-dragged {
        border: 1px solid rgba(234, 222, 252, 0.50);
        background: rgba(234, 222, 252, 0.50);
    }

    .upload-dnd-dragged:hover {
        background: rgba(234, 222, 252, 0.50);
    }

    .header-row {
        font-weight: 500;
        font-size: 12px;
        color: #6b7a94;
    }

    .light-line-border {
        border: 1px solid #495F781F;
    }

    .light-bottom-line-border, .border-stack > *:not(:last-child) {
        border-bottom: 1px solid #495F781F;
    }

    .table-link {
        border-bottom: 1px solid #495F781F;
    }

    .table-link:hover {
        border-bottom-color: #0A73EB;
    }

    .table-link:hover a {
        color: #0A73EB;
    }

    .inline-block-middle {
        display: inline-block;
        vertical-align: middle;
    }

    .pagination-button.pagination-button {
        height: 32px;
        min-width: 32px;
        padding: 0px;
        margin: 0px;
        padding: 0 8px;
    }

    .tabs {
        display: flex;
        line-height: 24px;
        min-height: 40px;
        border-bottom: 1px solid #e9ecef;
        padding: 0 32px;
        width: 100%;
        overflow-x: auto;
    }

    .calendar-indicator {
        position: absolute;
        top: -4px;
        left: 0;
        width: calc(100% + 2px);
        height: 1.25px;
        z-index: 0;
        color: #0A73EB;

        &:has(.calendar-indicator-button:hover) {
            z-index: 10;
        }
    }

    .calendar-indicator-line {
        opacity: 0;
        height: 100%;
        width: 100%;
        background: #0A73EB;
        border-radius: 10px;
    }

    .calendar-indicator-timestamp {
        opacity: 0;
        position: absolute;
        display: inline-block;
        top: 50%;
        transform: translateY(-50%);
        line-height: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 0px 1px;
        border-radius: 4px;
        left: calc(100% + 2px + 15px + 2px);
        white-space: nowrap;
    }

    .calendar-indicator-button {
        position: absolute;
        opacity: 0;
        top: 50%;
        width: 15px;
        height: 15px;
        transform: translateY(-50%);
        left: calc(100% - 2px);
        cursor: pointer;

        & > circle {
            fill: #fff;
        }

        &:hover, &:hover ~ .calendar-indicator-timestamp, &:hover ~ .calendar-indicator-line {
            opacity: 1;
        }
    }

    .calendar-time-slot-wrapper:hover {
        .calendar-indicator {
            z-index: 10;
        }

        .calendar-indicator-timestamp, .calendar-indicator-button, .calendar-indicator-line {
            opacity: 1;
        }
    }



    .calendar-indicator.calendar-indicator-disabled {
        color: #929DB0;

        .calendar-indicator-button {
            cursor: not-allowed;
        }

        .calendar-indicator-line {
            background: #929DB0;
        }
    }

    .calendar-time-slot-range {
        border-left-style: solid;
        border-left-width: 2px;
        padding: 2px 4px;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 0 0 1px #fff;

        &:hover {
            left: 0 !important;
            width: 100% !important;
            z-index: 10 !important;

            & > .calendar-time-slot-range-remove {
                display: block;
            }
        }
    }

    .calendar-time-slot-range-remove {
        display: none;
        right: 2px;
        top: 2px;
        position: absolute;
    }

    .z-1 {
        z-index: 10;
    }
    .grid {
        display: grid;
    }
    .w-full {
        width: 100%;
    }

    // Table component
    .table {
        width: 100%;
        color: #929db0;

        .tr {
            border-bottom: 1px solid rgba(73, 95, 120, 0.12);
        }

        .thead {
            .th {
                white-space: nowrap;
                text-align: left;
                font-weight: 500;
                font-size: 12px;
                padding: 12px 32px;
                color: #6b7a94;
            }
        }

        .tbody {
            .tr {
                &:hover {
                    background: rgba(209, 233, 255, 0.3);
                }

                .td {
                    min-height: 48px;
                    padding: 16px 32px;
                }
            }
        }

        &.table-sm {
            .thead .th {
                padding: 8px 8px;
            }
            
            .tbody .tr .td {
                padding: 8px 8px;
            }
        }
    }

    // report card component
    .data-bar-actual-number {
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .data-bar-card:hover .data-bar-actual-number {
        opacity: 1;
    }

    // progress bar component
    .progress {
        border-radius: 4px;
        height: 8px;
        background-color: #F6F6F6;
        position: relative;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: #0A73EB;
        }
    }

    // border classes
    .border-0 {
        border-width: 0;
    }
    .border-b-1 {
        border-bottom-width: 1px;
    }
    .border-light {
        border-color: #495F781F;
    }
    .border-solid {
        border-style: solid;
    }

    .shrink-0 {
        flex-shrink: 0;
    }

    // mask
    .mask {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        white-space: nowrap;
    }

    .mask.mask-on {
        height: 20px;
        background: #f9f9f9;
        border-radius: 5px;
        color: #929db0;
        cursor: pointer;
        padding: 2px 12px;
        font-size: 12px;
        line-height: 16px;
    }

    .mask.mask-icon.mask-disabled {
        cursor: not-allowed;
    }

    .mask > .mask-icon {
        margin: 0;
        margin-right: 6px;
        font-size: 12px;
        cursor: pointer;
    }
    
    .preview-mode {
        display: flex;
        background: #1E69F6;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 18px;
        margin: 0 -8px;
    }
    
    .skeleton {
        border-radius: 4px;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.15) 37%, rgba(0, 0, 0, 0.06) 63%);
        background-size: 400% 100%;
        animation-name: skeleton-loading;
        animation-duration: 1.4s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
    }
    
    @keyframes skeleton-loading {
        0% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0 50%;
        }
    }
    
    .m-0 {
        margin: 0;
    }
    
    .mb-4 {
        margin-bottom: 16px;
    }
    
    .grow {
        flex-grow: 1;
    }
    
    .gap-2 {
        gap: 8px;
    }
    
    .gap-4 {
        gap: 16px;
    }

    .sr-only {
        display: none;
    }

    .datepicker-clear-button {
        outline: revert !important;
    }

    .visually-hidden {
        border: 0;
        clip: rect(0px, 0px, 0px, 0px);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        white-space: nowrap;
        position: absolute;
    }
    
    .visually-hidden {
        border: 0;
        clip: rect(0px, 0px, 0px, 0px);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        white-space: nowrap;
        position: absolute;
    }
    
    .font-bold {
        font-weight: 700;
    }
`;

export default GlobalStyle;
