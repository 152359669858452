const GAP = 4; // 4px - space between the dropdown/select and the controller element

/**
 * Hook to calculate the position of a dropdown.
 */
export const useWindowPositioner = () => {
    /**
     * Calculate the position of the target element relative to the controller element.
     *
     * @param {Object} controllerRef - Reference to the controller element
     * @param {Object} targetRef - Reference to the target element
     */
    const position = (controllerRef, targetRef) => {
        let newPosition = {
            left: 0,
            top: 0
        };

        if (controllerRef && targetRef && controllerRef.current && targetRef.current) {
            const controllerRect = controllerRef.current.getBoundingClientRect();
            const targetRect = targetRef.current.getBoundingClientRect();

            newPosition = {
                left: controllerRect.left + window.scrollX,
                top: controllerRect.bottom + window.scrollY + GAP
            };

            const isOutRight = newPosition.left + targetRect.width > window.innerWidth;
            const isOutBottom = newPosition.top + targetRect.height > window.innerHeight + window.scrollY;
            const isOutTop = controllerRect.top - targetRect.height < 0;

            // If the dropdown is going to be out of the screen (right), then move it to the left
            if (isOutRight) {
                newPosition.left = newPosition.left + controllerRect.width - targetRect.width;
            }

            // If the dropdown is going to be out of the screen (bottom), then move it to the top
            if (isOutBottom && !isOutTop) {
                newPosition.top = controllerRect.top + window.scrollY - targetRect.height - GAP;
            }
        }

        return newPosition;
    };

    return position;
};

export default useWindowPositioner;
