exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".container__2AVPS {\n    justify-content: space-between;\n    border: 1px solid #495F781F;\n    border-radius: 10px;\n    padding: 16px;\n    margin-bottom: 20px;\n}\n.container__2AVPS .logo__2Uz1Q {\n    width: 32px;\n    height: 32px;\n    align-items: center;\n    justify-content: center;\n}\n.container__2AVPS .logo__2Uz1Q img {\n    width: 22px;\n}\n.container__2AVPS .title__2LqYR {\n    color: #001233;\n    font-size: 18px;\n    line-height: 28px;\n    font-weight: 600;\n    align-items: center;\n    margin-left: 12px;\n}\n.container__2AVPS .total__2RzLa {\n    color: #606E85;\n    font-size: 14px;\n    line-height: 22px;\n    font-weight: 400;\n    align-items: center;\n    margin-left: 12px;\n}\n.container__2AVPS .buttons__1JCv5 {\n    justify-content: end;\n}\n.container__2AVPS .buttons__1JCv5 .buttonAdd__Twc-4,\n.modal__iGpjS .buttons__1JCv5 .buttonReturn__12oFJ {\n    margin-right: 0;\n    margin-left: 12px;\n    padding: 0 16px;\n}\n.container__2AVPS .buttons__1JCv5 button:disabled {\n    opacity: 1;\n    background-color: #e7e8f1;\n}\n.modal__iGpjS {\n    flex-direction: column;\n    align-items: center;\n}\n.modal__iGpjS .icon__1IOif {\n    width: 72px;\n    height: 72px;\n    background: #F1F7FE;\n    justify-content: center;\n    align-items: center;\n    border-radius: 50%;\n    color: #0A73EB;\n    margin-bottom: 24px;\n    font-size: 32px;\n}\n.modal__iGpjS .title__2LqYR {\n    font-size: 24px;\n    line-height: 32px;\n    font-weight: 600;\n    margin-bottom: 24px;\n    text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"container": "container__2AVPS",
	"logo": "logo__2Uz1Q",
	"title": "title__2LqYR",
	"total": "total__2RzLa",
	"buttons": "buttons__1JCv5",
	"buttonAdd": "buttonAdd__Twc-4",
	"modal": "modal__iGpjS",
	"buttonReturn": "buttonReturn__12oFJ",
	"icon": "icon__1IOif"
};