import React from 'react';
import { Box } from 'rebass';
import { Input } from '@rebass/forms';
import { FiSearch } from 'react-icons/fi';

/**
 * Search input component.
 *
 * @param {Omit<React.InputHTMLAttributes<HTMLInputElement>>} props
 * @param {React.Ref<HTMLInputElement>} ref
 */
export default React.forwardRef(
    /**
     * @param {Omit<React.InputHTMLAttributes<HTMLInputElement>, 'placeholder' | 'autoFocus' | 'displayHidden'>} props
     * @param {string} [placeholder]
     * @param {boolean} [autoFocus]
     * @param {boolean} [displayHidden]
     * @param {React.Ref<HTMLInputElement>} ref
     */
    ({ displayHidden, autoFocus, placeholder, ...props }, ref) => (
        <Box style={{ position: 'relative', height: '32px', display: displayHidden ? 'none' : 'block' }}>
            <Input
                ref={ref}
                {...props}
                style={{ padding: '6px 0px 6px 36px' }}
                type="text"
                className="input-darker"
                placeholder={placeholder && 'Search'}
            />
            <FiSearch
                className="text-primary"
                style={{
                    position: 'absolute',
                    left: '12px',
                    top: '8px',
                    strokeWidth: '1.5px',
                    fontSize: '16px'
                }}
            />
        </Box>
    )
);
