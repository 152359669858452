import React from 'react';
import styled from 'styled-components';
import { Flex, Box, Image } from 'rebass';
import { FiCalendar, FiEdit, FiCheckCircle } from 'react-icons/fi';
import helpers from 'utils/helpers';

const Container = styled(Box)`
    width: 100%;
    border-radius: 8px;
`;

const StatusContainer = styled(Flex)`
    width: 100%;
    height: 48px;
    border-radius: 8px 8px 0 0;
    justify-content: center;
    align-items: center;
    gap: 12px;
`;

const MainContainer = styled(Box)`
    max-width: 560px;
    width: 94%;
    @media (max-width: 374px) {
        width: 96%;
    }
`;

const ChildrenContainer = styled(Flex)`
    width: 100%;
    padding: 40px;
    flex-direction: column;
    gap: 32px;
    @media (max-width: 520px) {
        padding: 40px 16px;
    }
`;

const PreviewIndicator = styled(Flex)`
    max-width: 560px;
    width: 94%;
    height: 40px;
    border-radius: 0 0 8px 8px;
    justify-content: center;
    align-items: center;
    @media (max-width: 374px) {
        width: 96%;
    }
`;

const SchedulingPageWrapper = props => {
    const Status = () => {
        switch (props.status) {
            case 'session-new':
                return (
                    <StatusContainer className="fs-title-16 color-dark color-bg-warning">
                        <FiCalendar className="icon-20" />
                        <h1 className="fs-title-16 color-dark" style={{ margin: 0 }}>
                            Schedule new session
                        </h1>
                    </StatusContainer>
                );
            case 'session-reschedule':
                return (
                    <StatusContainer className="fs-title-16 color-dark color-bg-warning">
                        <FiEdit className="icon-20" />
                        <h1 className="fs-title-16 color-dark" style={{ margin: 0 }}>
                            Reschedule session
                        </h1>
                    </StatusContainer>
                );
            case 'session-scheduled':
                return (
                    <StatusContainer className="fs-title-16 color-dark color-bg-success">
                        <FiCheckCircle className="icon-20" />
                        <h1 className="fs-title-16 color-dark" style={{ margin: 0 }}>
                            Session scheduled
                        </h1>
                    </StatusContainer>
                );
            default:
                return null;
        }
    };

    return (
        <Flex
            width="100%"
            minHeight="100vh"
            backgroundColor="#F6F6F6"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
        >
            <Flex minHeight="80px" width="100%" justifyContent="center">
                {props.isPreview && (
                    <PreviewIndicator className="fs-title-14 color-primary-dark color-bg-primary-light">
                        Preview
                    </PreviewIndicator>
                )}
            </Flex>
            <MainContainer>
                {props.logo && (
                    <Flex width="100%" justifyContent="center" mb="40px">
                        <Image src={props.logo} alt={`${props.account.title} logo`} width="144px" />
                    </Flex>
                )}
                <Container className="nice-boxshadow-light color-bg-light">
                    <Status />
                    <ChildrenContainer>{props.children}</ChildrenContainer>
                </Container>
                {helpers.renderPoweredByPanelfox(props.account)}
            </MainContainer>
            <Box minHeight="60px" width="100%"></Box>
        </Flex>
    );
};

export default SchedulingPageWrapper;
