import React, { useState } from 'react';
import { Box } from 'rebass';
import { Select } from '@rebass/forms';

import { useToastHelper } from 'hooks/useToastHelper';
import useQuery from 'hooks/useQuery';
import services from 'services/services';
import { DSCOUT_MISSION_DELIMITER } from 'utils/constants';

/**
 * Dropdown select component for Dscout Missions.
 *
 * @param {Function} onChange Callback function triggered when the selected option changes.
 */
export function DscoutMissionSelect({ onChange, selectedMissionId }) {
    const { addErrorToast } = useToastHelper();
    const [options, setOptions] = useState();
    const { data: missions, isLoading } = useQuery({
        queryFn: () => {
            return selectedMissionId ? services.getDscoutMission(selectedMissionId) : services.getDscoutMissions();
        },
        onSuccess: response => {
            if (selectedMissionId) {
                const [type, id] = selectedMissionId.split(DSCOUT_MISSION_DELIMITER);
                const projectName = response.mission.project ? response.mission.project.name : 'Unknown project';
                const options = [
                    <option key={id} value={selectedMissionId}>
                        [{projectName}] {response.mission.name} ({type})
                    </option>
                ];
                setOptions(options);
                onChange({ ...response.mission, type });
            } else {
                const options = [<option key={-1}></option>];
                Object.keys(response).forEach(missionType => {
                    response[missionType].forEach(mission => {
                        const projectName = mission.project ? mission.project.name : 'Unknown project';
                        options.push(
                            <option key={mission.id} value={`${missionType}${DSCOUT_MISSION_DELIMITER}${mission.id}`}>
                                [{projectName}] {mission.name} ({missionType})
                            </option>
                        );
                    });
                });
                setOptions(options);
            }
        },
        onError: (_, errorText) => {
            addErrorToast(errorText);
        }
    });

    const onMissionSelect = e => {
        let selectedMission = null;

        const value = e.target.value;
        if (value) {
            const [type, id] = value.split(DSCOUT_MISSION_DELIMITER);
            const mission = missions[type].find(mission => id == mission.id);
            if (mission) {
                selectedMission = { ...mission, type };
            }
        }

        onChange(selectedMission);
    };

    if (isLoading) {
        return (
            <Box px={2} py={1}>
                Loading missions...
            </Box>
        );
    }

    return (
        <Select onChange={onMissionSelect} defaultValue={selectedMissionId}>
            {options}
        </Select>
    );
}

export default DscoutMissionSelect;
