import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import H1 from 'components/H1';
import { Flex, Box, Button } from 'rebass';
import { Label } from '@rebass/forms';
import helpers from 'utils/helpers';
import services from 'services/services';
import service_accounts from 'services/accounts';
import { FiCopy, FiInfo, FiTrash2 } from 'react-icons/fi';
import auth from 'services/auth';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import SettingsPageSubNav from 'containers/SettingsPage/SettingsPageSubNav';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';
import { ActionButton } from 'components/Button/ActionButton';
import NiceModal from 'components/NiceModal';
import NotificationCard from 'components/NotificationCard';

import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import { usePrompt } from 'components/PromptProvider';

const BLANK_TOKEN = { token: {} };

function SettingsDeveloperPage(props) {
    const prompt = usePrompt();

    const [tokens, setTokens] = useState([]);
    const [newToken, setNewToken] = useState(BLANK_TOKEN);
    const [isLoading, setIsLoading] = useState(true);
    const { addToast } = useToasts();

    const authObject = auth.getAuthState();

    useEffect(() => {
        getUserTokens();
    }, []);

    function getUserTokens() {
        service_accounts
            .getPATokens()
            .then(user_patokens => {
                setTokens(user_patokens);
                setIsLoading(false);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function clickCreateToken() {
        prompt.ask('Name this token', {
            _default: '',
            title: 'Create a new token',
            confirmText: 'Create token',
            onConfirm: name => {
                if (name) {
                    service_accounts
                        .createPAToken({ name })
                        .then(newToken => {
                            setNewToken(newToken);
                            getUserTokens();
                            addToast('Token successfully created', {
                                appearance: 'success',
                                autoDismiss: true
                            });
                        })
                        .catch(error => {
                            const errorText = services.parseAndTrackXhrErrors(error);
                            addToast(errorText, {
                                appearance: 'error',
                                autoDismiss: true
                            });
                        });
                } else {
                    addToast('Missing token name, token not created.', {
                        appearance: 'error',
                        autoDismiss: true
                    });
                }
            }
        });
    }

    function clickDeleteToken(tokenId) {
        if (confirm('Are you sure you want to delete this token?')) {
            service_accounts
                .deletePAToken(tokenId)
                .then(() => {
                    getUserTokens();
                    addToast('Successfully deleted', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);
                    addToast(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    function renderTokens() {
        let elements = [];
        tokens.forEach(token => {
            elements.push(
                <ItemWrapper className="text-primary">
                    <Box width={8 / 16} style={{ marginTop: '6px', paddingRight: '8px' }}>
                        <b>{token.name}</b>
                        <br />
                        <Box className="ellipsis text-secondary">
                            <small>
                                Token ID: {token.id}
                                <br />
                                Access Token: Hidden
                            </small>
                        </Box>
                    </Box>
                    <Box width={3 / 16}>{token.user_name}</Box>
                    <Box width={2 / 16} style={{ marginTop: '6px' }}>
                        {helpers.daysAgoOrDaysUntil(token.created_at)}
                    </Box>
                    <Box width={2 / 16}>{helpers.daysAgoOrDaysUntil(token.expires_at)}</Box>
                    <Box width={1 / 16}>
                        {token.user_id == authObject.user.id && (
                            <Button
                                type="button"
                                variant="secondary-gray"
                                className="secondary-icon"
                                onClick={() => clickDeleteToken(token.id)}
                            >
                                <FiTrash2 />
                            </Button>
                        )}
                    </Box>
                </ItemWrapper>
            );
        });

        if (elements.length == 0) {
            elements.push(
                <Box fontSize={1} m={3}>
                    0 tokens.
                </Box>
            );
        }

        return elements;
    }

    function onModalClose() {
        setNewToken(BLANK_TOKEN);
    }

    return (
        <Flex style={{ width: '100%' }}>
            <AppPageWrapper>
                <Helmet>
                    <title>Developer</title>
                </Helmet>
                <Flex>
                    <SettingsPageSubNav />
                </Flex>
                <AppPageWrapperSectionHeader className="experience">
                    <Box>
                        <H1>Developer</H1>
                        <Box className="experience-description">Create and manage API tokens.</Box>
                    </Box>
                    <Box mt={2}>
                        <ActionButton title="New Token" action={() => clickCreateToken()} keyLabel="N" mr={0} />
                    </Box>
                </AppPageWrapperSectionHeader>
                <AppPageWrapperSectionBody>
                    <Box mx={4} my={4} width="100%">
                        <ListWrapper
                            style={{
                                position: 'relative',
                                border: '1px solid #eee',
                                borderRadius: '4px'
                            }}
                        >
                            <ItemWrapper className="header-row">
                                <Box width={8 / 16}>Title</Box>
                                <Box width={3 / 16}>Created By</Box>
                                <Box width={2 / 16}>Created</Box>
                                <Box width={2 / 16}>Expires</Box>
                                <Box width={1 / 16}>Actions</Box>
                            </ItemWrapper>
                            {isLoading ? (
                                <Box m={3} style={{ minHeight: '24px' }}>
                                    <LoadingWrapper>
                                        <LoadingIndicator />
                                    </LoadingWrapper>
                                </Box>
                            ) : (
                                renderTokens()
                            )}
                        </ListWrapper>
                    </Box>

                    <NiceModal
                        title={newToken.token.name}
                        isOpen={newToken && newToken.accessToken}
                        shouldCloseOnOverlayClick={false}
                        shouldCloseOnEsc={false}
                        onRequestClose={onModalClose}
                        style={{ content: { width: '640px' } }}
                    >
                        <NotificationCard
                            type="warn"
                            title="Copy the Access Token now, it will never be shown again."
                            variant="dark"
                            icon={<FiInfo />}
                        />
                        <Box mb="20px" mt="20px">
                            <Label htmlFor="tokenId">Token ID</Label>
                            <Box padding="6px 10px" className="light-line-border rounded" id="tokenId">
                                {newToken.token.id}
                            </Box>
                        </Box>
                        <Box mb="20px">
                            <Label htmlFor="accessToken">Access Token</Label>
                            <Box as="p" padding="6px 10px" className="light-line-border rounded" id="accessToken">
                                {newToken.accessToken}
                            </Box>
                        </Box>
                        <Box className="modal-actions modal-actions-sticky">
                            <Button variant="secondary-gray" mr={3} onClick={onModalClose}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                sx={{ marginRight: 0 }}
                                onClick={() => {
                                    helpers.copyToClipboard(newToken.accessToken);
                                    addToast('Copied to clipboard', {
                                        appearance: 'success',
                                        autoDismiss: true
                                    });
                                }}
                            >
                                <FiCopy /> Copy access token
                            </Button>
                        </Box>
                    </NiceModal>
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
        </Flex>
    );
}

export default SettingsDeveloperPage;
