import React, { useState, useRef, useMemo } from 'react';
import { Button } from 'rebass';
import { FiPlusCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';
import classNames from 'classnames';

import services from 'services/services';
import helpers from 'utils/helpers';
import {
    EMAIL_TEMPLATE_TYPE,
    MERGE_TAG_PANELIST_UUID,
    MERGE_TAG_SESSION_DURATION,
    MERGE_TAG_STUDY_INCENTIVE,
    MERGE_TAG_STUDY_LOCATION,
    MERGE_TAG_STUDY_SESSION,
    MERGE_TAG_STUDY_TITLE,
    MERGE_TAG_STUDYPANELIST_ID,
    MERGE_TAG_UNSUBSCRIBE_LINK,
    MERGE_TAG_UNSUBSCRIBE_LINK_LABEL
} from 'utils/constants';
import useQuery from 'hooks/useQuery';
import { PositionerV2 } from 'components/Positioner';
import NiceDropdown from 'components/NiceDropdown';
import Portal from 'components/Portal';

import styles from './styles.module.css';

const studyOptions = [
    { label: MERGE_TAG_STUDYPANELIST_ID, value: MERGE_TAG_STUDYPANELIST_ID },
    { label: MERGE_TAG_STUDY_SESSION, value: MERGE_TAG_STUDY_SESSION },
    { label: MERGE_TAG_SESSION_DURATION, value: MERGE_TAG_SESSION_DURATION },
    { label: MERGE_TAG_STUDY_LOCATION, value: MERGE_TAG_STUDY_LOCATION },
    { label: MERGE_TAG_STUDY_INCENTIVE, value: MERGE_TAG_STUDY_INCENTIVE },
    { label: MERGE_TAG_STUDY_TITLE, value: MERGE_TAG_STUDY_TITLE }
];
const additionalPanelistOptions = [{ label: MERGE_TAG_PANELIST_UUID, value: MERGE_TAG_PANELIST_UUID }];
const htmlOptions = [{ label: MERGE_TAG_UNSUBSCRIBE_LINK_LABEL, value: MERGE_TAG_UNSUBSCRIBE_LINK }];

/**
 * @typedef {{ custom_sp_attributes: { title: string, id: string }[] }} MergeTagsStudy
 */

/**
 * Get merge tags options basing on several conditions like study properties, custom study attributes, etc.
 *
 * @param {MergeTagsStudy} [study]
 * @param {EMAIL_TEMPLATE_TYPE} [templateType]
 */
const useMergeTags = (study, templateType) => {
    const { data, isLoading } = useQuery({
        queryFn: () => services.getCustomDataColumnsXHR(),
        onError: error => {
            services.parseAndTrackXhrErrors(error);
        }
    });
    const options = useMemo(() => {
        if (isLoading) return [];

        const columnOptions = data.map(column => ({ label: column.title, value: column.title }));

        let options = [
            { label: 'Panelist Properties', value: 'panelist-properties-section', disabled: true },
            ...additionalPanelistOptions,
            ...columnOptions,
            { label: 'Study Properties', value: 'study-properties-section', disabled: true },
            ...studyOptions
        ];

        if (study && study.custom_sp_attributes.length) {
            options = options.concat(
                [
                    {
                        label: 'Custom Study Attributes',
                        value: 'custom-study-properties-section',
                        disabled: true
                    }
                ],
                study.custom_sp_attributes.map(attr => ({ label: attr.title, value: `StudyAttribute:${attr.id}` }))
            );
        }

        if (templateType === EMAIL_TEMPLATE_TYPE.HTML) {
            options = options.concat(
                [{ label: 'HTML Tags', value: 'html-properties-section', disabled: true }],
                htmlOptions
            );
        }

        return options;
    }, [data, isLoading, study, templateType]);

    return { isLoading, options };
};

/**
 * Displays a dropdown with personalised tokens (merge tags) that can be copied to the clipboard.
 *
 * @param {MergeTagsStudy} [study] The study object.
 * @param {EMAIL_TEMPLATE_TYPE} [templateType] The template type. The context in which the dropdown is used.
 * @param {React.CSSProperties} [style] Additional styles.
 * @param {string} [className] Additional class names.
 */
export function MergeTagsDropdown({ study, templateType, style, className }) {
    const anchorRef = useRef();
    const { options, isLoading } = useMergeTags(study, templateType);
    const [isOpen, setIsOpen] = useState(false);
    const { addToast } = useToasts();

    /**
     * Convert the value to a merge tag format.
     *
     * @param {string} value The value to convert.
     */
    const valueToMergeTag = value => `{${value}}`;

    /**
     * Copy the merge tag to the clipboard and display a success toast.
     *
     * @param {string} value The value to copy.
     */
    const copyMergeTag = value => {
        const mergeTag = valueToMergeTag(value);
        helpers.copyToClipboard(mergeTag);
        addToast(`${mergeTag} copied to clipboard`, {
            appearance: 'success',
            autoDismiss: true
        });
        setIsOpen(false);
    };

    return (
        <>
            <Button
                variant="primary-transparent"
                disabled={isLoading}
                type="button"
                style={style}
                className={classNames(styles.anchorButton, className)}
                onClick={() => setIsOpen(true)}
                ref={anchorRef}
            >
                <FiPlusCircle />
                Merge tags
            </Button>
            <Portal isOpen={isOpen}>
                <PositionerV2 anchorRef={anchorRef}>
                    <NiceDropdown
                        style={{ inset: 'auto', position: 'static' }}
                        onClose={() => setIsOpen(false)}
                        showSearch
                        searchAutoFocus={false}
                        onChange={value => copyMergeTag(value)}
                        items={options.map(option => ({
                            id: option.value,
                            title: option.label,
                            disabled: option.disabled
                        }))}
                    />
                </PositionerV2>
            </Portal>
        </>
    );
}
