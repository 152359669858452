import React from 'react';
import ReactModal from 'react-modal';
import { Flex, Box, Heading, Button } from 'rebass';
import { FiX } from 'react-icons/fi';

export default ({ overflow, style, title, children, containerStyle, aria, ...props }) => {
    const styles = { content: {}, ...style };
    styles.content.overflow = overflow === 'visible' ? 'visible' : 'hidden';
    const headingId =
        typeof title === 'string' && title.trim() !== ''
            ? `${title.replace(/\s+/g, '-').toLowerCase()}-modal-title`
            : 'modal-title';

    return (
        <ReactModal role="dialog" aria={{ modal: 'true', labelledby: headingId, ...aria }} style={styles} {...props}>
            <Box sx={{ overflow: overflow ? overflow : 'auto' }} className="panelfox-react-modal">
                <Flex
                    sx={{
                        justifyContent: 'space-between',
                        position: 'sticky',
                        top: 0,
                        background: '#fff',
                        zIndex: 5,
                        borderRadius: '8px'
                    }}
                >
                    {title ? (
                        <Heading id={headingId} fontSize={2} sx={{ margin: '32px 0 24px 32px', fontWeight: 600 }}>
                            {title}
                        </Heading>
                    ) : (
                        <div></div>
                    )}
                    <Button
                        variant="transparent-icon"
                        style={{ margin: '16px 16px 0 32px' }}
                        onClick={props.onRequestClose}
                        aria-label="Close"
                    >
                        <FiX aria-hidden="true" />
                        <span className="sr-only">Close</span>
                    </Button>
                </Flex>
                <Box mx={4} mb={4} mt={0} sx={{ position: 'relative' }} style={containerStyle}>
                    {children}
                </Box>
            </Box>
        </ReactModal>
    );
};
