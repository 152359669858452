import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import { Box } from 'rebass';

/**
 * @fileoverview react-star-rating
 * @author @cameronjroe
 * <StarRating
 *   name={string} - name for form input (required)
 *   caption={string} - caption for rating (optional)
 *   ratingAmount={number} - the rating amount (required, default: 5)
 *   rating={number} - a set rating between the rating amount (optional)
 *   disabled={boolean} - whether to disable the rating from being selected (optional)
 *   editing={boolean} - whether the rating is explicitly in editing mode (optional)
 *   size={string} - size of stars (optional)
 *   onRatingClick={function} - a handler function that gets called onClick of the rating (optional)
 *   />
 */

export default class StarRating extends React.Component {
    constructor(props) {
        super(props);

        this.min = 0;
        this.max = props.ratingAmount || 5;

        const ratingVal = props.rating || 0;
        const ratingCache = {
            pos: this.getStarRatingPosition(ratingVal),
            rating: ratingVal
        };

        this.state = {
            ratingCache,
            editing: props.editing || !props.rating,
            rating: ratingVal,
            pos: ratingCache.pos,
            glyph: this.getStars()
        };
    }

    getStars() {
        return Array.from({ length: this.props.ratingAmount }, () => '\u2605').join('');
    }

    componentDidMount() {
        this.root = ReactDOM.findDOMNode(this.rootNode);
        this.ratingContainer = ReactDOM.findDOMNode(this.node);
    }

    componentWillUnmount() {
        delete this.root;
        delete this.ratingContainer;
    }

    getWidthFromValue(val) {
        return Math.min(Math.max((val / this.max) * 100, 0), 100);
    }

    getStarRatingPosition(val) {
        return `${this.getWidthFromValue(val)}%`;
    }

    snapToStep(value) {
        const step = this.props.step || 0.5;
        return Math.round(value / step) * step;
    }

    handleMouseMove(e) {
        if (this.props.disabled) return;

        const pos = e.pageX - this.root.getBoundingClientRect().left;
        const maxWidth = this.ratingContainer.offsetWidth;
        const value = (pos / maxWidth) * this.max;
        const snappedValue = this.snapToStep(value);

        this.setState({
            rating: snappedValue,
            pos: this.getStarRatingPosition(snappedValue)
        });
    }

    handleMouseLeave() {
        this.setState({
            rating: this.state.ratingCache.rating,
            pos: this.state.ratingCache.pos
        });
    }

    handleClick(e) {
        if (this.props.disabled) return;

        const ratingCache = {
            pos: this.state.pos,
            rating: this.state.rating
        };

        this.setState({ ratingCache });
        this.props.onRatingClick(e, ratingCache);
    }

    handleKeyDown(e) {
        if (this.props.disabled) return;

        let newRating = this.state.rating;

        switch (e.key) {
            case 'ArrowLeft':
                newRating = Math.max(this.min, this.snapToStep(newRating - this.props.step));
                break;
            case 'ArrowRight':
                newRating = Math.min(this.max, this.snapToStep(newRating + this.props.step));
                break;
            case 'Enter':
            case ' ':
                this.props.onRatingClick && this.props.onRatingClick(e, { rating: newRating });
                return;
            default:
                return;
        }

        this.setState({
            rating: newRating,
            pos: this.getStarRatingPosition(newRating)
        });
    }

    render() {
        const classes = cx({
            'react-star-rating__root': true,
            'rating-disabled': this.props.disabled,
            [`react-star-rating__size--${this.props.size}`]: this.props.size,
            'rating-editing': this.state.editing
        });

        const starRating = (
            <div
                ref={c => (this.node = c)}
                className="rating-container rating-gly-star"
                data-content={this.state.glyph}
                onMouseMove={e => this.handleMouseMove(e)}
                onMouseLeave={() => this.handleMouseLeave()}
                onClick={e => this.handleClick(e)}
            >
                <div className="rating-stars" aria-hidden="true" style={{ width: this.state.pos }}>
                    {this.state.glyph}
                </div>
            </div>
        );

        return (
            <span className="react-star-rating">
                <span
                    ref={c => (this.rootNode = c)}
                    style={{ cursor: 'pointer' }}
                    className={classes}
                    tabIndex="0"
                    role="slider"
                    aria-valuenow={this.state.rating}
                    aria-valuemin={this.min}
                    aria-valuemax={this.max}
                    aria-disabled={this.props.disabled}
                    aria-valuetext={`Rating: ${this.state.rating} out of ${this.max}`}
                    aria-labelledby={this.props.labelledby}
                    aria-describedby="rating-instructions"
                    onKeyDown={e => this.handleKeyDown(e)}
                >
                    {starRating}
                    <Box fontSize={1}>Your rating: {this.state.rating}</Box>
                    <div id="rating-instructions" className="visually-hidden">
                        Use the left and right arrow keys to decrease or increase the rating.
                    </div>
                </span>
            </span>
        );
    }
}

StarRating.propTypes = {
    name: PropTypes.string.isRequired,
    caption: PropTypes.string,
    ratingAmount: PropTypes.number,
    rating: PropTypes.number,
    onRatingClick: PropTypes.func,
    disabled: PropTypes.bool,
    editing: PropTypes.bool,
    size: PropTypes.string,
    step: PropTypes.number
};

StarRating.defaultProps = {
    step: 0.5,
    ratingAmount: 5,
    onRatingClick: () => {},
    disabled: false
};
