import React from 'react';
import moment from 'moment-timezone';
import { Flex, Box } from 'rebass';
import { Label, Radio } from '@rebass/forms';

import useIsMobile from 'hooks/useIsMobile';
import helpers from 'utils/helpers';

const hashCode = string => {
    let hash = 0;
    if (string.length === 0) return hash;

    for (let i = 0; i < string.length; i++) {
        hash = (hash << 5) - hash + string.charCodeAt(i);
        hash = hash & hash;
    }

    return hash;
};

/**
 * List of available spots for a given day
 *
 * @param {string} [ariaLabel]
 * @param {any} props
 */
const SchedulingPageAvailability = ({ ariaLabel, ...props }) => {
    const isMobile = useIsMobile(520);

    if (props.data.days.length === 0) return <div>Unfortunately there are 0 spots available.</div>;

    const client_days = {};

    props.data.days.forEach(day_data => {
        day_data.spots.forEach(spot => {
            const human_date = moment
                .tz(spot.begin_host_tz, props.data.today.timezone)
                .tz(props.timezone)
                .format('MMMM Do, YYYY');
            const day_of_week = moment
                .tz(spot.begin_host_tz, props.data.today.timezone)
                .tz(props.timezone)
                .format('dddd');

            if (!client_days[human_date]) {
                client_days[human_date] = {
                    day_of_week: day_of_week,
                    human_date: human_date,
                    spots: []
                };
            }

            client_days[human_date].spots.push(spot);
        });
    });

    // loop over days based on client timezone
    return (
        <Flex aria-label={ariaLabel} role="radiogroup" style={{ flexDirection: 'column', gap: '16px' }}>
            {Object.keys(client_days).map((date, index) => {
                const day = client_days[date];

                return (
                    <Box key={index}>
                        {index !== 0 && <div className="divider color-bg-line-light" />}
                        <Flex key={index} mt="16px" style={{ flexDirection: isMobile ? 'column' : 'row' }}>
                            <Box width="160px" id={`day-${index}`}>
                                <div className="fs-title-16 color-text-primary" style={{ marginBottom: '4px' }}>
                                    {day.day_of_week}
                                </div>
                                <div className="fs-body-12 color-text-secondary">{day.human_date}</div>
                            </Box>
                            <Flex
                                style={{ flexDirection: 'column', gap: '14px', marginTop: isMobile ? '18px' : '4px' }}
                            >
                                {day.spots.map(spot => {
                                    let duration = 0;

                                    try {
                                        const start = moment(spot.begin);
                                        const end = moment(spot.end);
                                        duration = moment.duration(end.diff(start)).asMinutes();
                                    } catch (error) {
                                        helpers.trackError(error);
                                    }

                                    const spotBeginUTC = moment
                                        .tz(spot.begin, props.data.today.timezone)
                                        .utc()
                                        .format('YYYY-MM-DD HH:mm');
                                    const hash = hashCode(spotBeginUTC);

                                    return (
                                        <Label key={hash} htmlFor={hash} id={`spot-${hash}`}>
                                            <Radio
                                                id={hash}
                                                name="spot"
                                                checked={props.selectedSpotHash === hash}
                                                value={hash}
                                                onChange={() => {
                                                    props.onSelectSpot(hash, spotBeginUTC, duration);
                                                }}
                                                aria-labelledby={`day-${index} spot-${hash}`}
                                            />
                                            {moment
                                                .utc(spotBeginUTC)
                                                .tz(props.timezone)
                                                .format('h:mm A')}
                                            {` (${duration} min)`}
                                        </Label>
                                    );
                                })}
                            </Flex>
                        </Flex>
                    </Box>
                );
            })}
        </Flex>
    );
};

export default SchedulingPageAvailability;
