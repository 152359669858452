import React from 'react';
import TextAreaAutosize from 'react-textarea-autosize';
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { withToastManager } from 'react-toast-notifications';
import NiceDropdown from 'components/NiceDropdown';
import NiceModal from 'components/NiceModal';
import ErrorLabel from 'components/ErrorLabel';
import DynamicOptionList from './dynamic-option-list';
import { get } from './stores/requests';
import ID from './UUID';
import {
    FiExternalLink,
    FiCornerDownRight,
    FiLogIn,
    FiDatabase,
    FiCheckCircle,
    FiGitMerge,
    FiCheck,
    FiSettings,
    FiInfo,
    FiEdit,
    FiX,
    FiTrash2,
    FiCopy,
    FiPlus,
    FiMinusCircle,
    FiImage,
    FiChevronRight,
    FiChevronDown,
    FiAlignLeft,
    FiMoreVertical,
    FiHelpCircle,
    FiUsers,
    FiZap
} from 'react-icons/fi';
import { Button, Link, Box, Flex } from 'rebass';
import { Select, Input, Textarea, Label, Switch } from '@rebass/forms';
import services from 'services/services.js';
import people_service from 'services/people.js';
import { CreatePersonDataKey } from 'components/PersonDataKey';
import AvailableElements from 'components/ReactFormBuilder/config/available-elements.js';
import helpersScreener from 'utils/helpers-screener';
import helpersStudy from 'utils/helpers-study';
import helpers from 'utils/helpers';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { produce } from 'immer';
import 'react-quill/dist/quill.snow.css';
import ReactTooltip from 'react-tooltip';
import myxss from 'components/ReactFormBuilder/form-elements/myxss';
import {
    VALIDATION_TYPE_EMAIL,
    VALIDATION_TYPE_REGEX,
    PERSON_KEY_TYPE_BOOLEAN,
    VALIDATION_TYPE_CONTINUOUS_SUM_MIN_TOTAL,
    VALIDATION_TYPE_CONTINUOUS_SUM_MAX_TOTAL,
    VALIDATION_TYPE_CONTINUOUS_SUM_EXACT_TOTAL,
    VALIDATION_TYPE_MATRIX_TEXT_NUMERIC,
    ANY_ALL_TO_OR_AND,
    OPTION_ORDER_OFF,
    OPTION_ORDER_RADOMIZE,
    OPTION_ORDER_FLIP,
    VALIDATION_EXACT,
    VALIDATION_MAXIMUM,
    VALIDATION_MINIMUM,
    MERGE_CODE_URL_REGEX,
    TWO_WAY_SYNC_INPUT_TYPE_COMPATIBILITY,
    BOOLEAN_FORM_VALUES
} from 'utils/constants';
import SelectModal from 'components/SelectModal';
import SortOrder from './setting-options/sort-order';
import { cloneDeep } from 'lodash';
import xss from 'xss';
import { EditPanelPropertyModal } from 'components/EditPanelPropertyModal';
import RichTextEditorModal from '../Shared/RichTextEditorModal';
import classNames from 'classnames';

let editor_instance = null;
const toolbar = {
    options: ['inline', 'list', 'textAlign', 'fontSize', 'link', 'history'],
    inline: {
        inDropdown: false,
        className: undefined,
        options: ['bold', 'italic', 'underline', 'superscript', 'subscript']
    }
};

const QUESTION_VALIDATION_RULES_DEFAULT = [{}];

class FormElementsEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.element,
            data: this.props.data,
            all_questions: this.props.all_questions, // these are the other questions from all pages
            page_index: this.props.page_index, // page number of current element
            dirty: false,
            availableColumns: [],
            availableColumns_byId: {},
            showModalAddCustomColumn: false,
            showModalWysiwyg: false,
            showConfigurationOptions: false,
            editorData: helpers.makeBlocksFromText('test'),
            allowedQuestionsForPiping: [],
            mergeCodeValidation: true,
            panelProperty: null
        };

        this.quillRef = React.createRef();

        this.openConfigurationOptions = this.openConfigurationOptions.bind(this);
        this.handleImportCustomColumnValues = this.handleImportCustomColumnValues.bind(this);
        this.handleOpenModalAddCustomColumn = this.handleOpenModalAddCustomColumn.bind(this);
        this.handleCloseModalAddCustomColumn = this.handleCloseModalAddCustomColumn.bind(this);
        this.handleAddColumnSuccess = this.handleAddColumnSuccess.bind(this);
        this.editQuestionLogicRulesSetupAction = this.editQuestionLogicRulesSetupAction.bind(this);
        this.editQuestionLogicRulesSetupMatch = this.editQuestionLogicRulesSetupMatch.bind(this);
        this.editElementPropQuestionLogicRules = this.editElementPropQuestionLogicRules.bind(this);
        this.renderQuestionLogicRules = this.renderQuestionLogicRules.bind(this);
        this.renderValidatonRules = this.renderValidatonRules.bind(this);
        this.editQuestionValidationType = this.editQuestionValidationType.bind(this);
        this.editQuestionValidationValue = this.editQuestionValidationValue.bind(this);
        this.editQuestionValidationMessage = this.editQuestionValidationMessage.bind(this);
        this.getConditionalElement = this.getConditionalElement.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.editQuestionLogicRulesConditionalValue = this.editQuestionLogicRulesConditionalValue.bind(this);
        this.editElementPropQuestionValidation = this.editElementPropQuestionValidation.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSubmitInsertPipingIntoOptions = this.onSubmitInsertPipingIntoOptions.bind(this);
        this.onSubmitInsertPipingIntoQuestion = this.onSubmitInsertPipingIntoQuestion.bind(this);
    }
    handleOpenModalAddCustomColumn() {
        this.setState({ showModalAddCustomColumn: true });
    }
    handleCloseModalAddCustomColumn() {
        this.setState({ showModalAddCustomColumn: false });
    }

    handleAddColumnSuccess(customDataColumn) {
        //e.preventDefault();
        this.getCustomDataColumnsXHR(true, (columns, availableColumns_byId) => {
            this.props.panelColumnsChangedCallback(columns, availableColumns_byId);
            this.setState(
                prevState => ({
                    element: { ...prevState.element, personCustomDataId: customDataColumn.id },
                    dirty: true
                }),
                () => {
                    this.updateElement();
                }
            );

            const { toastManager } = this.props;
            toastManager.add('Column successfully added.', {
                appearance: 'success',
                autoDismiss: true
            });
        });
        this.handleCloseModalAddCustomColumn();
    }

    /**
     * Handle panel property edit.
     * Fetch the neweset custom data columns and update the element. At the end, close the modal.
     *
     * @param {Object} customDataColumn
     */
    async handleEditPanelProperty(customDataColumn) {
        const { toastManager } = this.props;

        try {
            this.getCustomDataColumnsXHR(true, (columns, availableColumns_byId) => {
                this.props.panelColumnsChangedCallback(columns, availableColumns_byId);
                this.setState(
                    prevState => ({
                        element: { ...prevState.element, personCustomDataId: customDataColumn.id },
                        dirty: true
                    }),
                    () => {
                        this.updateElement();
                    }
                );
            });

            toastManager.add('Property updated.', {
                appearance: 'success',
                autoDismiss: true
            });
        } catch (error) {
            toastManager.add('Property update failed', {
                appearance: 'error',
                autoDismiss: true
            });
        }

        this.setState({ panelProperty: null });
    }

    getCustomDataColumnsXHR(bustCache, callback = null) {
        const { toastManager } = this.props;
        services
            .getCustomDataColumnsXHR(bustCache)
            .then(columns => {
                let availableColumns_byId = {};
                columns.forEach(c => {
                    availableColumns_byId[c.id] = c;
                });

                this.setState({
                    availableColumns: columns.map(c => ({
                        ...c,
                        suffix: (
                            <Button
                                type="button"
                                variant="link"
                                sx={{ width: '32px', height: '32px', lineHeight: 'normal', textAlign: 'right' }}
                                onClick={e => {
                                    e.stopPropagation();
                                    this.setState({ panelProperty: c });
                                }}
                            >
                                <FiEdit />
                            </Button>
                        ),
                        disabled:
                            TWO_WAY_SYNC_INPUT_TYPE_COMPATIBILITY[this.props.element.element] &&
                            !TWO_WAY_SYNC_INPUT_TYPE_COMPATIBILITY[this.props.element.element].includes(c.type),
                        onDisabledClick: () => {
                            toastManager.add('Element does not match to a two way sync property', {
                                appearance: 'info',
                                autoDismiss: true
                            });
                        }
                    })),
                    availableColumns_byId: availableColumns_byId
                });
                if (callback) {
                    callback(columns, availableColumns_byId);
                }
            })
            .catch(error => {
                let errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    toggleRequired() {
        // const this_element = this.state.element;
    }

    setHiddenValue = urlParam => {
        const this_element = cloneDeep(this.state.element);
        this_element.default_value = this_element.default_value.concat(`{url("${urlParam}")}`);
        this.setState(
            {
                element: this_element,
                dirty: true
            },
            () => {
                this.updateElement();
            }
        );
    };

    isMergeValueValid = input => {
        const count1 = input.match(/\{/g);
        const count2 = input.match(/\}/g);

        if (count1) {
            // missing 2nd bracket
            if (!count2) {
                return false;
            }

            if (count1.length !== count2.length) return false;

            const result = input.match(MERGE_CODE_URL_REGEX);
            if (result) {
                return result.length === count1.length;
            }
            // no merge tag found, error it
            else {
                return false;
            }
        }
        // if there is no count, there's nothing to test, mark as valid
        else {
            return true;
        }
    };

    onBlurDefaultValue = () => {
        if (this.state.element.element === 'HiddenValue') {
            const valid = this.isMergeValueValid(this.state.element.default_value);
            this.setState({ mergeCodeValidation: valid });
        }
        this.updateElement();
    };

    editElementProp(elemProperty, targProperty, e, forceSave) {
        // elemProperty could be content or label
        // targProperty could be value or checked
        const this_element = cloneDeep(this.state.element);

        const new_value = this.doesContainHtml(e.target[targProperty])
            ? myxss.process(e.target[targProperty])
            : e.target[targProperty];
        this_element[elemProperty] = new_value;

        //console.log('prepare validation', elemProperty, this_element);

        // the element has the property 'add_other' which is a boolean
        // the option (within an element) has a property 'is_add_other' which is a boolean

        let options = this_element.options;
        if (elemProperty == 'add_other') {
            const found_existing_add_other = options.find(o => o.is_add_other == true);
            const add_other_value = new_value;

            if (add_other_value) {
                if (!found_existing_add_other) {
                    options.push({
                        text: '',
                        is_add_other: true,
                        key: ID.uuid()
                    });
                    this_element.options = options;
                }
            } else {
                if (found_existing_add_other) {
                    options = options.filter(o => !o.is_add_other);
                    this_element.options = options;
                }
            }
        }

        if (elemProperty == 'none_of_the_above') {
            const found_existing_none_of_the_above = options.find(o => o.is_none_of_the_above == true);
            const none_of_the_above_value = new_value;
            //console.log('none_of_the_above_value', none_of_the_above_value)
            if (none_of_the_above_value) {
                if (!found_existing_none_of_the_above) {
                    options.push({
                        text: '',
                        is_none_of_the_above: true,
                        key: ID.uuid()
                    });
                    this_element.options = options;
                }
            } else {
                if (found_existing_none_of_the_above) {
                    options = options.filter(o => !o.is_none_of_the_above);
                    this_element.options = options;
                }
            }
        }

        if (elemProperty == 'question_logic') {
            //console.log('scrolling')
            // height of question-has-page-logic minus 2px
            window.scrollBy({ left: 0, top: new_value ? 54 : -54, behavior: 'instant' });
        }

        this.setState(
            {
                element: this_element,
                dirty: true
            },
            () => {
                const updateElemProps = [
                    'personCustomDataId',
                    'option_order',
                    'matrix_allow_multiple',
                    'video_recording_time',
                    'upload_files_cnt'
                ];
                if (targProperty === 'checked' || forceSave || updateElemProps.includes(elemProperty)) {
                    console.log('force save form...');
                    this.updateElement();
                } else {
                    console.log('not saving form...');
                }
            }
        );
    }

    onEditorStateChange(index, property, editorContent) {
        const html = draftToHtml(convertToRaw(editorContent.getCurrentContent())); //.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>');
        //const html = draftToHtml(convertToRaw(editorContent.getCurrentContent())).replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/(?:\r\n|\r|\n)/g, ' ');
        const this_element = this.state.element;
        this_element[property] = html;

        this.setState({
            element: this_element,
            dirty: true
        });
    }

    changeElementToNewElement(new_key) {
        const current_item_copy = this.state.element;
        const new_item_blank = AvailableElements.find(item => item.key == new_key);
        const new_element = this.props.create(new_item_blank, current_item_copy);
        new_element.id = current_item_copy.id;
        if (current_item_copy.field_name) {
            new_element.field_name = current_item_copy.field_name;
        }
        //console.log('new_element', new_element)
        const generatedElement = {
            ...new_element,
            element: new_item_blank.key,
            text: new_item_blank.name
        };

        this.setState(
            {
                element: generatedElement,
                dirty: true
            },
            () => {
                this.updateElement();
            }
        );

        //console.log('changeElementToNewElement', new_key, new_item_blank, current_item_copy);
    }

    updateElement() {
        // to prevent ajax calls with no change
        if (this.state.dirty && this.props.updateElement) {
            const this_element = cloneDeep(this.state.element);

            this.props.updateElement(this_element);
            this.setState({ dirty: false });
        }
    }

    onSave() {
        console.log('in onSave!');
        // to prevent ajax calls with no change
        if (this.state.dirty && this.props._onSave) {
            const this_element = cloneDeep(this.state.element);
            console.log('saving!');
            this.props._onSave(this_element);
            this.setState({ dirty: false });
            this.props._onClose();
        }
    }

    convertFromHTML(content) {
        const newContent = convertFromHTML(content);
        if (!newContent.contentBlocks || !newContent.contentBlocks.length) {
            // to prevent crash when no contents in editor
            return EditorState.createEmpty();
        }
        const contentState = ContentState.createFromBlockArray(newContent);
        return EditorState.createWithContent(contentState);
    }

    handleImportCustomColumnValues(customDataId) {
        const { toastManager } = this.props;
        const that = this;

        const foundColumn = this.state.availableColumns.find(ac => ac.id == customDataId);
        if (!foundColumn) {
            toastManager.add('Column not found. Please refresh the page and try again.', {
                appearance: 'error',
                autoDismiss: true
            });
            return;
        }

        try {
            if (foundColumn && foundColumn.definition) {
                const this_element = this.state.element;

                // sept 8, 2021
                // overwrite existing options completely
                // let { options } = this.props.element;

                let options = [];

                let defined_options = [];

                if (foundColumn.type == PERSON_KEY_TYPE_BOOLEAN) {
                    defined_options = [
                        {
                            value: 1,
                            label: 'Yes'
                        },
                        {
                            value: 0,
                            label: 'No'
                        }
                    ];
                } else {
                    defined_options = foundColumn.definition;
                }

                //console.log(defined_options)

                defined_options.forEach(x => {
                    options.push({
                        value: x.value,
                        text: x.label,
                        key: ID.uuid()
                    });

                    /*const found_existing = options.find(o => o.text == x.label);
                    if (found_existing) {
                        console.log('skipping ', found_existing)
                    } else {
                    }*/
                });

                // remove blanks
                //console.log('OPTNZ', options)
                options = options.filter(o => o && o.text);

                this_element['options'] = options;
                this.setState(
                    {
                        element: this_element,
                        dirty: true
                    },
                    () => {
                        this.updateElement();
                    }
                );

                toastManager.add('Successfully found column values', {
                    appearance: 'success',
                    autoDismiss: true
                });
            }
        } catch (e) {
            helpers.trackError(e);

            toastManager.add('There was an error, and it has been reported.', {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    addOptionsAPI() {
        const optionsApiUrl = document.getElementById('optionsApiUrl').value;
        if (optionsApiUrl) {
            get(optionsApiUrl).then(data => {
                this.props.element.options = [];
                const { options } = this.props.element;
                data.forEach(x => {
                    // eslint-disable-next-line no-param-reassign
                    x.key = ID.uuid();
                    options.push(x);
                });
                const this_element = this.state.element;
                this.setState({
                    element: this_element,
                    dirty: true
                });
            });
        }
    }

    removeConditional(index, group_index) {
        const this_element = this.state.element;

        // remove a conditional for a group
        if (group_index !== undefined) {
            // check if group index exists
            if (this_element['question_logic_rules']['conditionals'][group_index]) {
                // check if conditional index exists within group
                if (this_element['question_logic_rules']['conditionals'][group_index]['conditionals'][index]) {
                    this_element['question_logic_rules']['conditionals'][group_index]['conditionals'].splice(index, 1);
                }
            }
        } else {
            // check if index exists
            if (this_element['question_logic_rules']['conditionals'][index]) {
                this_element['question_logic_rules']['conditionals'].splice(index, 1);
            }
        }
        this.editElementPropQuestionLogicRules(this_element['question_logic_rules']);
    }

    addConditional(conditional_group_index) {
        const this_element = this.state.element;

        console.log('conditional_group_index', conditional_group_index);
        // means it is a group
        if (conditional_group_index !== undefined) {
            let conditional_group = this_element['question_logic_rules']['conditionals'][conditional_group_index];
            if (conditional_group) {
                conditional_group['conditionals'].push(helpersScreener.getQuestionLogicConditionalDefault());
                this_element['question_logic_rules']['conditionals'][conditional_group_index] = conditional_group;
            }
        } else {
            this_element['question_logic_rules']['conditionals'].push(
                helpersScreener.getQuestionLogicConditionalDefault()
            );
        }
        this.editElementPropQuestionLogicRules(this_element['question_logic_rules']);
    }

    addConditionalGroup() {
        const is_group = true;
        const this_element = this.state.element;
        this_element['question_logic_rules']['conditionals'].push(
            helpersScreener.getQuestionLogicConditionalDefault(is_group)
        );
        this.editElementPropQuestionLogicRules(this_element['question_logic_rules']);
    }

    removeValidation(index) {
        const this_element = this.state.element;
        if (this_element['validation_rules'][index]) {
            this_element['validation_rules'].splice(index, 1);
        }
        this.editElementPropQuestionValidation(this_element['validation_rules']);
    }

    addValidation(index) {
        const this_element = this.state.element;
        this_element['validation_rules'].splice(index + 1, 0, {});
        this.editElementPropQuestionValidation(this_element['validation_rules']);
    }

    // this is question logic
    getConditionalElement(conditional_index, conditional, group_index) {
        let question_labels_raw = {};
        let that = this;

        const current_question_index_in_page = this.state.all_questions[this.state.page_index].findIndex(
            pel => pel.id == this.state.element.id
        );

        //console.log("current_question_index_in_page", current_question_index_in_page, this.state.element)

        this.state.all_questions.forEach((page_questions, page_index) => {
            if (!question_labels_raw[page_index]) {
                question_labels_raw[page_index] = [];
            }

            page_questions.forEach((pq, pq_index) => {
                //console.log('pages', this.state.page_index, page_index+1)
                // only show question for current or preceding pages
                //console.log(this.state.page_index, page_index, this.state.page_index >= page_index)
                if (this.state.page_index >= page_index) {
                    // dont show questions after current question
                    // dont show logic for question we're adding logic for
                    // also show previous pages
                    if (!pq.static && pq.id != this.state.element.id && !helpersScreener.questionDisabledInLogic(pq)) {
                        //console.log(pq.label)
                        // for current page, get index of the question so you can show preceding questions only
                        let pq_after_current_question = false;
                        if (this.state.page_index == page_index) {
                            const pq_index = this.state.all_questions[this.state.page_index].indexOf(pq);
                            //console.log(pq_index >= current_question_index_in_page, pq_index, current_question_index_in_page);
                            if (pq_index >= current_question_index_in_page) {
                                pq_after_current_question = true;
                            }
                        }
                        if (!pq_after_current_question) {
                            question_labels_raw[page_index].push(pq);
                        }
                        //console.log(question_labels_raw);
                    }
                }
            });
        });

        // blank
        let question_labels = [];
        question_labels.push(<option></option>);
        Object.keys(question_labels_raw).forEach(page_index => {
            if (question_labels_raw[page_index].length) {
                let question_labels_raw_page = [];
                question_labels_raw[page_index].forEach(pq => {
                    // strip html if rich text
                    const label_plain = helpers.removeHtml(pq.label);
                    question_labels_raw_page.push(<option value={pq.id}>{label_plain}</option>);
                });

                question_labels.push(
                    <optgroup label={`Page ${parseInt(page_index) + 1}`}>{question_labels_raw_page}</optgroup>
                );
            }
        });

        let render_return;

        if (!conditional.question_id && conditional.type == 'group') {
            let sub_conditionals = conditional.conditionals.map((sub_conditional, sub_conditional_index) => {
                //console.log('here', sub_conditional, sub_conditional_index);
                return (
                    <>
                        {that.getConditionalElement(sub_conditional_index, sub_conditional, conditional_index)}
                        {conditional.conditionals.length > sub_conditional_index + 1 &&
                            that.renderAndOr(conditional, true)}
                    </>
                );
            });
            render_return = (
                <Flex>
                    <Box className={'rounded question-logic-group'} p={3}>
                        {that.renderRuleAction(conditional, conditional_index)}
                        {sub_conditionals.length > 0 && (
                            <Box className={'question-logic-group'}>{sub_conditionals}</Box>
                        )}
                        <Box mt={2}>{that.renderAddConditionalsButton(conditional_index)}</Box>
                    </Box>
                    <Box ml={3}>{that.renderRemoveConditionalButton(conditional_index)}</Box>
                </Flex>
            );
        } else {
            const question_element = helpersScreener.getQuestionById(this.state.all_questions, conditional.question_id);
            const question_element_type = question_element ? question_element.element : null;

            //console.log('this.state.element.element', this.state.element.element)
            let additional_question_options = false;
            // super special thing for matrix
            if (question_element_type == 'Matrix' && helpersScreener.getMatrixType(question_element) == 'text') {
                additional_question_options = [];
                additional_question_options.push(<option></option>);
                let matrix_options = helpersStudy.generateMatrixAnswerCombinations(question_element.options);
                if (matrix_options) {
                    //console.log(matrix_options);
                    matrix_options.forEach(option => {
                        additional_question_options.push(
                            <option value={option.row_col_key}>{option.row_col_text}</option>
                        );
                    });
                }
            }
            //console.log('additional_question_options', additional_question_options);

            // this is question logic
            render_return = (
                <>
                    <Flex>
                        <Flex
                            className={group_index !== undefined ? 'question-logic-sub-group' : 'question-logic-group'}
                        >
                            {/*conditional.question_id}, {conditional.compare}, {conditional.value*/}
                            <Box>
                                <Select
                                    className="theme-input theme-input-simple"
                                    sx={{ width: '200px' }}
                                    mr={3}
                                    value={conditional.question_id}
                                    onChange={e =>
                                        this.editQuestionLogicRulesConditionalQuestionId(
                                            conditional_index,
                                            e,
                                            group_index
                                        )
                                    }
                                >
                                    {question_labels}
                                </Select>
                            </Box>
                            {additional_question_options !== false && (
                                <Box>
                                    <Select
                                        className="theme-input theme-input-simple"
                                        sx={{ width: '200px' }}
                                        mr={3}
                                        value={conditional.question_id_child}
                                        onChange={e =>
                                            this.editQuestionLogicRulesConditionalQuestionIdChild(
                                                conditional_index,
                                                e,
                                                group_index
                                            )
                                        }
                                    >
                                        {additional_question_options}
                                    </Select>
                                </Box>
                            )}
                            <Box className="condi-text">is</Box>
                            <Box>
                                <Select
                                    className="theme-input theme-input-simple"
                                    sx={{ width: '120px' }}
                                    ml={3}
                                    mr={3}
                                    value={conditional.compare}
                                    onChange={e =>
                                        this.editQuestionLogicRulesConditionalCompare(conditional_index, e, group_index)
                                    }
                                >
                                    {helpersScreener.renderScreenerLogicOptionsByQuestion(question_element)}
                                </Select>
                            </Box>
                            <Box>
                                {helpersScreener.getQuestionValuesForLogic(
                                    this.state.all_questions,
                                    conditional.question_id,
                                    conditional.compare,
                                    conditional.value,
                                    conditional_index,
                                    this.editQuestionLogicRulesConditionalValue,
                                    group_index,
                                    this.state.element.id
                                )}
                            </Box>
                        </Flex>
                        <Flex ml={2}>{this.renderRemoveConditionalButton(conditional_index, group_index)}</Flex>
                    </Flex>
                </>
            );
        }
        return render_return;
    }

    renderRemoveConditionalButton(conditional_index, group_index) {
        return (
            <Button
                variant="transparent-icon"
                className="gray"
                onClick={e => {
                    this.removeConditional(conditional_index, group_index);
                }}
                tabIndex="-1"
            >
                <FiX />
            </Button>
        );
    }

    editQuestionValidationType(e, index) {
        const this_element = this.state.element;
        if (!this_element['validation_rules']) {
            this_element['validation_rules'] = [{}];
        }
        this_element['validation_rules'][index]['validation_type'] = e.target['value'];
        // reset this
        this_element['validation_rules'][index]['validation_value'] = '';
        // reset this
        this_element['validation_rules'][index]['validation_message'] = '';
        this.editElementPropQuestionValidationRules(this_element['validation_rules']);
    }

    editQuestionValidationValue(e, index, validation_type) {
        const { toastManager } = this.props;

        const this_element = this.state.element;
        if (!this_element['validation_rules']) {
            this_element['validation_rules'] = [{}];
        }
        const value = e.target['value'];
        if (validation_type == VALIDATION_TYPE_REGEX && !helpers.isValidRegex(value)) {
            toastManager.add('Regex expression is invalid', {
                appearance: 'error',
                autoDismiss: true
            });
        }
        this_element['validation_rules'][index]['validation_value'] = value;
        this.editElementPropQuestionValidationRules(this_element['validation_rules']);
    }

    editQuestionValidationMessage(e, index) {
        const this_element = this.state.element;
        if (!this_element['validation_rules']) {
            this_element['validation_rules'] = [{}];
        }
        this_element['validation_rules'][index]['validation_message'] = e.target['value'];
        this.editElementPropQuestionValidationRules(this_element['validation_rules']);
    }

    editElementPropQuestionValidationRules(validation_rules) {
        //console.log('!! editElementPropQuestionValidationRules', validation_rules);

        const this_element = this.state.element;
        this_element['validation_rules'] = validation_rules;

        this.setState(
            {
                element: this_element,
                dirty: true
            },
            () => {
                this.updateElement();
            }
        );
    }

    renderValidatonRules(validation_rules) {
        /*
        this_validation_rules = [{
            'validation_type': '',
            'validation_value': '',
            'validation_message': ''
        }];
        */

        let all_rendered = [];

        if (!validation_rules) {
            validation_rules = [{}];
        }

        const validation_types = [];
        validation_types.push({ title: 'Select One', value: '', disabled: true });
        if (this.state.element.element === 'Checkboxes') {
            validation_types.push({ title: 'Minimum answers selected', value: VALIDATION_MINIMUM });
            validation_types.push({ title: 'Exact answers selected', value: VALIDATION_EXACT });
            validation_types.push({ title: 'Maximum answers selected', value: VALIDATION_MAXIMUM });
        } else {
            if (helpersScreener.isElementTextMatrix(this.props.element)) {
                validation_types.push({
                    title: 'Continuous Sum: Min Total',
                    value: VALIDATION_TYPE_CONTINUOUS_SUM_MIN_TOTAL
                });
                validation_types.push({
                    title: 'Continuous Sum: Max Total',
                    value: VALIDATION_TYPE_CONTINUOUS_SUM_MAX_TOTAL
                });
                ``;
                validation_types.push({
                    title: 'Continuous Sum: Exact Total',
                    value: VALIDATION_TYPE_CONTINUOUS_SUM_EXACT_TOTAL
                });
                validation_types.push({ title: 'Require numeric answers', value: VALIDATION_TYPE_MATRIX_TEXT_NUMERIC });
            } else {
                validation_types.push({ title: 'Email', value: VALIDATION_TYPE_EMAIL });
                validation_types.push({ title: 'RegEx', value: VALIDATION_TYPE_REGEX });
            }
        }

        if (validation_rules && validation_rules.length) {
            validation_rules.forEach((vr, index) => {
                const vt = vr.validation_type;

                let render_type = (
                    <Box sx={{ flexShrink: 0 }}>
                        <Select
                            className="theme-input theme-input-simple"
                            sx={{ minWidth: '220px' }}
                            value={vr.validation_type}
                            onChange={e => this.editQuestionValidationType(e, index)}
                        >
                            {validation_types.map(vt => {
                                return (
                                    <option key={vt.value} value={vt.value} disabled={vt.disabled}>
                                        {vt.title}
                                    </option>
                                );
                            })}
                        </Select>
                    </Box>
                );

                let render_values = [];
                let render_message = [];
                let render_add_remove = [];

                if (vt == VALIDATION_TYPE_REGEX) {
                    let regexValidClassName = helpers.isValidRegex(vr.validation_value) ? 'valid' : 'error';
                    //console.log('regexValidClassName', regexValidClassName)
                    render_values = (
                        <Input
                            value={vr.validation_value}
                            className={`theme-input theme-input-simple ${regexValidClassName}`}
                            placeholder="Pattern"
                            onChange={e => this.editQuestionValidationValue(e, index, vt)}
                        />
                    );
                    render_message = (
                        <Input
                            value={vr.validation_message}
                            className="theme-input theme-input-simple"
                            placeholder="Custom error text"
                            onChange={e => this.editQuestionValidationMessage(e, index)}
                        />
                    );
                } else if (
                    vt == VALIDATION_TYPE_CONTINUOUS_SUM_MIN_TOTAL ||
                    vt == VALIDATION_TYPE_CONTINUOUS_SUM_MAX_TOTAL ||
                    vt == VALIDATION_TYPE_CONTINUOUS_SUM_EXACT_TOTAL
                ) {
                    const total_case =
                        vt == VALIDATION_TYPE_CONTINUOUS_SUM_MIN_TOTAL
                            ? 'Min Total'
                            : vt == VALIDATION_TYPE_CONTINUOUS_SUM_MAX_TOTAL
                            ? 'Max Total'
                            : 'Exact Total';

                    render_values = (
                        <Input
                            type="number"
                            value={vr.validation_value}
                            className={`theme-input theme-input-simple`}
                            placeholder={total_case}
                            onChange={e => this.editQuestionValidationValue(e, index, vt)}
                        />
                    );
                    render_message = (
                        <Input
                            value={vr.validation_message}
                            className="theme-input theme-input-simple"
                            placeholder="Custom error text"
                            onChange={e => this.editQuestionValidationMessage(e, index)}
                        />
                    );
                } else if ([VALIDATION_MINIMUM, VALIDATION_MAXIMUM, VALIDATION_EXACT].includes(vt)) {
                    const optionsCount = this.optionsCount;
                    render_values = (
                        <Input
                            type="number"
                            min="0"
                            max={optionsCount}
                            value={vr.validation_value}
                            className={`theme-input theme-input-simple`}
                            placeholder="Number"
                            onChange={e => {
                                const newValue = +e.target.value;

                                // prevent negative numbers and numbers greater than the number of options
                                if (newValue < 0 || newValue > optionsCount) return;

                                this.editQuestionValidationValue(e, index, vt);
                            }}
                            style={{ width: '30%' }}
                        />
                    );
                }

                render_add_remove.push(
                    <Button
                        variant="transparent-icon"
                        className="gray"
                        onClick={e => {
                            this.addValidation(index);
                        }}
                        tabIndex="-1"
                        mr={0}
                    >
                        <FiPlus />
                    </Button>
                );

                if (index > 0 || validation_rules.length >= 2) {
                    render_add_remove.push(
                        <Button
                            variant="transparent-icon"
                            className="gray"
                            onClick={e => {
                                this.removeValidation(index);
                            }}
                            tabIndex="-1"
                        >
                            <FiX />
                        </Button>
                    );
                }

                all_rendered.push(
                    <Flex mb={3} sx={{ gridGap: 3 }}>
                        {render_type}
                        {render_values}
                        {render_message}
                        {render_add_remove}
                    </Flex>
                );
            });
        }

        return all_rendered;
    }

    renderQuestionLogicRules(question_logic_rules) {
        //this_question_logic_rules.setup = {}
        // setup.action = show/hide
        // setup.match = any/all

        //this_question_logic_rules.conditionals = []
        // conditional.question_id
        // conditional.compare
        // conditional.value

        if (!question_logic_rules) {
            question_logic_rules = helpersScreener.getQuestionLogicRulesDefault();
        }

        let render_conditionals = [];

        if (question_logic_rules.conditionals.length == 0) {
        } else {
            question_logic_rules.conditionals.forEach((conditional, index) => {
                render_conditionals.push(
                    <>
                        {this.getConditionalElement(index, conditional)}
                        {question_logic_rules.conditionals.length > index + 1 && this.renderAndOr(question_logic_rules)}
                    </>
                );
            });
        }

        return (
            <Box mb={3}>
                {this.renderRuleAction(question_logic_rules)}
                <Box>{render_conditionals}</Box>
                <Flex mt={2}>
                    <Box>{this.renderAddConditionalsButton()}</Box>
                    <Box ml={3}>{this.renderAddConditionalGroupButton()}</Box>
                </Flex>
            </Box>
        );
    }

    renderAndOr(question_logic_rules, is_group) {
        return (
            <span
                style={{
                    margin: '0 0 0 16px',
                    display: 'inline-block',
                    padding: '4px 8px',
                    color: is_group ? '#666' : '#999',
                    background: is_group ? '#ddd' : '#eee'
                }}
            >
                {ANY_ALL_TO_OR_AND[question_logic_rules.setup.match]}
            </span>
        );
    }

    renderRuleAction(question_logic_rules, group_conditional_index) {
        if (question_logic_rules && question_logic_rules.setup) {
            let is_group = false;
            if (group_conditional_index !== undefined) {
                is_group = true;
            }

            return (
                <Flex mb={2}>
                    {is_group == false ? (
                        <>
                            <Select
                                className="theme-input theme-input-simple"
                                sx={{ width: '100px' }}
                                mr={3}
                                value={question_logic_rules.setup.action}
                                onChange={this.editQuestionLogicRulesSetupAction}
                            >
                                <option value="show">Show</option>
                                <option value="hide">Hide</option>
                            </Select>
                            <Box className="condi-text" mr={3}>
                                this question if
                            </Box>
                        </>
                    ) : (
                        <Box className="condi-text" mr={3}>
                            If
                        </Box>
                    )}
                    <Select
                        className="theme-input theme-input-simple"
                        sx={{ width: '180px' }}
                        mr={3}
                        value={question_logic_rules.setup.match}
                        onChange={e => {
                            this.editQuestionLogicRulesSetupMatch(e, group_conditional_index);
                        }}
                    >
                        <option value="any">any (or)</option>
                        <option value="all">all (and)</option>
                    </Select>
                    <Box className="condi-text">{is_group == true && 'in this group '}match:</Box>
                </Flex>
            );
        }
    }

    renderAddConditionalsButton(conditional_group_index) {
        return (
            <Button
                variant="link"
                className="fs-13"
                onClick={e => {
                    this.addConditional(conditional_group_index);
                }}
                tabIndex="-1"
            >
                <FiPlus style={{ marginTop: '3px' }} /> Add condition{' '}
                {conditional_group_index !== undefined && ' in group'}
            </Button>
        );
    }

    renderAddConditionalGroupButton() {
        return (
            <Button
                variant="link"
                className="fs-13"
                onClick={e => {
                    this.addConditionalGroup();
                }}
                tabIndex="-1"
            >
                <FiPlus style={{ marginTop: '3px' }} /> Add group
            </Button>
        );
    }

    editQuestionLogicRulesConditionalValue(conditionalIndex, e, group_index) {
        const this_element = this.state.element;
        if (!this_element['question_logic_rules']) {
            this_element['question_logic_rules'] = helpersScreener.getQuestionLogicRulesDefault();
        }

        if (group_index !== undefined) {
            if (this_element['question_logic_rules']['conditionals'][group_index]) {
                if (
                    this_element['question_logic_rules']['conditionals'][group_index]['conditionals'][conditionalIndex]
                ) {
                    this_element['question_logic_rules']['conditionals'][group_index]['conditionals'][conditionalIndex][
                        'value'
                    ] = e.target['value'];
                }
            }
        } else {
            if (this_element['question_logic_rules']['conditionals'][conditionalIndex]) {
                this_element['question_logic_rules']['conditionals'][conditionalIndex]['value'] = e.target['value'];
            }
        }

        this.editElementPropQuestionLogicRules(this_element['question_logic_rules']);
    }

    editQuestionLogicRulesConditionalCompare(conditionalIndex, e, group_index) {
        const this_element = this.state.element;
        if (!this_element['question_logic_rules']) {
            this_element['question_logic_rules'] = helpersScreener.getQuestionLogicRulesDefault();
        }

        if (group_index !== undefined) {
            if (this_element['question_logic_rules']['conditionals'][group_index]) {
                if (
                    this_element['question_logic_rules']['conditionals'][group_index]['conditionals'][conditionalIndex]
                ) {
                    this_element['question_logic_rules']['conditionals'][group_index]['conditionals'][conditionalIndex][
                        'compare'
                    ] = e.target['value'];
                }
            }
        } else {
            if (this_element['question_logic_rules']['conditionals'][conditionalIndex]) {
                this_element['question_logic_rules']['conditionals'][conditionalIndex]['compare'] = e.target['value'];
            }
        }

        this.editElementPropQuestionLogicRules(this_element['question_logic_rules']);
    }

    editQuestionLogicRulesConditionalQuestionIdChild(conditionalIndex, e, group_index) {
        const this_element = this.state.element;
        if (!this_element['question_logic_rules']) {
            this_element['question_logic_rules'] = helpersScreener.getQuestionLogicRulesDefault();
        }
        if (group_index !== undefined) {
            if (this_element['question_logic_rules']['conditionals'][group_index]) {
                if (
                    this_element['question_logic_rules']['conditionals'][group_index]['conditionals'][conditionalIndex]
                ) {
                    this_element['question_logic_rules']['conditionals'][group_index]['conditionals'][conditionalIndex][
                        'question_id_child'
                    ] = e.target['value'];
                }
            }
        } else {
            if (this_element['question_logic_rules']['conditionals'][conditionalIndex]) {
                this_element['question_logic_rules']['conditionals'][conditionalIndex]['question_id_child'] =
                    e.target['value'];
            }
        }

        this.editElementPropQuestionLogicRules(this_element['question_logic_rules']);
    }

    editQuestionLogicRulesConditionalQuestionId(conditionalIndex, e, group_index) {
        const this_element = this.state.element;
        if (!this_element['question_logic_rules']) {
            this_element['question_logic_rules'] = helpersScreener.getQuestionLogicRulesDefault();
        }

        if (group_index !== undefined) {
            if (this_element['question_logic_rules']['conditionals'][group_index]) {
                if (
                    this_element['question_logic_rules']['conditionals'][group_index]['conditionals'][conditionalIndex]
                ) {
                    this_element['question_logic_rules']['conditionals'][group_index]['conditionals'][conditionalIndex][
                        'question_id'
                    ] = e.target['value'];
                }
            }
        } else {
            if (this_element['question_logic_rules']['conditionals'][conditionalIndex]) {
                this_element['question_logic_rules']['conditionals'][conditionalIndex]['question_id'] =
                    e.target['value'];
            }
        }

        this.editElementPropQuestionLogicRules(this_element['question_logic_rules']);
    }

    editQuestionLogicRulesSetupAction(e) {
        const this_element = this.state.element;
        if (!this_element['question_logic_rules']) {
            this_element['question_logic_rules'] = helpersScreener.getQuestionLogicRulesDefault();
        }
        this_element['question_logic_rules']['setup']['action'] = e.target['value'];
        this.editElementPropQuestionLogicRules(this_element['question_logic_rules']);
    }

    editQuestionLogicRulesSetupMatch(e, group_conditional_index) {
        const this_element = this.state.element;
        if (!this_element['question_logic_rules']) {
            this_element['question_logic_rules'] = helpersScreener.getQuestionLogicRulesDefault();
        }
        if (group_conditional_index !== undefined) {
            let g_c = this_element['question_logic_rules']['conditionals'][group_conditional_index];
            if (g_c) {
                this_element['question_logic_rules']['conditionals'][group_conditional_index]['setup']['match'] =
                    e.target['value'];
            }
        } else {
            this_element['question_logic_rules']['setup']['match'] = e.target['value'];
        }

        this.editElementPropQuestionLogicRules(this_element['question_logic_rules']);
    }

    editElementPropQuestionLogicRules(question_logic_rules) {
        const this_element = this.state.element;
        this_element['question_logic_rules'] = question_logic_rules;

        this.setState(
            {
                element: this_element,
                dirty: true
            },
            () => {
                this.updateElement();
            }
        );
    }

    editElementPropQuestionValidation(validation_rules) {
        const this_element = this.state.element;
        this_element['validation_rules'] = validation_rules;

        this.setState(
            {
                element: this_element,
                dirty: true
            },
            () => {
                this.updateElement();
            }
        );
    }

    componentDidMount() {
        this.getCustomDataColumnsXHR();

        // Check merge code validation at first render
        if (this.state.element.element === 'HiddenValue') {
            const valid = this.isMergeValueValid(this.state.element.default_value);
            this.setState({ mergeCodeValidation: valid });
        }
    }

    handleImageChange(error, body) {
        if (error) {
            alert(body);
            return;
        }

        // if "body" is empty we are deleting the image
        if (body) {
            const { toastManager } = this.props;
            toastManager.add('Successfully uploaded image', {
                appearance: 'success',
                autoDismiss: true
            });
        }

        const this_element = this.state.element;
        this_element['label-image'] = body;

        this.setState(
            {
                element: this_element,
                dirty: true
            },
            () => {
                this.updateElement();
            }
        );
    }

    onSubmitInsertPipingIntoQuestion(question_field_name) {
        let piping_code;

        this.state.all_questions.forEach((page_questions, page_index) => {
            page_questions.forEach((pq, pq_index) => {
                if (pq.field_name == question_field_name) {
                    piping_code = `{pg=${page_index + 1},q=${pq_index + 1}}`;
                }
            });
        });

        const forceSave = true;
        let newLabel = '';
        newLabel += this.props.element.label + piping_code;

        this.editElementProp('label', 'value', { target: { value: newLabel } }, forceSave);

        this.setState({ showModalInsertPiping: false });
    }

    onSubmitInsertPipingIntoOptions(piping_code) {
        const forceSave = true;

        //let { page_id, question_id } = helpersScreener.getPipingPageIndexQuestionIndex(piping_code);

        console.log('..saving ' + piping_code);

        this.editElementProp('pipe_options_from', 'value', { target: { value: piping_code } }, forceSave);

        this.setState({ showModalInsertPiping: false });
    }

    getAllowedQuestionsForPiping() {
        let allowedQuestionsForPiping = [];

        const current_question_index_in_page = this.props.all_questions[this.state.page_index].findIndex(
            pel => pel.id == this.state.element.id
        );

        this.props.all_questions.forEach((page_questions, page_index) => {
            page_questions.forEach((pq, pq_index) => {
                //console.log('pages', this.state.page_index, page_index+1)
                // only show question for current or preceding pages
                if (this.state.page_index >= page_index) {
                    // dont show questions after current question
                    // dont show logic for question we're adding logic for
                    // also show previous pages

                    if (!pq.static && pq.id != this.state.element.id && helpersScreener.questionAllowsPiping(pq)) {
                        // for current page, get index of the question so you can show preceding questions only
                        let pq_after_current_question = false;
                        if (this.state.page_index == page_index) {
                            const pq_index = this.props.all_questions[this.state.page_index].indexOf(pq);

                            if (pq_index >= current_question_index_in_page) {
                                pq_after_current_question = true;
                            }
                        }
                        if (!pq_after_current_question) {
                            allowedQuestionsForPiping.push({
                                id: pq.field_name,
                                title: helpers.removeHtml(pq.label)
                            });
                        }
                    }
                }
            });
        });

        return allowedQuestionsForPiping;
    }

    handleInsertPipingClick(element_id, pipe_into) {
        const allowedQuestionsForPiping = this.getAllowedQuestionsForPiping();

        this.setState({
            allowedQuestionsForPiping: allowedQuestionsForPiping,
            showModalInsertPiping: true,
            insertPipingWhere: pipe_into
        });
    }

    handleImageUploadClick(element_id) {
        if (this.props.element['label-image']) {
            helpersScreener.imageRemove(this.props.element['label-image'], err => {
                helpers.trackError(err);
            });
        }

        const callbackUploading = () => {
            const { toastManager } = this.props;
            toastManager.add('Image uploading...', {
                appearance: 'info',
                autoDismiss: true
            });
        };

        helpersScreener.imageUploadButton(`label-image-${element_id}`, callbackUploading, this.handleImageChange);
    }

    getQuestionText() {
        return this.props.element.static ? this.props.element.content : this.props.element.label;
    }

    doesContainHtml(str) {
        return /<\/?[a-z][\s\S]*>/i.test(str);
    }

    openConfigurationOptions() {
        let current = this.state.showConfigurationOptions;
        this.setState({ showConfigurationOptions: !current });
    }

    componentDidCatch(error, errorInfo) {
        helpers.trackError(error);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.dirty === false) this.setState({ element: nextProps.element });
    }

    handleChangeSortOrder = orderValue => {
        const currentOrder = [OPTION_ORDER_OFF, OPTION_ORDER_RADOMIZE, OPTION_ORDER_FLIP].find(
            item => item.value === orderValue
        );

        this.editElementProp('option_order', 'value', {
            target: {
                value: currentOrder
            }
        });
        this.updateElement.bind(this);
    };

    handleAddOther = specify_label => e => {
        this.editElementProp('add_other', 'checked', e);
        if (!this.state.element.other_specify_label)
            this.editElementProp('other_specify_label', 'value', {
                target: { value: specify_label }
            });
    };

    /**
     * Check if the question is piped to another question
     *
     * @returns {boolean} True, if the question is piped to another question
     */
    checkIfQuestionIsPiped() {
        const flatAllQuestions = this.props.all_questions.flat();
        const fieldName = this.props.element.field_name;

        return flatAllQuestions.some(
            question => fieldName && question.pipe_options_from && question.pipe_options_from === fieldName
        );
    }

    /**
     * Remove two-way sync from the element
     */
    removeTwoWaySync() {
        this.setState(
            produce(draft => {
                draft.element.save_to_panel = false;
                draft.element.personCustomDataId = '';
                draft.dirty = true;

                // Bring back the original value
                draft.element.options.forEach(option => {
                    option.value = helpersScreener.normalizeValue(option.text);
                });
            }),
            () => {
                this.updateElement();
            }
        );
    }

    /**
     * Get the count of options for the current question
     *
     * @returns {number} The count of options for the current question
     */
    get optionsCount() {
        /**
         * @type number
         */
        let count = this.state.element.options.length;

        // Check if the question is piped to another question
        // If yes, add the count of the piped question's options to the total count
        if (this.state.element.pipe_options_from) {
            const pipedQuestion = this.props.all_questions
                .flat()
                .find(question => question.field_name === this.state.element.pipe_options_from);

            if (pipedQuestion) {
                count = count + pipedQuestion.options.length;
            }
        }

        return count;
    }

    render() {
        // only allow to change non-static items
        let listOfAvailableElements = [];
        const elementNamesWithOptions = ['Checkboxes', 'RadioButtons', 'Dropdown', 'Matrix'];
        const elementNamesBasicInputs = ['TextInput', 'TextArea', 'NumberInput'];
        const elementNamesStatic = ['Header', 'Paragraph'];

        listOfAvailableElements = AvailableElements.map(item => ({ id: item.key, title: item.name, icon: item.icon }));

        const verify_addpipe_index = listOfAvailableElements.findIndex(item => item.id == 'AddPipeInput');
        if (verify_addpipe_index) listOfAvailableElements.splice(verify_addpipe_index, 1);
        [2, 7, 12, 15, 17].map((pos, index) =>
            listOfAvailableElements.splice(pos, 0, { id: `divider-${index}`, divider: true })
        );

        let customColumnNames = [];
        customColumnNames.push(<option></option>);
        this.state.availableColumns.forEach(col => {
            customColumnNames.push(
                <option key={col.id} value={col.id}>
                    {col.title}
                </option>
            );
        });

        if (this.state.dirty) {
            this.props.element.dirty = true;
        }

        // console.log('NEW ELLLL', this.props.element)

        const this_checked_required = this.props.element.hasOwnProperty('required')
            ? this.props.element.required
            : false;

        const this_add_other = this.props.element.hasOwnProperty('add_other') ? this.props.element.add_other : false;
        const this_none_of_the_above = this.props.element.hasOwnProperty('none_of_the_above')
            ? this.props.element.none_of_the_above
            : false;
        const this_qual_disqual = this.props.element.hasOwnProperty('qual_disqual')
            ? this.props.element.qual_disqual
            : false;
        const this_reporting_values = this.props.element.hasOwnProperty('reporting_values')
            ? this.props.element.reporting_values
            : false;
        const this_validation = this.props.element.hasOwnProperty('validation') ? this.props.element.validation : false;
        let this_validation_rules = this.props.element.hasOwnProperty('validation_rules')
            ? this.props.element.validation_rules
            : false;
        const this_question_logic = this.props.element.hasOwnProperty('question_logic')
            ? this.props.element.question_logic
            : false;
        const this_question_logic_rules = this.props.element.hasOwnProperty('question_logic_rules')
            ? this.props.element.question_logic_rules
            : false;
        const this_checked_panel = this.props.element.hasOwnProperty('save_to_panel')
            ? this.props.element.save_to_panel
            : false;
        let this_matrix_allow_multiple = this.props.element.hasOwnProperty('matrix_allow_multiple')
            ? this.props.element.matrix_allow_multiple
            : false;
        const this_other_specify_label = this.props.element.hasOwnProperty('other_specify_label')
            ? this.props.element.other_specify_label
            : 'Other (please specify)';
        const this_none_above_specify_label = this.props.element.hasOwnProperty('none_above_specify_label')
            ? this.props.element.none_above_specify_label
            : 'None of the above';
        // to be backwards compatible; if this was "true" mark it as "1"
        if (this_matrix_allow_multiple === true) {
            this_matrix_allow_multiple = 1;
        }

        const this_personCustomDataId = this.props.element.hasOwnProperty('personCustomDataId')
            ? this.props.element.personCustomDataId
            : '';
        const this_num_columns = this.props.element.hasOwnProperty('num_columns') ? this.props.element.num_columns : '';

        const this_video_recording_time = this.props.element.hasOwnProperty('video_recording_time')
            ? this.props.element.video_recording_time
            : '';
        const this_upload_files_cnt = this.props.element.hasOwnProperty('upload_files_cnt')
            ? this.props.element.upload_files_cnt
            : '';
        const this_read_only = this.props.element.hasOwnProperty('readOnly') ? this.props.element.readOnly : false;
        const this_default_today = this.props.element.hasOwnProperty('defaultToday')
            ? this.props.element.defaultToday
            : false;
        const this_show_time_select = this.props.element.hasOwnProperty('showTimeSelect')
            ? this.props.element.showTimeSelect
            : false;
        const this_show_time_select_only = this.props.element.hasOwnProperty('showTimeSelectOnly')
            ? this.props.element.showTimeSelectOnly
            : false;
        const this_checked_inline = this.props.element.hasOwnProperty('inline') ? this.props.element.inline : false;
        const this_checked_bold = this.props.element.hasOwnProperty('bold') ? this.props.element.bold : false;
        const this_checked_italic = this.props.element.hasOwnProperty('italic') ? this.props.element.italic : false;
        const this_checked_center = this.props.element.hasOwnProperty('center') ? this.props.element.center : false;
        const this_checked_page_break = this.props.element.hasOwnProperty('pageBreakBefore')
            ? this.props.element.pageBreakBefore
            : false;
        const this_checked_alternate_form = this.props.element.hasOwnProperty('alternateForm')
            ? this.props.element.alternateForm
            : false;
        const this_pipe_options_from = this.props.element.hasOwnProperty('pipe_options_from')
            ? this.props.element.pipe_options_from
            : false; // this is QUESTION.FIELD_NAME

        const {
            canHavePageBreakBefore,
            canHaveAlternateForm,
            canHaveDisplayHorizontal,
            canHaveOptionCorrect,
            canHaveOptionValue
        } = this.props.element;

        const this_files = this.props.files.length ? this.props.files : [];
        if (this_files.length < 1 || (this_files.length > 0 && this_files[0].id !== '')) {
            this_files.unshift({ id: '', file_name: '' });
        }

        let editorState;
        if (this.props.element.hasOwnProperty('content')) {
            editorState = this.convertFromHTML(this.props.element.content);
        }
        if (this.props.element.hasOwnProperty('label')) {
            editorState = this.convertFromHTML(this.props.element.label);
        }

        let moveToSelectOptions = [];
        moveToSelectOptions.push({
            index: 'default',
            label: `Move to`,
            disabled: true,
            selected: true
        });
        this.props.all_questions.map((page_questions, page_index) => {
            moveToSelectOptions.push({
                index: `ignore`,
                label: `- - - Page ${page_index + 1} - - -`,
                disabled: true,
                selected: false
            });
            moveToSelectOptions.push({
                index: page_index + '_' + 0,
                label: 'Top of page',
                disabled: false,
                selected: false
            });
            let index_for_options;
            page_questions.forEach((q, question_index) => {
                //if (question_index != 0) {
                index_for_options = page_index + '_' + (parseInt(question_index) + 1);
                moveToSelectOptions.push({
                    index: index_for_options,
                    label: `After #${question_index + 1}: ${
                        q.static ? (q.content ? q.content.substr(0, 30) : '') : q.label ? q.label.substr(0, 30) : ''
                    }`, //` `,
                    disabled: false,
                    selected: false
                });
                //}
            });
        });

        let questionMoreDropdownItems = [];
        questionMoreDropdownItems.push({
            title: `Edit Rich Text`,
            feature_launched_at: moment('11-10-2021', 'MM-DD-YYYY'),
            icon: <FiEdit />,
            onClick: () => {
                this.setState({
                    showModalWysiwyg: true,
                    modalWysiwygHtml: this.getQuestionText()
                });
                const maxTries = 10;
                let numTries = 1;
                const quillRefInterval = setInterval(() => {
                    let doClearInterval = false;
                    if (this.quillRef.current) {
                        this.quillRef.current.focus();
                        doClearInterval = true;
                    } else {
                        if (numTries >= maxTries) {
                            doClearInterval = true;
                        }
                        numTries++;
                    }

                    if (doClearInterval) {
                        clearInterval(quillRefInterval);
                    }
                    //console.log('quillRefInterval...')
                }, 100);
            }
        });

        if (this.props.element.static) {
            if (this.doesContainHtml(this.props.element.content)) {
                questionMoreDropdownItems.push({
                    title: `Remove Rich Text`,
                    icon: <FiTrash2 />,
                    onClick: () => {
                        this.editElementProp(
                            'content',
                            'value',
                            { target: { value: helpers.removeHtml(this.props.element.content) } },
                            true
                        );
                    }
                });
            }
        } else {
            if (this.doesContainHtml(this.props.element.label)) {
                questionMoreDropdownItems.push({
                    title: `Remove Rich Text`,
                    icon: <FiTrash2 />,
                    onClick: () => {
                        this.editElementProp(
                            'label',
                            'value',
                            { target: { value: helpers.removeHtml(this.props.element.label) } },
                            true
                        );
                    }
                });
            }
        }

        questionMoreDropdownItems.push({
            title: `Insert Piping`,
            icon: <FiCornerDownRight />,
            onClick: () => {
                this.handleInsertPipingClick(this.props.element.id, 'question');
            }
        });

        questionMoreDropdownItems.push({
            title: this.props.element['label-image'] ? `Change Image` : `Upload Image`,
            icon: <FiImage />,
            onClick: () => {
                this.handleImageUploadClick(this.props.element.id);
            }
        });

        if (this.props.element['label-image']) {
            questionMoreDropdownItems.push({
                title: `Delete Image`,
                icon: <FiTrash2 />,
                onClick: () => {
                    if (confirm('Are you sure you want to delete this image?')) {
                        helpersScreener.imageRemove(this.props.element['label-image'], err => {
                            helpers.trackError(err);
                        });
                        this.handleImageChange(false, '');
                    }
                }
            });
        }

        const isSynchronisable = Object.keys(TWO_WAY_SYNC_INPUT_TYPE_COMPATIBILITY).includes(
            this.state.element.element
        );
        let isAllowedSyncCombination = true;
        let panelProperty = null;
        if (isSynchronisable && this_checked_panel && this.state.availableColumns_byId[this_personCustomDataId]) {
            panelProperty = this.state.availableColumns_byId[this_personCustomDataId];
            isAllowedSyncCombination = TWO_WAY_SYNC_INPUT_TYPE_COMPATIBILITY[this.state.element.element].includes(
                panelProperty.type
            );
        }

        return (
            <>
                <div className="form-item clearfix">
                    {this_question_logic ? (
                        <Box className="text-primary medium question-has-page-logic">
                            <FiGitMerge style={{ margin: '4px 8px 0 0', verticalAlign: 'top' }} />
                            This question has display logic
                        </Box>
                    ) : (
                        ''
                    )}
                    <div style={{ height: '1px' }}></div>
                    <div className="editor-top-container clearfix">
                        {this.props.showSaveCancel == true && (
                            <>
                                <Button variant="primary" type="button" onClick={this.onSave}>
                                    Save
                                </Button>
                                <Button variant="secondary-gray" type="button" onClick={this.props._onClose}>
                                    Close
                                </Button>
                            </>
                        )}
                        <Flex
                            style={{
                                color: '#606E85',
                                padding: '0 0 12px',
                                margin: '0 0 8px',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Box>
                                <span style={{ display: 'inline-block', position: 'relative', margin: '-4px 8px 0 0' }}>
                                    {listOfAvailableElements.length > 0 ? (
                                        <div style={{ display: 'inline-block', position: 'relative' }}>
                                            <Button
                                                variant="link"
                                                type="button"
                                                className="muted bold"
                                                onClick={() => this.setState({ showAddElementDropdown: true })}
                                                sx={{ fontSize: '16px' }}
                                            >
                                                {this.props.element.text}{' '}
                                                <FiChevronDown
                                                    style={{
                                                        marginRight: 0,
                                                        marginLeft: 0,
                                                        marginTop: '5px',
                                                        fontSize: '16px'
                                                    }}
                                                />
                                            </Button>
                                            {this.state.showAddElementDropdown && (
                                                <NiceDropdown
                                                    showSearch
                                                    //positionRight="0px"
                                                    onClose={() => {
                                                        this.setState({ showAddElementDropdown: false });
                                                    }}
                                                    items={listOfAvailableElements}
                                                    onChange={key => {
                                                        this.changeElementToNewElement(key);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <span className="text-primary" style={{ fontSize: '16px', fontWeight: 500 }}>
                                            {this.props.element.text}
                                        </span>
                                    )}
                                </span>
                            </Box>
                            <Box className="question-misc-actions">
                                <Button
                                    type="button"
                                    variant="secondary-gray"
                                    className="secondary-icon"
                                    tabIndex="-1"
                                    onClick={() => {
                                        if (this.checkIfQuestionIsPiped()) {
                                            return this.props.toastManager.add(
                                                'This question answers are piped to another question',
                                                {
                                                    appearance: 'error',
                                                    autoDismiss: true
                                                }
                                            );
                                        }

                                        if (confirm('Are you sure you want to delete this element?')) {
                                            this.props._onDestroy();
                                        }
                                    }}
                                    style={{ float: 'right', margin: '-4px 0 0 0' }}
                                >
                                    <FiTrash2 />
                                </Button>
                                <Button
                                    type="button"
                                    variant="secondary-gray"
                                    className="secondary-icon"
                                    tabIndex="-1"
                                    onClick={() => {
                                        this.props._onDuplicate();
                                    }}
                                    style={{ float: 'right', margin: '-4px 16px 0 16px' }}
                                >
                                    <FiCopy />
                                </Button>
                                <div style={{ width: '120px', float: 'right', margin: '-4px 8px 0 0' }}>
                                    <Select
                                        sx={{ maxWidth: '140px' }}
                                        onChange={e => {
                                            let val = e.target.value;
                                            const val_array = val.split('_');
                                            const page_index_new = val_array[0];
                                            const question_index_new = val_array[1];
                                            this.props._onMoveElement(
                                                this.props.index,
                                                page_index_new,
                                                question_index_new
                                            );
                                            e.target.value = 'default';
                                        }}
                                        className="theme-input"
                                    >
                                        {moveToSelectOptions.map(mtso => {
                                            if (mtso.index == 'default' || mtso.index == 'ignore') {
                                                return (
                                                    <option
                                                        value={mtso.index}
                                                        disabled={mtso.disabled}
                                                        selected={mtso.selected}
                                                    >
                                                        {mtso.label}
                                                    </option>
                                                );
                                            }

                                            const val_array = mtso.index.split('_');
                                            const page_index_new = val_array[0];
                                            const question_index_new = val_array[1];

                                            if (
                                                page_index_new == this.props.page_index &&
                                                (question_index_new == this.props.index ||
                                                    question_index_new == this.props.index + 1)
                                            ) {
                                                // skip
                                            } else {
                                                return (
                                                    <option
                                                        value={mtso.index}
                                                        disabled={mtso.disabled}
                                                        selected={mtso.selected}
                                                    >
                                                        {mtso.label}
                                                    </option>
                                                );
                                            }
                                        })}
                                    </Select>
                                </div>
                            </Box>
                        </Flex>

                        {/*<FiX className="float-right dismiss-edit" onClick={this.props.manualEditModeOff} />*/}
                    </div>
                    {this.props.element.hasOwnProperty('file_path') && (
                        <div className="form-group">
                            <label className="control-label" htmlFor="fileSelect">
                                Choose file:
                            </label>
                            <select
                                id="fileSelect"
                                className="form-control"
                                defaultValue={this.props.element.file_path}
                                onBlur={this.updateElement.bind(this)}
                                onChange={this.editElementProp.bind(this, 'file_path', 'value')}
                            >
                                {this_files.map(file => {
                                    const this_key = `file_${file.id}`;
                                    return (
                                        <option value={file.id} key={this_key}>
                                            {file.file_name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    )}
                    {this.props.element.hasOwnProperty('href') && (
                        <div className="form-group">
                            <TextAreaAutosize
                                type="text"
                                className="form-control"
                                defaultValue={this.props.element.href}
                                onBlur={this.updateElement.bind(this)}
                                onChange={this.editElementProp.bind(this, 'href', 'value')}
                            />
                        </div>
                    )}
                    {this.props.element.hasOwnProperty('src') && (
                        <div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="srcInput">
                                    Link to:
                                </label>
                                <input
                                    id="srcInput"
                                    type="text"
                                    className="form-control"
                                    defaultValue={this.props.element.src}
                                    onBlur={this.updateElement.bind(this)}
                                    onChange={this.editElementProp.bind(this, 'src', 'value')}
                                />
                            </div>
                            <div className="form-group">
                                <div className="custom-control ">
                                    <input
                                        id="do-center"
                                        className="custom-control-input"
                                        type="checkbox"
                                        checked={this_checked_center}
                                        value={true}
                                        onChange={this.editElementProp.bind(this, 'center', 'checked')}
                                    />
                                    <label className="custom-control-label" htmlFor="do-center">
                                        Center?
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="elementWidth">
                                        Width:
                                    </label>
                                    <input
                                        id="elementWidth"
                                        type="text"
                                        className="form-control"
                                        defaultValue={this.props.element.width}
                                        onBlur={this.updateElement.bind(this)}
                                        onChange={this.editElementProp.bind(this, 'width', 'value')}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <label className="control-label" htmlFor="elementHeight">
                                        Height:
                                    </label>
                                    <input
                                        id="elementHeight"
                                        type="text"
                                        className="form-control"
                                        defaultValue={this.props.element.height}
                                        onBlur={this.updateElement.bind(this)}
                                        onChange={this.editElementProp.bind(this, 'height', 'value')}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {(this.props.element.hasOwnProperty('label') || this.props.element.hasOwnProperty('content')) && (
                        <div className=" editor-title-container">
                            <Flex sx={{ width: '100%' }}>
                                <Box sx={{ flexGrow: 1 }} mr={3}>
                                    {this.props.element.hasOwnProperty('label') && (
                                        <>
                                            <Label className="text-primary medium">
                                                {this.props.index + 1}. Question
                                                {this.doesContainHtml(this.props.element.label) ? ' (Rich Text)' : ''}
                                                {this_checked_required ? <span className="red">&nbsp;*</span> : <></>}
                                            </Label>

                                            {this.doesContainHtml(this.props.element.label) ? (
                                                <Box className="quill-render">
                                                    <div
                                                        style={{ whiteSpace: 'pre-wrap' }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: myxss.process(this.props.element.label)
                                                        }}
                                                    />
                                                </Box>
                                            ) : (
                                                <TextAreaAutosize
                                                    id={helpersScreener.getScreenerBuilderInputHtmlId(
                                                        this.state.element.id
                                                    )}
                                                    type="text"
                                                    style={{ width: '100%' }}
                                                    className="theme-input"
                                                    value={this.state.element.label}
                                                    onBlur={this.updateElement.bind(this)}
                                                    onChange={this.editElementProp.bind(this, 'label', 'value')}
                                                />
                                            )}
                                        </>
                                    )}

                                    {this.props.element.hasOwnProperty('content') && (
                                        <>
                                            <Label className="text-primary medium">
                                                {this.props.index + 1}. Text
                                                {this.doesContainHtml(this.props.element.content) ? ' (Rich Text)' : ''}
                                            </Label>
                                            {this.doesContainHtml(this.props.element.content) ? (
                                                <Box className="quill-render">
                                                    <div
                                                        style={{ whiteSpace: 'pre-wrap' }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: myxss.process(this.props.element.content)
                                                        }}
                                                    />
                                                </Box>
                                            ) : (
                                                <TextAreaAutosize
                                                    id={helpersScreener.getScreenerBuilderInputHtmlId(
                                                        this.props.element.id
                                                    )}
                                                    type="text"
                                                    style={{ width: '100%' }}
                                                    className="theme-input"
                                                    value={this.state.element.content}
                                                    onBlur={this.updateElement.bind(this)}
                                                    onChange={this.editElementProp.bind(this, 'content', 'value')}
                                                />
                                            )}
                                        </>
                                    )}

                                    {this.props.element['label-image'] && (
                                        <Box mt={3}>
                                            <img src={this.props.element['label-image']} style={{ maxWidth: '100%' }} />
                                        </Box>
                                    )}
                                </Box>
                                <Box sx={{ flexShrink: 0 }}>
                                    <Label className="quiet">&nbsp;</Label>
                                    <Box style={{ display: 'inline-block', position: 'relative' }}>
                                        <Button
                                            tabIndex={-1}
                                            variant="secondary-gray"
                                            className="secondary-icon"
                                            mr={0}
                                            onClick={() => {
                                                this.setState({
                                                    showQuestionMoreDropdown: true
                                                });
                                            }}
                                        >
                                            <FiMoreVertical style={{ marginRight: 0 }} />
                                        </Button>
                                        {this.state.showQuestionMoreDropdown == true && (
                                            <NiceDropdown
                                                width={100}
                                                positionRight="0px"
                                                onClose={() => {
                                                    this.setState({
                                                        showQuestionMoreDropdown: false
                                                    });
                                                }}
                                                items={questionMoreDropdownItems}
                                            />
                                        )}
                                    </Box>
                                    <input
                                        type="file"
                                        id={`label-image-${this.props.element.id}`}
                                        accept="image/x-png,image/jpeg"
                                        hidden
                                    />
                                </Box>
                            </Flex>

                            {this.props.element.element == 'Matrix' && (
                                <Box mt={3} mb={3}>
                                    <Label className="text-primary medium">
                                        Matrix type
                                        {helpers.newFeatureTag(moment('11-07-2021', 'MM-DD-YYYY'), {
                                            margin: '0 0 4px 8px'
                                        })}
                                    </Label>
                                    <Box>
                                        <Select
                                            style={{ maxWidth: '160px' }}
                                            value={this_matrix_allow_multiple}
                                            onBlur={this.updateElement.bind(this)}
                                            onChange={this.editElementProp.bind(this, 'matrix_allow_multiple', 'value')}
                                            className="theme-input"
                                            mr={3}
                                        >
                                            <option value={0}>Single Select</option>
                                            <option value={1}>Checkboxes</option>
                                            <option value={2}>Short Answer</option>
                                        </Select>
                                    </Box>
                                </Box>
                            )}

                            {/*<Editor
                          toolbar={toolbar}
                          defaultEditorState={editorState}
                          onBlur={this.updateElement.bind(this)}
                          onEditorStateChange={this.onEditorStateChange.bind(this, 0, 'label')}
                          stripPastedStyles={true} />*/}

                            {/*this.props.element.hasOwnProperty('readOnly') && (
                            <div className="custom-control ">
                                <input
                                    id="is-read-only"
                                    className="custom-control-input"
                                    type="checkbox"
                                    checked={this_read_only}
                                    value={true}
                                    onChange={this.editElementProp.bind(this, 'readOnly', 'checked')}
                                />
                                <label className="custom-control-label" htmlFor="is-read-only">
                                    Read only
                                </label>
                            </div>
                        )*/}

                            {this.props.element.element == 'DatePicker' && (
                                <>
                                    <div className="custom-control ">
                                        <br />
                                        <Label className="for-checkbox" mt={2}>
                                            <input
                                                id="is-default-to-today"
                                                className="custom-control-input"
                                                type="checkbox"
                                                checked={this_default_today}
                                                value={true}
                                                onChange={this.editElementProp.bind(this, 'defaultToday', 'checked')}
                                                style={{ margin: '4px 8px 0 0' }}
                                            />
                                            Default to today's date
                                        </Label>
                                    </div>

                                    <div className="custom-control ">
                                        <Label className="for-checkbox">
                                            <input
                                                id="show-time-select"
                                                className="custom-control-input"
                                                type="checkbox"
                                                checked={this_show_time_select}
                                                value={true}
                                                onChange={this.editElementProp.bind(this, 'showTimeSelect', 'checked')}
                                                style={{ margin: '4px 8px 0 0' }}
                                            />
                                            Show Time
                                        </Label>
                                    </div>
                                </>
                            )}
                            {this_show_time_select && this.props.element.element == 'DatePicker' && (
                                <div className="custom-control ">
                                    <Label className="for-checkbox">
                                        <input
                                            id="show-time-select-only"
                                            className="custom-control-input"
                                            type="checkbox"
                                            checked={this_show_time_select_only}
                                            value={true}
                                            onChange={this.editElementProp.bind(this, 'showTimeSelectOnly', 'checked')}
                                            style={{ margin: '4px 8px 0 0' }}
                                        />
                                        Show Time Only
                                    </Label>
                                </div>
                            )}
                            {/*(this.state.element.element === 'RadioButtons' ||
                            this.state.element.element === 'Checkboxes') &&
                            canHaveDisplayHorizontal && (
                                <div className="custom-control ">
                                    <input
                                        id="display-horizontal"
                                        className="custom-control-input"
                                        type="checkbox"
                                        checked={this_checked_inline}
                                        value={true}
                                        onChange={this.editElementProp.bind(this, 'inline', 'checked')}
                                    />
                                    <label className="custom-control-label" htmlFor="display-horizontal">
                                        Display horizonal
                                    </label>
                                </div>
                            )*/}
                        </div>
                    )}

                    {this.state.element.element === 'Signature' && this.props.element.readOnly ? (
                        <div className="form-group">
                            <label className="control-label" htmlFor="variableKey">
                                Variable Key:
                            </label>
                            <input
                                id="variableKey"
                                type="text"
                                className="form-control"
                                defaultValue={this.props.element.variableKey}
                                onBlur={this.updateElement.bind(this)}
                                onChange={this.editElementProp.bind(this, 'variableKey', 'value')}
                            />
                            <p className="help-block">
                                This will give the element a key that can be used to replace the content with a runtime
                                value.
                            </p>
                        </div>
                    ) : (
                        <div />
                    )}

                    {/*canHavePageBreakBefore &&
          <div className="form-group">
            <label className="control-label">Print Options</label>
            <div className="custom-control ">
              <input id="page-break-before-element" className="custom-control-input" type="checkbox" checked={this_checked_page_break} value={true} onChange={this.editElementProp.bind(this, 'pageBreakBefore', 'checked')} />
              <label className="custom-control-label" htmlFor="page-break-before-element">
                Page Break Before Element?
              </label>
            </div>
          </div>
        */}

                    {/*canHaveAlternateForm &&
          <div className="form-group">
            <label className="control-label">Alternate/Signature Page</label>
            <div className="custom-control ">
              <input id="display-on-alternate" className="custom-control-input" type="checkbox" checked={this_checked_alternate_form} value={true} onChange={this.editElementProp.bind(this, 'alternateForm', 'checked')} />
              <label className="custom-control-label" htmlFor="display-on-alternate">
                Display on alternate/signature Page?
              </label>
            </div>
          </div>
        */}

                    {this.props.element.hasOwnProperty('step') && (
                        <div className="custom-control survey-edit-horizontal-margin">
                            <div className="form-group-range">
                                <label className="control-label" htmlFor="rangeStep">
                                    Step
                                </label>
                                <input
                                    id="rangeStep"
                                    type="number"
                                    className="form-control"
                                    defaultValue={this.props.element.step}
                                    onBlur={this.updateElement.bind(this)}
                                    onChange={this.editElementProp.bind(this, 'step', 'value')}
                                    style={{ 'margin-left': '16px' }}
                                />
                            </div>
                        </div>
                    )}
                    {this.props.element.hasOwnProperty('min_value') && (
                        <div className="form-group survey-edit-horizontal-margin">
                            <div className="form-group-range">
                                <label className="control-label" htmlFor="rangeMin">
                                    Min
                                </label>
                                <input
                                    id="rangeMin"
                                    type="number"
                                    className="form-control"
                                    defaultValue={this.props.element.min_value}
                                    onBlur={this.updateElement.bind(this)}
                                    onChange={this.editElementProp.bind(this, 'min_value', 'value')}
                                    style={{ 'margin-left': '16px' }}
                                />
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={this.props.element.min_label}
                                    onBlur={this.updateElement.bind(this)}
                                    onChange={this.editElementProp.bind(this, 'min_label', 'value')}
                                    style={{ 'margin-left': '16px' }}
                                />
                            </div>
                        </div>
                    )}
                    {this.props.element.hasOwnProperty('max_value') && (
                        <div className="form-group survey-edit-horizontal-margin">
                            <div className="form-group-range">
                                <label className="control-label" htmlFor="rangeMax">
                                    Max
                                </label>
                                <input
                                    id="rangeMax"
                                    type="number"
                                    className="form-control"
                                    defaultValue={this.props.element.max_value}
                                    onBlur={this.updateElement.bind(this)}
                                    onChange={this.editElementProp.bind(this, 'max_value', 'value')}
                                    style={{ 'margin-left': '16px' }}
                                />
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={this.props.element.max_label}
                                    onBlur={this.updateElement.bind(this)}
                                    onChange={this.editElementProp.bind(this, 'max_label', 'value')}
                                    style={{ 'margin-left': '16px' }}
                                />
                            </div>
                        </div>
                    )}
                    {this.props.element.element === 'AddPipeInput' && (
                        <div className="form-group survey-edit-horizontal-margin" style={{ marginTop: '-5px' }}>
                            <div>
                                <Label className="text-primary medium">Max Video Duration</Label>
                                <Box mb={'24px'}>
                                    <Select
                                        sx={{ maxWidth: '120px' }}
                                        value={this_video_recording_time}
                                        onBlur={this.updateElement.bind(this)}
                                        onChange={this.editElementProp.bind(this, 'video_recording_time', 'value')}
                                        //className="theme-input theme-input-simple"
                                    >
                                        <option value="15">15 sec</option>
                                        <option value="30">30 sec</option>
                                        <option value="45">45 sec</option>
                                        <option value="60">1 min</option>
                                        <option value="120">2 min</option>
                                        <option value="180">3 min</option>
                                        <option value="240">4 min</option>
                                        <option value="300">5 min</option>
                                    </Select>
                                </Box>
                            </div>
                        </div>
                    )}
                    {this.props.element.element === 'FileUpload' && (
                        <div className="form-group survey-edit-horizontal-margin" style={{ marginTop: '-5px' }}>
                            <div>
                                <Label className="text-primary medium">Max Files</Label>
                                <Box mb={'24px'}>
                                    <Select
                                        sx={{ maxWidth: '120px' }}
                                        value={this_upload_files_cnt}
                                        onBlur={this.updateElement.bind(this)}
                                        onChange={this.editElementProp.bind(this, 'upload_files_cnt', 'value')}
                                    >
                                        <option value="1">1 file</option>
                                        <option value="2">2 files</option>
                                        <option value="3">3 files</option>
                                        <option value="4">4 files</option>
                                        <option value="5">5 files</option>
                                        <option value="6">6 files</option>
                                        <option value="7">7 files</option>
                                        <option value="8">8 files</option>
                                        <option value="9">9 files</option>
                                        <option value="10">10 files</option>
                                    </Select>
                                </Box>
                            </div>
                        </div>
                    )}
                    {this.props.element.hasOwnProperty('default_value') && (
                        <div className="form-group survey-edit-horizontal-margin">
                            <div className="form-group-range">
                                <Label className="text-primary medium" htmlFor="defaultSelected">
                                    Default Value
                                </Label>
                                <Input
                                    id="defaultSelected"
                                    type="text"
                                    className="form-control"
                                    defaultValue={this.props.element.default_value}
                                    value={this.state.element.default_value}
                                    onBlur={this.onBlurDefaultValue}
                                    onChange={this.editElementProp.bind(this, 'default_value', 'value')}
                                    style={{ maxWidth: '320px' }}
                                />
                                {this.state.element.element === 'HiddenValue' && (
                                    <Box>
                                        <Flex sx={{ gap: 2 }}>
                                            {!this.state.mergeCodeValidation && (
                                                <Box fontSize={1} mt={2} mb={2}>
                                                    <span className="label-error">The merge code is invalid.</span>
                                                </Box>
                                            )}
                                            {panelProperty &&
                                                panelProperty.type === PERSON_KEY_TYPE_BOOLEAN &&
                                                this.state.element.default_value !== '' &&
                                                !BOOLEAN_FORM_VALUES.includes(this.state.element.default_value) && (
                                                    <Box fontSize={1} mt={2} mb={2}>
                                                        <span className="label-error">
                                                            The value is not a valid boolean (true/false/0/1) value.
                                                        </span>
                                                    </Box>
                                                )}
                                        </Flex>

                                        <Box mt={1}>
                                            <Box className="gray fs-13" mt={2}>
                                                <a href="#" data-beacon-article="6305555ac1ba4b3dfdae2691">
                                                    <FiHelpCircle style={{ marginTop: '-2px' }} /> Merge codes &
                                                    documentation
                                                </a>
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                                <br />
                            </div>
                        </div>
                    )}
                    {/* this.props.element.hasOwnProperty('static') && this.props.element.static && (
                    <div className="form-group">
                        <label className="control-label">Text Style</label>
                        <div className="custom-control ">
                            <input
                                id="do-bold"
                                className="custom-control-input"
                                type="checkbox"
                                checked={this_checked_bold}
                                value={true}
                                onChange={this.editElementProp.bind(this, 'bold', 'checked')}
                            />
                            <label className="custom-control-label" htmlFor="do-bold">
                                Bold
                            </label>
                        </div>
                        <div className="custom-control ">
                            <input
                                id="do-italic"
                                className="custom-control-input"
                                type="checkbox"
                                checked={this_checked_italic}
                                value={true}
                                onChange={this.editElementProp.bind(this, 'italic', 'checked')}
                            />
                            <label className="custom-control-label" htmlFor="do-italic">
                                Italic
                            </label>
                        </div>
                    </div>
                ) */}
                    {this.props.element.showDescription && (
                        <div className="form-group">
                            <label className="control-label" htmlFor="questionDescription">
                                Description
                            </label>
                            <TextAreaAutosize
                                type="text"
                                className="form-control"
                                id="questionDescription"
                                defaultValue={this.props.element.description}
                                onBlur={this.updateElement.bind(this)}
                                onChange={this.editElementProp.bind(this, 'description', 'value')}
                            />
                        </div>
                    )}
                    {this.props.showCorrectColumn &&
                        this.props.element.canHaveAnswer &&
                        !this.props.element.hasOwnProperty('options') && (
                            <div className="form-group">
                                <label className="control-label" htmlFor="correctAnswer">
                                    Correct Answer
                                </label>
                                <input
                                    id="correctAnswer"
                                    type="text"
                                    className="form-control"
                                    defaultValue={this.props.element.correct}
                                    onBlur={this.updateElement.bind(this)}
                                    onChange={this.editElementProp.bind(this, 'correct', 'value')}
                                />
                            </div>
                        )}
                    {/* this.props.element.canPopulateFromApi && this.props.element.hasOwnProperty('options') &&
          <div className="form-group">
            <label className="control-label" htmlFor="optionsApiUrl">Populate Options from API</label>
            <div className="row">
              <div className="col-sm-6">
                <input className="form-control" style={{ width: '100%' }} type="text" id="optionsApiUrl" placeholder="http://localhost:8080/api/optionsdata" />
              </div>
              <div className="col-sm-6">
                <button onClick={this.addOptionsAPI.bind(this)} className="btn btn-success">Populate</button>
              </div>
            </div>
          </div>
        */}

                    {this.props.element.hasOwnProperty('options') &&
                        ['Checkboxes', 'RadioButtons', 'Dropdown', 'Matrix'].includes(this.props.element.element) && (
                            <DynamicOptionList
                                showCorrectColumn={this.props.showCorrectColumn}
                                canHaveOptionCorrect={canHaveOptionCorrect}
                                canHaveOptionValue={canHaveOptionValue}
                                data={this.props.preview.state.data}
                                this_personCustomDataId
                                updateElement={this.props.updateElement}
                                preview={this.props.preview}
                                element={this.props.element}
                                key={this.props.element.options.length}
                                onClickAddPiping={() => this.handleInsertPipingClick(this.props.element.id, 'options')}
                                onClickRemovePiping={() => this.onSubmitInsertPipingIntoOptions(null)}
                                availableColumns={this.state.availableColumns} // for two-way sync
                                isSynced={!!this_checked_panel}
                                isPopulable={
                                    isSynchronisable &&
                                    this_checked_panel &&
                                    this_personCustomDataId &&
                                    this.state.availableColumns_byId[this_personCustomDataId] &&
                                    elementNamesWithOptions.includes(this.props.element.element)
                                }
                                onPopulate={() => {
                                    setTimeout(() => {
                                        this.handleImportCustomColumnValues(this_personCustomDataId);
                                    }, 200);
                                }}
                            />
                        )}

                    {this.props.element.hasOwnProperty('label') && (
                        <>
                            <div className="custom-control  editor-configuration-container">
                                <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
                                    <Flex style={{ flexGrow: 1 }}>
                                        <Button
                                            type="button"
                                            variant="secondary-gray"
                                            //className="fs-13"
                                            onClick={this.openConfigurationOptions}
                                        >
                                            <FiSettings /> Settings
                                            {/*this.state.showConfigurationOptions == false ? <FiChevronRight /> : <FiChevronDown /> */}
                                        </Button>
                                        {!['Signature'].includes(this.props.element.element) && (
                                            <>
                                                {/*<Box>
                                            <Flex>
                                                <Label className="for-checkbox">
                                                    <input
                                                        id="is-add_other"
                                                        className="custom-control-input"
                                                        type="checkbox"
                                                        checked={this_add_other}
                                                        value={false}
                                                        // onChange={this.handleAddOther(this_other_specify_label)}
                                                        onChange={this.handleAddOther(this_other_specify_label)}
                                                        style={{ margin: '4px 8px 0 0' }}
                                                    />
                                                    Add "Other" answer option
                                                </Label>
                                            </Box>
                                            {this_add_other && (
                                                <Box style={{ marginLeft: '24px', width: '30%' }}>
                                                    <Input
                                                        defaultValue={this_other_specify_label}
                                                        className="theme-input"
                                                        placeholder="Other..."
                                                        onBlur={this.updateElement.bind(this)}
                                                        onChange={this.editElementProp.bind(
                                                            this,
                                                            'other_specify_label',
                                                            'value'
                                                        )}
                                                        mb={2}
                                                    />
                                                </Box>
                                            )}
                                        </>
                                    )}

                                    {['Checkboxes'].includes(this.props.element.element) && (
                                        <>
                                            <Box>
                                                <Label className="for-checkbox">
                                                    <input
                                                        id="is-none-of-the-above"
                                                        className="custom-control-input"
                                                        type="checkbox"
                                                        checked={this_none_of_the_above}
                                                        value={false}
                                                        onChange={e => {}}
                                                        style={{ margin: '4px 8px 0 0' }}
                                                    />
                                                    Two-way panel property sync <a href="#" data-beacon-article="630917937164226be0c80936" className='help-question'>?</a>
                                                </Label>
                                                {this.props.screener_for === 'panel' && !this_checked_panel && (
                                                    <ErrorLabel style={{ margin: '-4px 0 0 16px' }}>
                                                        Enable this checkbox to save answers to panel profile
                                                    </ErrorLabel>
                                                )}
                                            </Flex>
                                            <ReactTooltip
                                                id={`tooltip-sync-${this.state.element.id}`}
                                                effect="solid"
                                                place="right"
                                            >
                                                <Box>
                                                    This will save the screener answer to a specified property in a
                                                    panelist's profile;
                                                    <br />
                                                    and will also pre-populate a screener answer with a panelist's
                                                    existing information,
                                                    <br />
                                                    which can be edited.
                                                </Box>
                                            </ReactTooltip>
                                        </Box>*/}

                                                <div className="form-group" css={{ marginLeft: '12px' }}>
                                                    <Flex flexWrap="wrap" sx={{ gap: '8px 12px' }}>
                                                        {isSynchronisable && (
                                                            <Box
                                                                className={classNames(
                                                                    this.state.showDisplayedCustomColumns && 'relative'
                                                                )}
                                                            >
                                                                <Button
                                                                    variant={
                                                                        !!this_checked_panel
                                                                            ? 'secondary'
                                                                            : 'secondary-gray'
                                                                    }
                                                                    className="louder"
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            showDisplayedCustomColumns: true
                                                                        })
                                                                    }
                                                                >
                                                                    <FiUsers /> Two way sync
                                                                    {this_checked_panel &&
                                                                    this_personCustomDataId &&
                                                                    this.state.availableColumns_byId[
                                                                        this_personCustomDataId
                                                                    ]
                                                                        ? `: ${this.state.availableColumns_byId[this_personCustomDataId].title} `
                                                                        : ': Not Synced '}
                                                                    <FiChevronDown style={{ marginRight: 0 }} />
                                                                </Button>
                                                                {this.state.showDisplayedCustomColumns && (
                                                                    <NiceDropdown
                                                                        adjustHeight
                                                                        showSearch
                                                                        width="360px"
                                                                        onClose={() => {
                                                                            this.setState({
                                                                                showDisplayedCustomColumns: false
                                                                            });
                                                                        }}
                                                                        items={this.state.availableColumns}
                                                                        onChange={columnId => {
                                                                            this.editElementProp(
                                                                                'save_to_panel',
                                                                                'checked',
                                                                                {
                                                                                    target: {
                                                                                        checked: true
                                                                                    }
                                                                                }
                                                                            );

                                                                            setTimeout(() => {
                                                                                this.editElementProp(
                                                                                    'personCustomDataId',
                                                                                    'value',
                                                                                    {
                                                                                        target: {
                                                                                            value: columnId
                                                                                        }
                                                                                    }
                                                                                );
                                                                            }, 200);
                                                                        }}
                                                                        renderBottomStickyButton={
                                                                            <Flex>
                                                                                <Button
                                                                                    variant="secondary"
                                                                                    onClick={
                                                                                        this
                                                                                            .handleOpenModalAddCustomColumn
                                                                                    }
                                                                                    flex={1}
                                                                                >
                                                                                    + New Panel Property
                                                                                </Button>
                                                                                <Button
                                                                                    flex={1}
                                                                                    margin={0}
                                                                                    variant="secondary"
                                                                                    className="red"
                                                                                    onClick={() => {
                                                                                        this.removeTwoWaySync();
                                                                                        this.setState({
                                                                                            showDisplayedCustomColumns: false
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    Remove
                                                                                </Button>
                                                                            </Flex>
                                                                        }
                                                                    />
                                                                )}
                                                            </Box>
                                                        )}
                                                        {isSynchronisable && !isAllowedSyncCombination && (
                                                            <ErrorLabel>
                                                                Element does not match to a two way sync property
                                                            </ErrorLabel>
                                                        )}
                                                        {this_personCustomDataId
                                                            ? !this.state.availableColumns_byId[
                                                                  this_personCustomDataId
                                                              ] && (
                                                                  <ErrorLabel>
                                                                      Selected property no longer exists, please select
                                                                      a new one from the dropdown.
                                                                  </ErrorLabel>
                                                              )
                                                            : this_checked_panel && (
                                                                  <ErrorLabel>
                                                                      Missing "panelist property", please select one
                                                                      from the dropdown
                                                                  </ErrorLabel>
                                                              )}
                                                        {this.props.screener_for === 'panel' &&
                                                            !this_checked_panel &&
                                                            !['Signature', 'FileUpload'].includes(
                                                                this.props.element.element
                                                            ) &&
                                                            isSynchronisable && (
                                                                <ErrorLabel>Missing two-way sync</ErrorLabel>
                                                            )}
                                                        {this.props.screener_for === 'panel' &&
                                                            !this_checked_panel &&
                                                            !['Signature', 'FileUpload'].includes(
                                                                this.props.element.element
                                                            ) &&
                                                            !isSynchronisable && (
                                                                <ErrorLabel>
                                                                    This type of question can not be used with two-way
                                                                    sync
                                                                </ErrorLabel>
                                                            )}
                                                    </Flex>
                                                </div>
                                            </>
                                        )}
                                    </Flex>
                                    {!['HiddenValue'].includes(this.props.element.element) ? (
                                        <Flex sx={{ flexShrink: 0, paddingTop: '2px' }}>
                                            <Switch
                                                id={`required-toggle-${this.state.element.id}`}
                                                className="switch-small va-top"
                                                //name='show_in_profile'
                                                checked={this_checked_required}
                                                onClick={e => {
                                                    //this.editElementProp.bind(this, 'required', 'checked')
                                                    this.editElementProp('required', 'checked', {
                                                        target: {
                                                            checked: !this_checked_required
                                                        }
                                                    });
                                                }}
                                                mr={2}
                                                sx={{ marginTop: '8px' }}
                                            />
                                            <Label
                                                className="text-primary fs-13 pointer"
                                                sx={{ margin: '5px 0 0 0', lineHeight: '22px' }}
                                                htmlFor={`required-toggle-${this.state.element.id}`}
                                            >
                                                Required
                                            </Label>
                                            {/*<input
                                            id="is-required"
                                            className="custom-control-input"
                                            type="checkbox"
                                            checked={this_checked_required}
                                            value={true}
                                            onChange={this.editElementProp.bind(this, 'required', 'checked')}
                                            style={{margin: '4px 8px 0 0'}}
                                        />*/}
                                        </Flex>
                                    ) : (
                                        ''
                                    )}
                                </Flex>

                                {this.state.showConfigurationOptions == true ? (
                                    <div style={{ marginTop: '8px' }}>
                                        {/*!['HiddenValue'].includes(this.props.element.element) && (
                                    <Box>
                                        <Label className='for-checkbox'>
                                            <input
                                                id="is-required"
                                                className="custom-control-input"
                                                type="checkbox"
                                                checked={this_checked_required}
                                                value={true}
                                                onChange={this.editElementProp.bind(this, 'required', 'checked')}
                                                style={{margin: '4px 8px 0 0'}}
                                            />

                                            Required
                                        </Label>
                                    </Box>
                                )*/}

                                        {['Checkboxes', 'RadioButtons'].includes(this.props.element.element) && (
                                            <>
                                                <Box>
                                                    <Label className="for-checkbox">
                                                        <input
                                                            id="is-add_other"
                                                            className="custom-control-input"
                                                            type="checkbox"
                                                            checked={this_add_other}
                                                            value={false}
                                                            // onChange={this.handleAddOther(this_other_specify_label)}
                                                            onChange={this.handleAddOther(this_other_specify_label)}
                                                            style={{ margin: '4px 8px 0 0' }}
                                                        />
                                                        Add "Other" answer option
                                                    </Label>
                                                </Box>
                                                {this_add_other && (
                                                    <Box style={{ marginLeft: '24px', width: '30%' }}>
                                                        <Input
                                                            defaultValue={this_other_specify_label}
                                                            className="theme-input"
                                                            placeholder="Other..."
                                                            onBlur={this.updateElement.bind(this)}
                                                            onChange={this.editElementProp.bind(
                                                                this,
                                                                'other_specify_label',
                                                                'value'
                                                            )}
                                                            mb={2}
                                                        />
                                                    </Box>
                                                )}
                                            </>
                                        )}

                                        {['Checkboxes'].includes(this.props.element.element) && (
                                            <>
                                                <Box>
                                                    <Label className="for-checkbox">
                                                        <input
                                                            id="is-none-of-the-above"
                                                            className="custom-control-input"
                                                            type="checkbox"
                                                            checked={this_none_of_the_above}
                                                            value={false}
                                                            onChange={this.editElementProp.bind(
                                                                this,
                                                                'none_of_the_above',
                                                                'checked'
                                                            )}
                                                            style={{ margin: '4px 8px 0 0' }}
                                                        />
                                                        Add "None of the above" answer option
                                                    </Label>
                                                </Box>
                                                {this_none_of_the_above && (
                                                    <Box style={{ marginLeft: '24px', width: '30%' }}>
                                                        <Input
                                                            defaultValue={this_none_above_specify_label}
                                                            className="theme-input"
                                                            placeholder="None of the above."
                                                            onBlur={this.updateElement.bind(this)}
                                                            onChange={this.editElementProp.bind(
                                                                this,
                                                                'none_above_specify_label',
                                                                'value'
                                                            )}
                                                            mb={2}
                                                        />
                                                    </Box>
                                                )}
                                            </>
                                        )}

                                        {['Checkboxes', 'RadioButtons', 'Dropdown'].includes(
                                            this.props.element.element
                                        ) && (
                                            <Box>
                                                <Label className="for-checkbox">
                                                    <input
                                                        id="is-qual-disqual"
                                                        className="custom-control-input"
                                                        type="checkbox"
                                                        checked={this_qual_disqual}
                                                        value={false}
                                                        onChange={this.editElementProp.bind(
                                                            this,
                                                            'qual_disqual',
                                                            'checked'
                                                        )}
                                                        style={{ margin: '4px 8px 0 0' }}
                                                    />
                                                    Qualify, disqualify, or skip logic
                                                </Label>
                                            </Box>
                                        )}

                                        {['Checkboxes', 'RadioButtons', 'Dropdown', 'Matrix'].includes(
                                            this.props.element.element
                                        ) && (
                                            <>
                                                <SortOrder
                                                    elementId={this.state.element.id}
                                                    currentOrder={this.props.element.option_order}
                                                    onChangeOrder={this.handleChangeSortOrder}
                                                />
                                                <Box>
                                                    <Label className="for-checkbox">
                                                        <input
                                                            id="is-reporting_values"
                                                            className="custom-control-input"
                                                            type="checkbox"
                                                            checked={this_reporting_values}
                                                            value={false}
                                                            onChange={this.editElementProp.bind(
                                                                this,
                                                                'reporting_values',
                                                                'checked'
                                                            )}
                                                            style={{ margin: '4px 8px 0 0' }}
                                                        />
                                                        Reporting values
                                                    </Label>
                                                </Box>
                                            </>
                                        )}

                                        {helpersScreener.shouldShowValidationOptions(this.props.element) && (
                                            <>
                                                <Box>
                                                    <Label className="for-checkbox">
                                                        <input
                                                            id="is-validation"
                                                            className="custom-control-input"
                                                            type="checkbox"
                                                            checked={this_validation}
                                                            value={false}
                                                            onChange={this.editElementProp.bind(
                                                                this,
                                                                'validation',
                                                                'checked'
                                                            )}
                                                            style={{ margin: '4px 8px 0 0' }}
                                                        />
                                                        Answer validation
                                                    </Label>
                                                </Box>
                                                {this_validation && (
                                                    <div className="form-group" style={{ marginLeft: '21px' }}>
                                                        <Box className="fs-13">
                                                            {this.renderValidatonRules(this_validation_rules)}
                                                        </Box>
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        <Box>
                                            <Label className="for-checkbox">
                                                <input
                                                    id="question-logic"
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    checked={this_question_logic}
                                                    value={false}
                                                    onChange={this.editElementProp.bind(
                                                        this,
                                                        'question_logic',
                                                        'checked'
                                                    )}
                                                    style={{ margin: '4px 8px 0 0' }}
                                                />
                                                Show or hide question
                                            </Label>
                                        </Box>
                                        {this_question_logic && (
                                            <div className="form-group" style={{ marginLeft: '21px' }}>
                                                <Box className="fs-13">
                                                    {this.renderQuestionLogicRules(this_question_logic_rules)}
                                                </Box>
                                            </div>
                                        )}

                                        {['Checkboxes', 'RadioButtons'].includes(this.props.element.element) && (
                                            <Box>
                                                <Flex sx={{ fontSize: '13px' }}>
                                                    <Box>Display answers in</Box>
                                                    <Box>
                                                        <Select
                                                            style={{ width: '120px', marginTop: '-5px' }}
                                                            value={this_num_columns}
                                                            onBlur={this.updateElement.bind(this)}
                                                            onChange={this.editElementProp.bind(
                                                                this,
                                                                'num_columns',
                                                                'value'
                                                            )}
                                                            ml={2}
                                                            className="theme-input theme-input-simple"
                                                        >
                                                            <option value="1">1 Column</option>
                                                            <option value="2">2 Column</option>
                                                            <option value="3">3 Column</option>
                                                        </Select>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        )}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </>
                    )}
                    <RichTextEditorModal
                        isOpen={this.state.showModalWysiwyg}
                        onClose={() => this.setState({ showModalWysiwyg: false })}
                        modalTitle="Rich Text Editor"
                        editorValue={this.state.modalWysiwygHtml}
                        onChangeEditor={html => {
                            console.log('html', html);
                            this.setState({ modalWysiwygHtml: html });
                        }}
                        actionCancelLabel="Cancel"
                        actionConfirmLabel="Save"
                        actionCancel={() => this.setState({ showModalWysiwyg: false })}
                        actionConfirm={() => {
                            const { modalWysiwygHtml } = this.state;
                            const forceSave = true;
                            if (this.props.element.static) {
                                this.editElementProp(
                                    'content',
                                    'value',
                                    { target: { value: modalWysiwygHtml } },
                                    forceSave
                                );
                            } else {
                                console.log('modalWysiwygHtml', modalWysiwygHtml);
                                this.editElementProp(
                                    'label',
                                    'value',
                                    { target: { value: modalWysiwygHtml } },
                                    forceSave
                                );
                            }
                            this.setState({ showModalWysiwyg: false });
                        }}
                        ref={this.quillRef}
                    />

                    <SelectModal
                        title={`Select a question to pipe answer from`}
                        subtitle={
                            <Box fontSize={1} className="gray" mb={3}>
                                It is best practice to put a piping question on a{' '}
                                <span className="text-primary">new page</span>.<br />
                                <a href="#" data-beacon-article="6305551a549d66420b99a15c">
                                    <FiHelpCircle style={{ marginTop: '-2px' }} /> How piping works
                                </a>
                            </Box>
                        }
                        items={this.state.allowedQuestionsForPiping}
                        isOpen={this.state.showModalInsertPiping}
                        isLoading={false}
                        onRequestClose={() => {
                            this.setState({ showModalInsertPiping: false });
                        }}
                        onSelect={value => {
                            if (this.state.insertPipingWhere == 'options') {
                                this.onSubmitInsertPipingIntoOptions(value);
                            } else {
                                this.onSubmitInsertPipingIntoQuestion(value);
                            }
                        }}
                    />
                </div>
                <NiceModal
                    title="New panelist property"
                    isOpen={this.state.showModalAddCustomColumn}
                    shouldCloseOnOverlayClick
                    onRequestClose={this.handleCloseModalAddCustomColumn}
                >
                    <CreatePersonDataKey
                        onSuccess={this.handleAddColumnSuccess}
                        onClose={this.handleCloseModalAddCustomColumn}
                    />
                </NiceModal>
                {this.state.panelProperty && (
                    <EditPanelPropertyModal
                        panelProperty={this.state.panelProperty}
                        onClose={() => this.setState({ panelProperty: null })}
                        onSuccess={data => this.handleEditPanelProperty(data)}
                    />
                )}
            </>
        );
    }
}
FormElementsEdit.defaultProps = { className: 'edit-element-fields' };

export default withToastManager(FormElementsEdit);
