import React, { useEffect, useRef } from 'react';
import { Box } from 'rebass';
import classNames from 'classnames';
import { FiGitMerge } from 'react-icons/fi';

import helpersScreener from 'utils/helpers-screener';
import { Badge } from 'components/Badge';

import myxss from './myxss';
import { getElementLabel } from './getElementLabel';

/**
 * Display logic badge - displays a badge if the question has display logic and you want to show it
 *
 * @param {boolean} [isVisible] if true, then the badge is visible
 */
const DisplayLogic = ({ isVisible }) => {
    if (!isVisible) return null;

    return (
        <Badge size="sm" type="pure">
            <FiGitMerge /> This question has display logic
        </Badge>
    );
};

/**
 * Label component
 *
 * @param {Object} props
 * @param {Object} props.data
 * @param {boolean?} props.isPopup if true, then it's a popup
 * @param {boolean} props.[isQuestionLogicVisible] if true, then display logic is visible
 */
const ComponentLabel = props => {
    const labelRef = useRef(null);
    const hasLabel = props.data.hasOwnProperty('label') && props.data.label;
    const hasImage = props.data.hasOwnProperty('label-image') ? props.data['label-image'] : false;
    let insert_html_sanitized = myxss.process(
        helpersScreener.insertPiping(props.data.label, props.all_questions, props.answers_by_form_element_name)
    );
    const isText = helpersScreener.getElementNamesStatic().includes(props.data.element);

    if (!hasLabel && !hasImage) {
        return null;
    }

    let __html = insert_html_sanitized;

    if (props.data.required && !isText) {
        __html = __html + ` <span aria-hidden="true" class="required-mark text-danger fs-accent-14">*</span>`;
    }

    return (
        <>
            <DisplayLogic isVisible={props.isQuestionLogicVisible} />
            <Box
                mb={props.isPopup ? '4px' : 3}
                className={classNames('color-text-primary', props.isPopup ? 'fs-accent-14' : 'fs-title-16')}
            >
                {!props.data.static ? helpersScreener.showQuestionNumber(props.all_questions, props.data) : ''}
                <div
                    css={{
                        '*:has(+ .required-mark)': {
                            display: 'inline'
                        }
                    }}
                    ref={labelRef}
                    id={getElementLabel(props.data.id)}
                    style={{ whiteSpace: 'pre-wrap' }}
                    className="quill-render"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: __html }}
                />
                {hasImage && <img src={hasImage} style={{ maxWidth: '100%', margin: '16px 0 0 0' }} />}
            </Box>
        </>
    );
};

export default ComponentLabel;
